import { Document, Page, Text, View, StyleSheet} from "@react-pdf/renderer"
import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"


export default function Report(virtueblock){
    const { t } = useTranslation()
	const [contador,setContador] = useState(0)
	let styles = StyleSheet.create({
		pagina: {			
			paddingTop: 20,
			paddingRight: 30,
			paddingLeft: 30,
			paddingBottom: 20,
			fontSize: 18,
			fontFamily: 'Helvetica'
		},
		titulo: {
			fontSize: 16,
			fontFamily: 'Helvetica-Bold',
			color: "#24B8B0",
			marginBottom: 20,
			textAlign: 'right',			
		},
		titulo2: {
			borderWidth: 1,
			borderColor: "#24B8B0",
			borderRadius: 40,
			color: "#24B8B0",
			fontSize: 16,
			fontFamily: 'Helvetica-Bold',
			marginBottom: 20,	
			paddingVertical: 10,
			paddingHorizontal: 20,
			width: 250
		},
		caja: {
			borderWidth: 1,
			borderColor: "#24B8B0",
			borderRadius: 20,
			paddingHorizontal: 20,
			paddingVertical:20
		},
		cadavirtud:{
			marginBottom: 10,		
		},
		virtud: {
			fontSize: 14,
			fontFamily: 'Helvetica-Bold',
			borderWidth: 1,
			borderColor: "#24B8B0",
			borderRadius: 50,		
			color: "#24B8B0",
			marginBottom: 10,
			paddingHorizontal: 20,
			paddingVertical:5,
			width: 150
		},
		cabecera:{
			display: 'flex',
			flexDirection: 'row',
			marginBottom: 10
		},
		bloqueEjercicios:{			
			marginBottom:30
		},
		exerciseHead: {
			color: "#24B8B0",
			flex: 1,
			fontSize: 12,
			fontFamily: 'Helvetica-Bold',
			minWidth:170
		},
		levelHead: {
			color: "#24B8B0",
			flex: 1,
			fontSize: 12,
			fontFamily: 'Helvetica-Bold',
		},
		frequencyHead: {
			color: "#24B8B0",
			flex: 1,
			fontSize: 12,
			fontFamily: 'Helvetica-Bold',
		},
		ejercicio: {
			display: 'flex',
			borderBottom: 1,
			borderBottomColor: "#24B8B0",
			flexDirection: 'row',			
			marginBottom: 10,
			paddingBottom: 5,
			alignItems: 'center'
		},
		exercise: {
			flex: 1,
			fontFamily: 'Helvetica',
			fontSize: 12,
			paddingRight: 10,
			paddingVertical: 5,	
			minWidth:170
		},
		level: {
			borderWidth: 1,
			borderColor: "#24B8B0",
			borderRadius: 50,
			flex: 1,
			fontFamily: 'Helvetica',
			fontSize: 12,
			marginHorizontal:3,
			paddingHorizontal: 10,
			paddingVertical:5,
		},
		frequency: {
			borderWidth: 1,
			borderColor: "#24B8B0",
			borderRadius: 50,
			flex: 1,
			fontFamily: 'Helvetica',
			fontSize: 12,
			marginHorizontal:3,
			paddingHorizontal: 20,
			paddingVertical:5,
		},
		noexercises: {
			color: "#24B8B0",
			flex: 1,
			fontSize: 12,
			fontFamily: 'Helvetica-Bold',
			marginBottom: 10
		},
	})

	let typevirtuesdata = []
	if(virtueblock.typevirtuesdata?.length > 0){
		virtueblock.typevirtuesdata?.map((typevirtue) => (
			typevirtue?.map((virtuename) => (
				typevirtuesdata.push(virtuename)
			))
		))
	}

	return (
		<Document>
            <Page size="A4" style={styles.pagina}>
				<View>
					<View style={styles.titulo}>
						<Text>{t('training.alert')}</Text>
					</View>
					<View>
						<Text style={styles.titulo2}>{t('virtuestype.'+virtueblock.type)}</Text>
					</View>
					<View style={styles.caja}>
						{typevirtuesdata?.length > 0
							?
								typevirtuesdata?.map((typevirtue, index) => (
									typevirtue !== undefined && virtueblock?.virtuesdata[typevirtue]?.length > 0
										?
										<Fragment key={index}>
											{ contador === 0 
												?	
												setContador(1) 
												: 
												<></>
											}
											<View style={styles.cadavirtud}>
												<Text style={styles.virtud}>{t('virtues.'+typevirtue)}</Text>												
											</View>
											<View style={styles.cabecera}>
												<Text style={styles.exerciseHead}>{t('training.exercise')}</Text>
												<Text style={styles.levelHead}>{t('training.level')}</Text>
												<Text style={styles.frequencyHead}>{t('training.frequency')}</Text>
											</View>
											<View style={styles.bloqueEjercicios}>
											{virtueblock.virtuesdata[typevirtue]?.map((exercise, index) => (												
												<View style={styles.ejercicio} key={index}>
													<View style={styles.exercise} key={index}><Text>{exercise.description}</Text></View>
													<View style={styles.level}><Text>{t('training.'+exercise.level)}</Text></View>
													<View style={styles.frequency}><Text>{t('training.'+exercise.frequency)}</Text></View>
												</View>
											))}
											</View>
										</Fragment>
										:
										<Fragment key={index}></Fragment>
								))
							:
							<></>								
						}
						{ contador === 0
							?
							<Text style={styles.noexercises}>{t('training.noexercise')} {t('virtuestype.'+virtueblock.type)}</Text>
							:
							<></>
						}
					</View>
				</View>
				{/* <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        			`${pageNumber} / ${totalPages}`
      				)} fixed 
				/> */}
            </Page>
		</Document>
	)
}