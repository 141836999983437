import React,{useEffect, useState, Fragment} from 'react'
import { Container, Row, Col, Card, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { NeverAlways } from '../../../components/never-always'
import { getInitialTest } from '../../../actions/initialTestActions'
import { useNavigate,Link } from 'react-router-dom'

export default function ResultInitialWeek() { 
	const { t } = useTranslation()
	const [miEstado, setMiestado] = useState('')
	const [salud, setSalud] = useState('')
	const [exito, setExito] = useState('')
	const [compania, setCompania] = useState('')
	const [esfuerzo, setEsfuerzo] = useState('')
	const [isLoading, setIsLoading] = useState(true)
	const {client} = useAuth()
	const {test,loading} = useSelector(state=>state.initialTest) 
	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		if(client?.id){
			dispatch(getInitialTest(client?.id))
		}
	}, [dispatch, client])

	useEffect(()=>{
		if(test?.length>0){
			const resultado = calculoInitial(test[test.length-1].responses)

			// PROMEDIO TOTAL
			if(resultado.promedioTotal<2){
				setMiestado('crisis')
			}else{
				if(resultado.promedioTotal<3){
					setMiestado('challenge')
				}else{
					if(resultado.promedioTotal<3.5){
						setMiestado('flow')
					}else{
						setMiestado('wow')
					}
				}
			}

			// PROMEDIO A
			if(resultado.promedioA<2){
				setSalud('crisis')
			}else{
				if(resultado.promedioA<3){
					setSalud('challenge')
				}else{
					if(resultado.promedioA<3.5){
						setSalud('flow')
					}else{
						setSalud('wow')
					}
				}
			}

			// PROMEDIO B
			if(resultado.promedioB<2){
				setExito('crisis')
			}else{
				if(resultado.promedioB<3){
					setExito('challenge')
				}else{
					if(resultado.promedioB<3.5){
						setExito('flow')
					}else{
						setExito('wow')
					}
				}
			}

			// PROMEDIO C
			if(resultado.promedioC<2){
				setCompania('crisis')
			}else{
				if(resultado.promedioC<3){
					setCompania('challenge')
				}else{
					if(resultado.promedioC<3.5){
						setCompania('flow')
					}else{
						setCompania('wow')
					}
				}
			}
		
			// PROMEDIO D
			if(resultado.promedioD<2){
				setEsfuerzo('crisis')
			}else{
				if(resultado.promedioD<3){
					setEsfuerzo('challenge')
				}else{
					if(resultado.promedioD<3.5){
						setEsfuerzo('flow')
					}else{
						setEsfuerzo('wow')
					}
				}
			}
		}
	}, [test, navigate])

	useEffect(()=>{
		setIsLoading(loading)
	}, [loading])
  
	return (
		<>
		{isLoading
			?      
			<div className='d-flex justify-content-center vh-100'>
				<span className="spinner-border spinner-border-sm" style={{width: "5rem",height: "5rem"}}></span>
			</div>
			: 
			<>	
			<Container className='contenido modulo1'>
				<Row className='px-0 px-md-5'>
					<Col className='px-0 px-md-5'>
						<Container className='my-5'>
							<Row>
								<Col className='text-start'>
									<h2>{t('initial.title')}</h2>
									<p>{t('initial.description')}</p>
								</Col>
							</Row>
							<Row>
								<Col className='text-start' id="resultado-individual">
									{['health','success','company','effort'].map((steps,id) => (
										<Fragment key={id}>
											<Card className="my-4">
												<Card.Body>
													<div id={`inital_test_${steps}`} key={steps} >
														<h3 className='titulo-paso-test d-flex align-items-center'>
															<img
																src={`/images/initial_test_${id+1}.png`}
																className="d-inline-block align-top justify-center pe-3"
																alt=""
																width="80"
															/>
															{t('initial.'+steps)}
														</h3>
														{[1,2,3,4,5].map((question) => (
															<div key={`${steps+question}`} attr_key={`${steps+question}`}>
																<Row className='mt-4'>
																	<Col xs="12" lg="6" className='align-self-center'>
																		{t('initial.question'+(question+(id*5)))}
																	</Col>
																	<Col  xs="12" lg="6" className='d-flex flex-column'>
																		<NeverAlways/>
																		<Row className='text-center'>
																		{[1,2,3,4].map((number) => (
																			<Col key={number}>
																				<Form.Check type="radio" name={`q${question+(id*5)}`} value={`${number}`} inline key={`${steps+question+number}`} checked={test[0]?.responses[question+(id*5)-1] === number ? true : false} disabled/>
																			</Col>
																		))}
																		</Row>
																	</Col>
																</Row>
																{question !== 5 
																	?
																		<hr/>
																	:
																		<></>
																}
															</div>
															
														))}
													</div>
												</Card.Body>
											</Card>
											<div className='d-flex align-items-center justify-content-end' >
												{id === 0 ? 
													<Card className="mb-2">
														<Card.Body className='py-2 text-center d-flex align-items-center'>
															<img src={`/images/iconos-estados/`+salud+`.png`} className="img-fluid" alt=""/>
															<h5 className={'text-center text-uppercase ps-3 mb-0 '+salud+'-texto'}>{t(`initial.`+salud)}</h5>
														</Card.Body>
													</Card> : <></>
												}
												{id === 1 ? 
													<Card className="mb-2">
														<Card.Body className='py-2 text-center d-flex align-items-center'>
															<img src={`/images/iconos-estados/`+exito+`.png`} className="img-fluid" alt=""/>
															<h5 className={'text-center text-uppercase ps-3 mb-0 '+exito+'-texto'}>{t(`initial.`+exito)}</h5>
														</Card.Body>
													</Card> : <></>
												}
												{id === 2 ? 
													<Card className="mb-2">
														<Card.Body className='py-2 text-center d-flex align-items-center'>
															<img src={`/images/iconos-estados/`+compania+`.png`} className="img-fluid" alt=""/>
															<h5 className={'text-center text-uppercase ps-3 mb-0 '+compania+'-texto'}>{t(`initial.`+compania)}</h5>
														</Card.Body>
													</Card> : <></>
												}
												{id === 3 ? 
													<Card className="mb-2">
														<Card.Body className='py-2 text-center d-flex align-items-center'>
															<img src={`/images/iconos-estados/`+esfuerzo+`.png`} className="img-fluid" alt=""/>
															<h5 className={'text-center text-uppercase ps-3 mb-0 '+esfuerzo+'-texto'}>{t(`initial.`+esfuerzo)}</h5>
														</Card.Body>
													</Card> : <></>
												}
											</div>
										</Fragment>
									))}
								</Col>
							</Row>
							<Row className="pt-4">
								<Col className='text-center'>
									<h3>{t('initial.title2')}</h3>
								</Col>
							</Row>
							<Row>
								<Col className='my-3'>
									<div className='align-items-center justify-content-center' id="resultado-resumen">
										{['crisis','challenge','flow','wow'].map((estado, indice) => (
												<Row key={indice}>
													<Col className='col-12 col-md-8 mx-auto'>
													{miEstado !== estado ?
														<Card className={`mb-4 w-70 border-grande hover-${estado}`}>
															<Card.Body className='pb-2 d-flex align-items-center'>
																<Col xs='3' className='borde-derecho-verde text-center'>
																	<div className={`img-fluid mx-auto bg-image-${estado}`} alt=""/>
																	<h5 className={`text-center text-uppercase ${estado}-texto`}>{t(`initial.${estado}`)}</h5>
																</Col>
																<Col xs='9' className='hover-description ps-3'>
																	{t(`initial.${estado}-phrase`)}
																</Col>
															</Card.Body>
														</Card>
													:
														<Card className={`mb-4 border-grande hover-${estado}-now`}>
															<Card.Body className='pb-2 d-flex align-items-center'>
																<Col xs='3' className='borde-derecho-verde text-center'>
																	<div className={`img-fluid mx-auto bg-image-${estado}-now`} alt=""/>
																	<h5 className={`text-center text-uppercase ${estado}-texto`}>{t(`initial.${estado}`)}</h5>
																</Col>
																<Col xs='9' className='hover-description ps-3'>
																	{t(`initial.${estado}-phrase`)}
																</Col>
															</Card.Body>
														</Card>
													}
													</Col>
												</Row>
										))}
									</div>
								</Col>
							</Row>
							<Row>
								<Col className='text-center'>
								<Link to="/client/test/conscience/weak" className="btn btn-primary">
									{t('global.continue')}
								</Link>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			</Container>
			</>
		}
		</>
	)
	}

	function calculoInitial(datos){
	let totalA=0 , promedioA=0
	let totalB=0 , promedioB=0
	let totalC=0 , promedioC=0
	let totalD=0 , promedioD=0   
	let promedioTotal=0         
	for(var i=0;i<=19;i++){
		if(i<=4){
			totalA+=datos[i]
		}else{
			if(i<=9){
				totalB+=datos[i]
			}else{
				if(i<=14){
					totalC+=datos[i]
				}else{
					totalD+=datos[i]
				}
			}
		}
	}
	promedioA = totalA /5*2
	promedioB = totalB /5*2
	promedioC = totalC /5*2
	promedioD = totalD /5*4
	promedioTotal= (promedioA+promedioB+promedioC+promedioD)/10  
	return {
		promedioTotal: promedioTotal,
		promedioA: promedioA/2,
		promedioB: promedioB/2,
		promedioC: promedioC/2,
		promedioD: promedioD/4,
	}
}