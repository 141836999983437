import Axios  from "axios"

export const sendHarmfulTest = (user, habit, feelings, action, frequency, quantity, impact) => async (dispatch) => {
    dispatch({
        type: 'HARMFUL_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.post(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/conscience/harmful/register', {
                user: user,
                habit: habit,
                feelings: feelings,
                action: action,
                frequency: frequency,
                quantity: quantity,
                impact: impact
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )      
        if(status===200){            
            dispatch({
                type: 'HARMFUL_TEST_SEND_SUCCESS',
                payload: {
                    _id: data.id,
                    user: user,
                    habit: habit,
                    feelings: feelings,
                    action: action,
                    frequency: frequency,
                    quantity: quantity,
                    impact: impact
                }
            }) 
        }

    } catch (error) {
        dispatch({
            type: 'HARMFUL_TEST_ERROR'           
        })         
    }
}

export const getHarmfulTest = (user) => async (dispatch) => {
    dispatch({
        type: 'HARMFUL_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/conscience/harmful/get/'+ user, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )              
        if(status===200){            
            dispatch({
                type: 'HARMFUL_TEST_GET_SUCCESS',
                payload: data.harmful
            })             
        }

    } catch (error) {
        dispatch({
            type: 'HARMFUL_TEST_ERROR'           
        })         
    }
}

export const removeHarmfulTest = (idHarmful) => async (dispatch) => {
    try {
        const {
            data,
            status
        } = await Axios.delete(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/conscience/harmful/remove/' + idHarmful, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200) {
            dispatch({
                type: 'HARMFUL_DELETE_SUCCESS',
                payload: {
                    idHarmful: idHarmful,
                    message: data.message,
                    count: data.count
                }
            })
        }else{
            dispatch({
                type: 'HARMFUL_DELETE_NOT_SUCCESS',
                payload: {
                    message: data.message,
                    count: data.count
                }
            })
        }
    } catch (error) {
        dispatch({
            type: 'HARMFUL_ERROR'
        })
    }
}