import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFoundPage from './pages/404/NotFoundPage';
import AuthRouter from './routers/AuthRouter';
import AuthProvider from './context/auth/AuthProvider'
import TestRouter from './routers/TestRouter';
import PrivateRouter from './routers/PrivateRouter';
import AdminRouter from './routers/AdminRouter';
import Login from './pages/auth/Login';
import {Landing} from './pages/landing/Landing';

export default function App() {

  return (
    <>
      
      <BrowserRouter>
        <AuthProvider>    
          <Routes>
              <Route path="/" element={ <Login /> }/>          
              <Route path="/landing" element={<Landing />}/>
              <Route path="/auth/*" element={ <AuthRouter />} />
              <Route path="/admin/*" element={ <PrivateRouter><AdminRouter /></PrivateRouter>} />
              <Route path="/client/*" element={ <PrivateRouter><TestRouter /></PrivateRouter>} />
              <Route path="*" element={<NotFoundPage />}/>
          </Routes>
        </AuthProvider>    
      </BrowserRouter>   

    </>
  )
}
