import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { initialTestReducer } from './reducers/initalTestReducer'
import { usersReducer } from './reducers/usersReducer'
import { accessReducer } from './reducers/accessReducer'
import { weakTestReducer } from './reducers/weakTestReducer'
import { mediumhardTestReducer } from './reducers/mediumhardTestReducer'
import { prospectTestReducer } from './reducers/prospectTestReducer'
import { harmfulTestReducer } from './reducers/harmfulTestReducer'
import { weeklyTestReducer } from './reducers/weeklyTestReducer'
import { evaluationTestReducer } from './reducers/evaluationTestReducer'
import { trainingTestReducer } from './reducers/trainingTestReducer'
import { guideReducer } from './reducers/guideReducer'
import { uservirtueReducer } from './reducers/uservirtueReducer'
import { personalTestReducer } from './reducers/personalTestReducer'
import { professionalTestReducer } from './reducers/professionalTestReducer'
import { coupleTestReducer } from './reducers/coupleTestReducer'

const intialState = {}
const appReducer = combineReducers({
    users: usersReducer,
    access: accessReducer,
    initialTest: initialTestReducer,
    weakTest: weakTestReducer,
    mediumhardTest: mediumhardTestReducer,
    prospectTest: prospectTestReducer,
    harmfulTest: harmfulTestReducer,
    weeklyTest: weeklyTestReducer,
    trainingTest: trainingTestReducer,
    evaluationTest: evaluationTestReducer,
    guide: guideReducer,
    uservirtue: uservirtueReducer,
    personalTest: personalTestReducer,
    professionalTest: professionalTestReducer,
    coupleTest: coupleTestReducer
})

const reducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      return appReducer(undefined, action)
    }
  
    return appReducer(state, action)
}

const composeEnhancer = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, intialState, composeEnhancer(applyMiddleware(thunk)))

export default store