import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Container, Row, Col, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { getAllGuide } from '../../actions/guideActions'
import { Link } from 'react-router-dom'
import { Fragment } from 'react'

export default function Guide() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {guides} = useSelector(state=>state.guide)

    const allVirtues = ['aceptacion', 'admiracion', 'alegria', 'compania', 'compasion', 'dignidad', 'disciplina',
        'educacion', 'generosidad', 'guia', 'humildad', 'identidad', 'inspiracion', 'justicia', 'lealtad', 'libertad', 'paciencia',
        'perdon', 'perseverancia', 'proteccion', 'responsabilidad', 'sabiduria', 'valentia']

    const [virtuesWithGuides, setVirtuesWithGuides] = useState({
        'aceptacion': false,
        'admiracion': false,
        'alegria': false,
        'compania': false,
        'compasion': false,
        'dignidad': false,
        'disciplina': false,
        'educacion': false,
        'generosidad': false,
        'guia': false,
        'humildad': false,
        'identidad': false,
        'inspiracion': false,
        'justicia': false,
        'lealtad': false,
        'libertad': false,
        'paciencia': false,
        'perdon': false,
        'perseverancia': false,
        'proteccion': false,
        'responsabilidad': false,
        'sabiduria': false,
        'valentia': false,
    })

    useEffect(() => {
        dispatch(getAllGuide())
    }, [dispatch])

    useEffect(() => {
        if(guides && guides?.length > 0){
            getGuides(guides)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guides])

    const getGuides = async(guides) => {
        let virtuesWithGuidesCopy = virtuesWithGuides
        Promise.all(await guides?.map(async(guide) => {
            virtuesWithGuidesCopy = {...virtuesWithGuidesCopy, [guide.virtue]: true}

            return true
        })).then(
            setVirtuesWithGuides(virtuesWithGuidesCopy)
        )
    }
    return (
        <>
            <Container fluid className='contenido test-responsibility'>
                <Row className='px-3 px-md-5'>
                    <Col xs="12" className='mx-auto'>
                        <div className="form p-4">
                            <div>
                                <Row>
                                    <Col className='px-4 d-flex'>
                                        <h2 className='verde'>{t('guide.guides')}</h2>
                                        <Link to="/admin/dashboard" className='position-fixed icono-home'>
                                            <i className="fas fa-home fa-2x"></i>
                                        </Link>
                                    </Col>
                                </Row>
  
                            </div>
                            <Card className='bg-gray'>
                                <Card.Body className='px-5'>
                                    <Row className='pt-3 pb-4'>
                                        {allVirtues?.map((virtue, ivirtue) => (
                                            <Fragment key={ivirtue}>
                                                {virtuesWithGuides[virtue] === true
                                                    ?
                                                        <Col key={ivirtue} xs={4} className="py-3">
                                                            <Link className='d-block btn btn-primary boton-verde' to={`/admin/explain/${virtue}`}>{t('virtues.'+virtue)}</Link>
                                                        </Col>
                                                    :
                                                        <Col key={ivirtue} xs={4} className="py-3">
                                                            <Link className='d-block btn btn-primary boton-blanco' to={`/admin/explain/${virtue}`}>{t('virtues.'+virtue)}</Link>
                                                        </Col>
                                                }
                                            </Fragment>
                                        ))}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}