import React, { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { getProfessionalTest, sendProfessionalTest } from '../../../../actions/professionalTestActions'
import { Link } from 'react-router-dom'
import { getUservirtue } from '../../../../actions/uservirtueActions'
import { useState } from 'react'
import Swal from 'sweetalert2'
import Select from "react-select"
import { Fragment } from 'react'

export default function Professional() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const {uservirtue} = useSelector(state=>state.uservirtue)
    const {professionalTest} = useSelector(state=>state.professionalTest)

    const [weakVirtues, setWeakVirtues] = useState([])
    const [hardVirtues, setHardVirtues] = useState([])

    const [virtues, setVirtues] = useState({})
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client?.id){
            dispatch(getProfessionalTest(client.id))
            dispatch(getUservirtue(client.id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, dispatch, t])

    useEffect(() => {
        if(uservirtue){
            let weakVirtuesArray = []
            let hardVirtuesArray = []

            for (const virtue in uservirtue) {
                if(virtue !== '_id' && virtue !== '__v' && virtue !== 'user'){
                    if(uservirtue[virtue] === 'debil'){
                        weakVirtuesArray.push(virtue)
                    }
                    if(uservirtue[virtue] === 'fuerte'){
                        hardVirtuesArray.push(virtue)
                    }
                }
            }

            setWeakVirtues(weakVirtuesArray)
            setHardVirtues(hardVirtuesArray)

            let jsonVirtues = {}

            weakVirtuesArray?.map((virtue1)=>{
                if(professionalTest?.virtues[virtue1]?.length > 0 && professionalTest.virtues[virtue1][6] === 'weak'){
                    jsonVirtues[virtue1] = [professionalTest.virtues[virtue1][0], professionalTest.virtues[virtue1][1], professionalTest.virtues[virtue1][2], professionalTest.virtues[virtue1][3], professionalTest.virtues[virtue1][4], professionalTest.virtues[virtue1][5], professionalTest.virtues[virtue1][6]]
                }else{
                    jsonVirtues[virtue1] = ['empty', 'empty', 'empty', '-', '-', '-', 'weak']
                }
                return true
            })

            hardVirtuesArray?.map((virtue1)=>{
                if(professionalTest?.virtues[virtue1]?.length > 0 && professionalTest?.virtues[virtue1][6] === 'hard'){
                    jsonVirtues[virtue1] = [professionalTest.virtues[virtue1][0], professionalTest.virtues[virtue1][1], professionalTest.virtues[virtue1][2], professionalTest.virtues[virtue1][3], professionalTest.virtues[virtue1][4], professionalTest.virtues[virtue1][5], professionalTest.virtues[virtue1][6]]
                }else{
                    jsonVirtues[virtue1] = ['empty', 'empty', 'empty', '-', '-', '-', 'hard']
                }
                return true
            })
            setVirtues(jsonVirtues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uservirtue, professionalTest, t])

    const selectWeakVirtueOptionHandle = (e, virtue, position) => {
        let jsonVirtues = {}

        weakVirtues?.map((virtue1)=>{
            if(virtues[virtue1]){
                jsonVirtues[virtue1] = [virtues[virtue1][0], virtues[virtue1][1], virtues[virtue1][2], virtues[virtue1][3], virtues[virtue1][4], virtues[virtue1][5], 'weak']
            }
            return true
        })

        hardVirtues?.map((virtue1)=>{
            if(virtues[virtue1]){
                jsonVirtues[virtue1] = [virtues[virtue1][0], virtues[virtue1][1], virtues[virtue1][2], virtues[virtue1][3], virtues[virtue1][4], virtues[virtue1][5], 'hard']
            }
            return true
        })

        jsonVirtues[virtue][position] = e.value

        if(jsonVirtues[virtue][0] === true){
            if(jsonVirtues[virtue][0] !== 'empty' && jsonVirtues[virtue][1] !== 'empty' && jsonVirtues[virtue][2] !== 'empty'){
                if(jsonVirtues[virtue][1] === true){
                    if(jsonVirtues[virtue][2] === true){
                        jsonVirtues[virtue][3] = 'wow'
                        jsonVirtues[virtue][4] = 'resultweak6'
                        jsonVirtues[virtue][5] = 'actionweak6'
                    }else{
                        jsonVirtues[virtue][3] = 'challenge'
                        jsonVirtues[virtue][4] = 'resultweak4'
                        jsonVirtues[virtue][5] = 'actionweak4'
                    }
                }else{
                    if(jsonVirtues[virtue][2] === true){
                        jsonVirtues[virtue][3] = 'flow'
                        jsonVirtues[virtue][4] = 'resultweak5'
                        jsonVirtues[virtue][5] = 'actionweak5'
                    }else{
                        jsonVirtues[virtue][3] = 'crisis'
                        jsonVirtues[virtue][4] = 'resultweak3'
                        jsonVirtues[virtue][5] = 'actionweak3'
                    }
                }
            }else{
                jsonVirtues[virtue][3] = '-'
                jsonVirtues[virtue][4] = '-'
                jsonVirtues[virtue][5] = '-'
            }
        }else{
            if(jsonVirtues[virtue][0] !== 'empty' && jsonVirtues[virtue][2] !== 'empty'){
                if(jsonVirtues[virtue][2] === true){
                    jsonVirtues[virtue][3] = 'challenge'
                    jsonVirtues[virtue][4] = 'resultweak2'
                    jsonVirtues[virtue][5] = 'actionweak2'
                }else{
                    jsonVirtues[virtue][3] = 'crisis'
                    jsonVirtues[virtue][4] = 'resultweak1'
                    jsonVirtues[virtue][5] = 'actionweak1'
                }
            }else{
                jsonVirtues[virtue][3] = '-'
                jsonVirtues[virtue][4] = '-'
                jsonVirtues[virtue][5] = '-'
            }
        }

        setVirtues(jsonVirtues)
    }

    const selectHardVirtueOptionHandle = (e, virtue, position) => {
        let jsonVirtues = {}

        weakVirtues?.map((virtue1)=>{
            if(virtues[virtue1]){
                jsonVirtues[virtue1] = [virtues[virtue1][0], virtues[virtue1][1], virtues[virtue1][2], virtues[virtue1][3], virtues[virtue1][4], virtues[virtue1][5], 'weak']
            }
            return true
        })

        hardVirtues?.map((virtue1)=>{
            if(virtues[virtue1]){
                jsonVirtues[virtue1] = [virtues[virtue1][0], virtues[virtue1][1], virtues[virtue1][2], virtues[virtue1][3], virtues[virtue1][4], virtues[virtue1][5], 'hard']
            }
            return true
        })

        jsonVirtues[virtue][position] = e.value

        if(jsonVirtues[virtue][0] === true){
            jsonVirtues[virtue][3] = 'wow'
            jsonVirtues[virtue][4] = 'resulthard4'
            jsonVirtues[virtue][5] = 'actionhard4'
        }else{
            if(jsonVirtues[virtue][1] === true){
                if(jsonVirtues[virtue][0] !== 'empty' && jsonVirtues[virtue][1] !== 'empty'){
                    if(jsonVirtues[virtue][2] === true){
                        jsonVirtues[virtue][3] = 'flow'
                        jsonVirtues[virtue][4] = 'resulthard3'
                        jsonVirtues[virtue][5] = 'actionhard3'
                    }else{
                        if(jsonVirtues[virtue][2] !== 'empty'){
                            jsonVirtues[virtue][3] = 'challenge'
                            jsonVirtues[virtue][4] = 'resulthard2'
                            jsonVirtues[virtue][5] = 'actionhard2'
                        }else{
                            jsonVirtues[virtue][3] = '-'
                            jsonVirtues[virtue][4] = '-'
                            jsonVirtues[virtue][5] = '-'
                        }
                    }
                }else{
                    jsonVirtues[virtue][3] = '-'
                    jsonVirtues[virtue][4] = '-'
                    jsonVirtues[virtue][5] = '-'
                }
            }else{
                jsonVirtues[virtue][3] = 'flow'
                jsonVirtues[virtue][4] = 'resulthard1'
                jsonVirtues[virtue][5] = 'actionhard1'
            }
        }

        setVirtues(jsonVirtues)
    }

    const saveProfessional = () => {
        dispatch(sendProfessionalTest({user: client.id, virtues: virtues}))
        Swal.fire({
            icon: 'success',
            text: t('impact.professionaledited'),
            showConfirmButton: true,
            confirmButtonText: t('impact.great')
        })
    }

    return (
    <>
        <Container fluid className='contenido modulo3'>
            <Row className='px-1 px-md-5'>
                <Col xs="12" className='mx-auto'>
                    <div className="form p-md-4 p-0">
                        <div>
                            <Row>
                                <Col className='px-4 d-flex'>
                                    <h2 className='verde'>{t('impact.title')}</h2>
                                    <Link to="/client/profile" className='position-fixed icono-home'>
                                        <i className="fas fa-home fa-2x"></i>
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='px-4 pb-3'>
                                    <div className="alert auto alert-success text-center" role="alert">
                                        <h3 className='m-0 text-white'>{t('impact.alertprofessional')}</h3>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <h4 className='text-center d-block d-md-none'>{t('impact.myvirtuesweak')}</h4>
                        <Card className='mb-3 impacto'>
                            <Card.Body className='px-2 px-md-5'>
                            
                                <Row>
                                    <Col className='px-3'>                                        
                                        {virtues
                                            ?
                                            <>
                                                <Row className='pb-3 d-none d-md-flex align-items-center small border-bottom border-3 cabecera-fija'>
                                                    <Col className='text-center'><strong>{t('impact.myvirtuesweak')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.mybusiness')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.trainingweak')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.midlevelweak')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.level')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.result')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.action')}</strong></Col>
                                                </Row>
                                                {weakVirtues?.map((virtue, id)=>(
                                                    <Row key={id} className={`py-2 small d-block d-md-flex ${id % 2 === 0 ? 'bg-striped-gray' : 'bg-striped-white'} `}>
                                                        <Col className='d-flex align-items-center'>                                                            
                                                            <p className='text-uppercase'><strong>{t('virtues.'+virtue)}</strong></p>
                                                        </Col>
                                                        <Col className='d-block d-md-flex align-items-center'>
                                                            <p className='d-block d-md-none mb-0'><strong>{t('impact.mybusiness')}</strong></p>
                                                            <Select
                                                                className="form-control form-control-verde" 
                                                                name={'select-'+virtue}
                                                                options={[
                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                    {label: t('impact.true'), value: true},
                                                                    {label: t('impact.false'), value: false}
                                                                ]}
                                                                value={{label: t('impact.'+virtues[virtue][0]), value: virtues[virtue][0]}}
                                                                onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 0)}
                                                            />
                                                        </Col>
                                                        <Col className='d-block d-md-flex align-items-center'>
                                                            <p className='d-block d-md-none mt-3 mb-0'><strong>{t('impact.trainingweak')}</strong></p>
                                                            <Select
                                                                className={`form-control form-control-verde ${virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? 'disabled' : '' }`} 
                                                                name={'select-'+virtue}
                                                                options={[
                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                    {label: t('impact.true'), value: true},
                                                                    {label: t('impact.false'), value: false}
                                                                ]}
                                                                value={virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? {label: t('impact.false'), value: false} : {label: t('impact.'+virtues[virtue][1]), value: virtues[virtue][1]}}
                                                                onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 1)}
                                                                isDisabled={virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? true : false}
                                                            />
                                                        </Col>
                                                        <Col className='d-block d-md-flex align-items-center'>
                                                            <p className='d-block d-md-none mt-3 mb-0'><strong>{t('impact.midlevelweak')}</strong></p>
                                                            <Select
                                                                className="form-control form-control-verde" 
                                                                name={'select-'+virtue}
                                                                options={[
                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                    {label: t('impact.true'), value: true},
                                                                    {label: t('impact.false'), value: false}
                                                                ]}
                                                                value={{label: t('impact.'+virtues[virtue][2]), value: virtues[virtue][2]}}
                                                                onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 2)}
                                                            />
                                                        </Col>
                                                        <Col className='d-block d-md-flex align-items-center justify-content-center'>
                                                            <p className='d-block d-md-none mt-3 mb-0'><strong>{t('impact.level')}</strong></p>
                                                            <p>{t('impact.'+virtues[virtue][3])}</p>
                                                        </Col>
                                                        <Col className='d-block d-md-flex align-items-center'>
                                                            <p className='d-block d-md-none mt-3 mb-0'><strong>{t('impact.result')}</strong></p>
                                                            <p>{t('impact.'+virtues[virtue][4])}</p>
                                                        </Col>
                                                        <Col className='d-block d-md-flex align-items-center'>
                                                            <p className='d-block d-md-none mt-3 mb-0v'><strong>{t('impact.action')}</strong></p>
                                                            <p>{t('impact.'+virtues[virtue][5])}</p>
                                                        </Col>
                                                        <hr className='d-block d-md-none'></hr>
                                                    </Row>                                                    
                                                ))}
                                                {/* <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>{t('impact.myvirtuesweak')}</th>
                                                            <th>{t('impact.mybusiness')}</th>
                                                            <th>{t('impact.trainingweak')}</th>
                                                            <th>{t('impact.midlevelweak')}</th>
                                                            <th>{t('impact.level')}</th>
                                                            <th>{t('impact.result')}</th>
                                                            <th>{t('impact.action')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {weakVirtues?.map((virtue, id)=>(
                                                            <Fragment key={id}>
                                                                {virtues[virtue]
                                                                    ?
                                                                        <tr>
                                                                            <td>{t('virtues.'+virtue)}</td>
                                                                            <td>
                                                                                <Select
                                                                                    className="form-control form-control-verde" 
                                                                                    name={'select-'+virtue}
                                                                                    options={[
                                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                                        {label: t('impact.true'), value: true},
                                                                                        {label: t('impact.false'), value: false}
                                                                                    ]}
                                                                                    value={{label: t('impact.'+virtues[virtue][0]), value: virtues[virtue][0]}}
                                                                                    onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 0)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Select
                                                                                    className={`form-control form-control-verde ${virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? 'disabled' : '' }`} 
                                                                                    name={'select-'+virtue}
                                                                                    options={[
                                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                                        {label: t('impact.true'), value: true},
                                                                                        {label: t('impact.false'), value: false}
                                                                                    ]}
                                                                                    value={virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? {label: t('impact.false'), value: false} : {label: t('impact.'+virtues[virtue][1]), value: virtues[virtue][1]}}
                                                                                    onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 1)}
                                                                                    isDisabled={virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? true : false}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Select
                                                                                    className="form-control form-control-verde" 
                                                                                    name={'select-'+virtue}
                                                                                    options={[
                                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                                        {label: t('impact.true'), value: true},
                                                                                        {label: t('impact.false'), value: false}
                                                                                    ]}
                                                                                    value={{label: t('impact.'+virtues[virtue][2]), value: virtues[virtue][2]}}
                                                                                    onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 2)}
                                                                                />
                                                                            </td>
                                                                            <td>{t('impact.'+virtues[virtue][3])}</td>
                                                                            <td>{t('impact.'+virtues[virtue][4])}</td>
                                                                            <td>{t('impact.'+virtues[virtue][5])}</td>
                                                                        </tr>
                                                                    :
                                                                        <></>
                                                                }
                                                            </Fragment>
                                                        ))}
                                                    </tbody>
                                                </Table> */}
                                            </>
                                            :
                                                <>{t('impact.thereisnovirtues')}</>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                         </Card>
                        <h4 className='text-center d-block d-md-none'>{t('impact.myvirtueshard')}</h4>
                        <Card className='mb-3 impacto'>
                            <Card.Body className='px-2 px-md-5'>
                                <Row>
                                    <Col className='px-3'>
                                        {virtues
                                            ?
                                            <>
                                                <Row className='pb-3 d-none d-md-flex align-items-center small border-bottom border-3 cabecera-fija'>
                                                    <Col className='text-center'><strong>{t('impact.myvirtueshard')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.mybusiness')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.traininghard')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.midlevelhard')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.level')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.result')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.action')}</strong></Col>
                                                </Row>
                                                {hardVirtues?.map((virtue, id)=>(
                                                    <Row key={id} className={`py-2 small d-block d-md-flex ${id % 2 === 0 ? 'bg-striped-gray' : 'bg-striped-white'} `}>
                                                        <Col className='d-flex align-items-center'>
                                                            <p className='text-uppercase'><strong>{t('virtues.'+virtue)}</strong></p>
                                                        </Col>
                                                        <Col className='d-block d-md-flex align-items-center'>
                                                            <p className='d-block d-md-none mt-3 mb-0'><strong>{t('impact.mybusiness')}</strong></p>
                                                            <Select
                                                                className="form-control form-control-verde" 
                                                                name={'select-'+virtue}
                                                                options={[
                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                    {label: t('impact.true'), value: true},
                                                                    {label: t('impact.false'), value: false}
                                                                ]}
                                                                value={{label: t('impact.'+virtues[virtue][0]), value: virtues[virtue][0]}}
                                                                onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 0)}
                                                            />
                                                        </Col>
                                                        <Col className='d-block d-md-flex align-items-center'>
                                                            <p className='d-block d-md-none mt-3 mb-0'><strong>{t('impact.traininghard')}</strong></p>
                                                            <Select
                                                                className={`form-control form-control-verde ${virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? 'disabled' : '' }`}
                                                                name={'select-'+virtue}
                                                                options={[
                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                    {label: t('impact.true'), value: true},
                                                                    {label: t('impact.false'), value: false}
                                                                ]}
                                                                value={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? {label: t('impact.true'), value: true} : {label: t('impact.'+virtues[virtue][1]), value: virtues[virtue][1]}}
                                                                onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 1)}
                                                                isDisabled={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? true : false}
                                                            />
                                                        </Col>
                                                        <Col className='d-block d-md-flex align-items-center'>
                                                            <p className='d-block d-md-none mt-3 mb-0'><strong>{t('impact.midlevelhard')}</strong></p>
                                                            <Select
                                                                className={`form-control form-control-verde ${(virtues[virtue][0] === true || virtues[virtue][0] === 'true') || ((virtues[virtue][0] === false || virtues[virtue][0] === 'false') && (virtues[virtue][1] === false || virtues[virtue][1] === 'false')) ? 'disabled' : '' }`}
                                                                name={'select-'+virtue}
                                                                options={[
                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                    {label: t('impact.true'), value: true},
                                                                    {label: t('impact.false'), value: false}
                                                                ]}
                                                                value={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? {label: t('impact.true'), value: true} : {label: t('impact.'+virtues[virtue][2]), value: virtues[virtue][2]}}
                                                                onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 2)}
                                                                isDisabled={(virtues[virtue][0] === true || virtues[virtue][0] === 'true') || ((virtues[virtue][0] === false || virtues[virtue][0] === 'false') && (virtues[virtue][1] === false || virtues[virtue][1] === 'false')) ? true : false}
                                                            />
                                                        </Col>
                                                        <Col className='d-block d-md-flex align-items-center justify-content-center'>
                                                            <p className='d-block d-md-none mt-3 mb-0'><strong>{t('impact.level')}</strong></p>
                                                            <p>{t('impact.'+virtues[virtue][3])}</p>
                                                        </Col>
                                                        <Col className='d-block d-md-flex align-items-center'>
                                                            <p className='d-block d-md-none mt-3 mb-0'><strong>{t('impact.result')}</strong></p>
                                                            <p>{t('impact.'+virtues[virtue][4])}</p>
                                                        </Col>
                                                        <Col className='d-block d-md-flex align-items-center'>
                                                            <p className='d-block d-md-none mt-3 mb-0'><strong>{t('impact.action')}</strong></p>
                                                            <p>{t('impact.'+virtues[virtue][5])}</p>
                                                        </Col>
                                                        <hr className='d-block d-md-none'></hr>
                                                    </Row>
                                                ))}
                                                {/* <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>{t('impact.myvirtueshard')}</th>
                                                            <th>{t('impact.mybusiness')}</th>
                                                            <th>{t('impact.traininghard')}</th>
                                                            <th>{t('impact.midlevelhard')}</th>
                                                            <th>{t('impact.level')}</th>
                                                            <th>{t('impact.result')}</th>
                                                            <th>{t('impact.action')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {hardVirtues?.map((virtue, id)=>(
                                                            <Fragment key={id}>
                                                                {virtues[virtue]
                                                                    ?
                                                                        <tr>
                                                                            <td>{t('virtues.'+virtue)}</td>
                                                                            <td>
                                                                                <Select
                                                                                    className="form-control form-control-verde" 
                                                                                    name={'select-'+virtue}
                                                                                    options={[
                                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                                        {label: t('impact.true'), value: true},
                                                                                        {label: t('impact.false'), value: false}
                                                                                    ]}
                                                                                    value={{label: t('impact.'+virtues[virtue][0]), value: virtues[virtue][0]}}
                                                                                    onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 0)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Select
                                                                                    className={`form-control form-control-verde ${virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? 'disabled' : '' }`}
                                                                                    name={'select-'+virtue}
                                                                                    options={[
                                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                                        {label: t('impact.true'), value: true},
                                                                                        {label: t('impact.false'), value: false}
                                                                                    ]}
                                                                                    value={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? {label: t('impact.true'), value: true} : {label: t('impact.'+virtues[virtue][1]), value: virtues[virtue][1]}}
                                                                                    onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 1)}
                                                                                    isDisabled={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? true : false}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Select
                                                                                    className={`form-control form-control-verde ${(virtues[virtue][0] === true || virtues[virtue][0] === 'true') || ((virtues[virtue][0] === false || virtues[virtue][0] === 'false') && (virtues[virtue][1] === false || virtues[virtue][1] === 'false')) ? 'disabled' : '' }`}
                                                                                    name={'select-'+virtue}
                                                                                    options={[
                                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                                        {label: t('impact.true'), value: true},
                                                                                        {label: t('impact.false'), value: false}
                                                                                    ]}
                                                                                    value={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? {label: t('impact.true'), value: true} : {label: t('impact.'+virtues[virtue][2]), value: virtues[virtue][2]}}
                                                                                    onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 2)}
                                                                                    isDisabled={(virtues[virtue][0] === true || virtues[virtue][0] === 'true') || ((virtues[virtue][0] === false || virtues[virtue][0] === 'false') && (virtues[virtue][1] === false || virtues[virtue][1] === 'false')) ? true : false}
                                                                                />
                                                                            </td>
                                                                            <td>{t('impact.'+virtues[virtue][3])}</td>
                                                                            <td>{t('impact.'+virtues[virtue][4])}</td>
                                                                            <td>{t('impact.'+virtues[virtue][5])}</td>
                                                                        </tr>
                                                                    :
                                                                        <></>
                                                                }
                                                            </Fragment>
                                                        ))}
                                                    </tbody>
                                                </Table> */}
                                            </>
                                            :
                                                <>{t('impact.thereisnovirtues')}</>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                         </Card>
                        <Row className='mb-3'>
                            <Col className='text-end'>
                                <Button variant='primary' type='buttom' onClick={saveProfessional}>
                                    {t('global.save')}
                                </Button>
                            </Col>
                        </Row>
                        <Card>
                            <Card.Body className='px-2 px-md-5'>
                                <Row>
                                    <Col className='px-3'>
                                        <Row className='pb-3 d-none d-md-flex align-items-center small border-bottom border-3'>                                            
                                            <Col className='text-center'><strong>{t('impact.level')}</strong></Col>
                                            <Col className='text-center'><strong>{t('impact.scene')}</strong></Col>
                                            <Col className='text-center'><strong>{t('impact.recommendation')}</strong></Col>
                                        </Row>
                                        <Row className='py-4 d-block d-md-flex align-items-center small border-bottom border-3'>
                                            <Col className='text-center text-uppercase'><strong>{t('impact.crisis')}</strong></Col>  
                                            <Col>
                                                <strong className='d-block d-md-none'>{t('impact.scene')}</strong>
                                                <p>{t('impact.crisisscene1')}</p>
                                                <p>{t('impact.crisisscene2')}</p>
                                                <p>{t('impact.crisisscene3')}</p>
                                                <p>{t('impact.crisisscene4')}</p>
                                            </Col>
                                            <Col className='text-start text-md-center'>
                                                <strong className='d-block d-md-none'>{t('impact.recommendation')}</strong>
                                                {t('impact.crisisrecommendation')}
                                            </Col>
                                        </Row>
                                        <Row className='py-4 d-block d-md-flex align-items-center small border-bottom border-3'>
                                            <Col className='text-center text-uppercase'><strong>{t('impact.challenge')}</strong></Col>  
                                            <Col>
                                                <strong className='d-block d-md-none'>{t('impact.scene')}</strong>
                                                <p>{t('impact.challengescene1')}</p>
                                                <p>{t('impact.challengescene2')}</p>                                                
                                            </Col>
                                            <Col className='text-start text-md-center'>
                                                <strong className='d-block d-md-none'>{t('impact.recommendation')}</strong>
                                                {t('impact.challengerecommendation')}
                                            </Col>
                                        </Row>
                                        <Row className='py-4 d-block d-md-flex align-items-center small border-bottom border-3'>
                                            <Col className='text-center text-uppercase'><strong>{t('impact.flow')}</strong></Col>  
                                            <Col>
                                                <strong className='d-block d-md-none'>{t('impact.scene')}</strong>
                                                <p>{t('impact.flowscene1')}</p>
                                                <p>{t('impact.flowscene2')}</p>
                                            </Col>
                                            <Col className='text-start text-md-center'>
                                                <strong className='d-block d-md-none'>{t('impact.recommendation')}</strong>
                                                {t('impact.flowrecommendation')}
                                            </Col>
                                        </Row>   
                                        <Row className='py-4 d-block d-md-flex align-items-center small'>
                                            <Col className='text-center text-uppercase'><strong>{t('impact.wow')}</strong></Col>  
                                            <Col>
                                                <strong className='d-block d-md-none'>{t('impact.scene')}</strong>
                                                <p>{t('impact.wowscene1')}</p>
                                                <p>{t('impact.wowscene2')}</p>
                                            </Col>
                                            <Col className='text-start text-md-center'>
                                                <strong className='d-block d-md-none'>{t('impact.recommendation')}</strong>
                                                {t('impact.wowrecommendation')}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                         </Card>
                    </div>
                </Col>
            </Row>
            <Row className='my-4 botones-pasos'>
                <Col className="d-flex justify-content-md-space-around justify-content-center flex-wrap">
                    
                    <Link to="/client/test/impact/personalresume">
                        <Button className='boton-gris' variant='primary' type="submit">
                            {t('impact.personal')}
                        </Button>
                    </Link>
                    
                    <Button className='boton-blanco' variant='primary' type="submit">
                        {t('impact.professional')}
                    </Button>
                    
            
                    <Link to="/client/test/impact/couple">
                        <Button className='boton-gris' variant='primary' type="submit">
                            {t('impact.couple')}
                        </Button>
                    </Link>
                            
                </Col>
            </Row>       
        </Container>
    </>
    )
}