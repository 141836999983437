export const mediumhardTestReducer = (state = {loading:true,result:null,test:null} , action) => {
    switch (action.type) {
        case 'MEDIUMHARD_TEST_SEND_SUCCESS':
            return {
                ...state,
                loading:false,
                result: action.payload
            }
        case 'MEDIUMHARD_TEST_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                test: action.payload
            }
        case 'MEDIUMHARD_TEST_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'MEDIUMHARD_TEST_ERROR':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}

