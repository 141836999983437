import React,{useEffect, useState} from 'react'
import {Container,Row,Col,Card, Accordion, Form, Button} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetail } from './../../../../actions/usersActions'
import { getUservirtue } from './../../../../actions/uservirtueActions'
import { editUservirtueManifest } from './../../../../actions/uservirtueActions'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PDFDownloadLink } from '@react-pdf/renderer'
import ManifestReport from './manifestReport'


export default function Manifest() { 
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()
    const {uservirtue} = useSelector(state=>state.uservirtue)
    const [weakVirtues, setWeakVirtues] = useState([])
    const [mediumVirtues, setMediumVirtues] = useState([])
    const [hardVirtues, setHardVirtues] = useState([])

    const [manifestVirtues, setManifestVirtues] = useState({})

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })    
        if(client?.id){
            dispatch(getUserDetail(client?.id))
            dispatch(getUservirtue(client?.id))
        }
    }, [dispatch, client])

    useEffect(() => {
        if(uservirtue){
            let weakVirtuesArray = []
            let mediumVirtuesArray = []
            let hardVirtuesArray = []
            let nonejson = {}
            let manifestVirtuesJson = {}

            for (const virtue in uservirtue) {
                if(virtue !== '_id' && virtue !== '__v' && virtue !== 'user'){
                    if(uservirtue[virtue] === 'debil' || uservirtue[virtue] === 'media' || uservirtue[virtue] === 'fuerte'){
                        if(uservirtue[virtue] === 'debil'){
                            weakVirtuesArray.push(virtue)
                            nonejson[virtue] = 'nada'
                        }
                        if(uservirtue[virtue] === 'media'){
                            mediumVirtuesArray.push(virtue)
                        }
                        if(uservirtue[virtue] === 'fuerte'){
                            hardVirtuesArray.push(virtue)
                        }
                    }else{
                        manifestVirtuesJson[virtue] = uservirtue[virtue]
                    }
                }
            }

            setWeakVirtues(weakVirtuesArray)
            setMediumVirtues(mediumVirtuesArray)
            setHardVirtues(hardVirtuesArray)
            setManifestVirtues(manifestVirtuesJson)
        }
    }, [uservirtue])

    const inputChange = (e) =>{
        let manifestVirtuesJson = manifestVirtues

        if(e.target.value !== 'debil' && e.target.value !== 'media' && e.target.value !== 'fuerte'){
            manifestVirtuesJson[e.target.name] = e.target.value
        }

        setManifestVirtues(manifestVirtuesJson)
    }

    const sendManifests = () =>{
        dispatch(editUservirtueManifest(client?.id, manifestVirtues))

        Swal.fire({
            showConfirmButton: true,
            confirmButtonText: t('manifest.great'),
            icon: 'success',
            text: t('manifest.updatesuccess')
        })
    }

    return (
        <>
            <Container fluid className='contenido modulo2'>
                <Row className='px-0 px-md-2'>
                    <Col xs="12" className='mx-auto'>
                        <div className="form p-2 p-md-4">
                            <div id="manifest_test_header">
                                <Row>
                                    <Col className='px-4 d-flex'>
                                        <h2 className='verde'>{t('manifest.title')}</h2>
                                        <Link to="/client/profile" className='position-fixed icono-home'>
                                            <i className="fas fa-home fa-2x"></i>
                                        </Link>
                                    </Col>
                                </Row>                                
                                <Row>
                                    <Col className='px-4 pb-3'>
                                        <div className="alert auto alert-success text-center" role="alert">
                                            <h3 className='m-0 text-white'>{t('manifest.alert')}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='px-4 pb-3'>
                                        <PDFDownloadLink                                        
                                            document={
                                                <ManifestReport
                                                    hardVirtues={hardVirtues}
                                                    mediumVirtues={mediumVirtues}
                                                    weakVirtues={weakVirtues}
                                                    manifestVirtues={manifestVirtues}
                                                />
                                            } 
                                            fileName={'resume_manifest.pdf'}
                                        >
                                            <Button className='btn btn-secondary'><i className="fa fa-download"></i> {t('manifest.download')}</Button>
                                        </PDFDownloadLink>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='px-4'>
                                        <p className='my-0'><strong>{t('manifest.paragraph1')}</strong></p>
                                    </Col>
                                </Row>
                            </div>
                            <Card className='my-4'>
                                <Card.Body className='px-0 px-md-2'>
                                    <Row className='justify-content-center justify-content-md-between'>
                                        <Col className='columna-virtudes my-3'>
                                            <Row>
                                                <Col className='d-flex justify-content-center'>
                                                    <div className="alert auto alert-success text-center d-flex" role="alert">
                                                        <h4 className='m-0 my-1 text-white float-right'>{t('resume.virtues-to-train')}</h4>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='px-5'>
                                            <Accordion>
                                                {weakVirtues?.map((virtue, id)=>{
                                                    return (
                                                        virtue.virtue !== '' 
                                                        ?
                                                            <Row key={id}>
                                                                <Col xs={12} md={4}>
                                                                    <Accordion.Item eventKey={id} key={id} className='virtudes-sincolor'>
                                                                        <Accordion.Header>{t('virtues.'+virtue)}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            {t('descriptions.'+virtue)}
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Col>
                                                                <Col xs={12} md={8} className='mb-4'>
                                                                    <Form.Group>
                                                                        <Form.Control
                                                                            type='text'
                                                                            name={virtue+'Manifest'} 
                                                                            defaultValue={manifestVirtues?.[virtue+'Manifest'] ||  ''}
                                                                            onChange={(e) =>inputChange(e)}
                                                                            required
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        :
                                                            <></>
                                                    )
                                                })}
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>                            
                            <Card className='my-4'>
                                <Card.Body className='px-0 px-md-2'>
                                    <Row className='justify-content-center justify-content-md-between'>
                                        <Col className='columna-virtudes my-3'>
                                            <Row>
                                                <Col className='d-flex justify-content-center'>
                                                    <div className="alert auto alert-success text-center d-flex" role="alert">
                                                        <h4 className='m-0 my-1 text-white float-right'>{t('resume.virtues-hard')}</h4>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='px-5'>
                                            <Accordion> 
                                                {hardVirtues?.map((virtue, id)=>{
                                                    return (
                                                        virtue.virtue !== '' 
                                                        ?
                                                            <Row key={id}>
                                                                <Col xs={12} md={4}>
                                                                    <Accordion.Item eventKey={id} key={id} className="virtudes-sincolor">
                                                                        <Accordion.Header>{t('virtues.'+virtue)}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            {t('descriptions.'+virtue )}
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Col>
                                                                <Col xs={12} md={8} className='mb-4'>
                                                                    <Form.Group>
                                                                        <Form.Control
                                                                            type='text'
                                                                            name={virtue+'Manifest'} 
                                                                            defaultValue={manifestVirtues?.[virtue+'Manifest'] ||  ''}
                                                                            onChange={(e) =>inputChange(e)}
                                                                            required
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        :
                                                            <></>
                                                    )
                                                })}
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className='my-4'>
                                <Card.Body className='px-0 px-md-2'>
                                    <Row className='justify-content-center justify-content-md-between'>
                                        <Col className='columna-virtudes my-3'>
                                            <Row>
                                                <Col className='d-flex justify-content-center'>
                                                    <div className="alert auto alert-success text-center d-flex" role="alert">
                                                        <h4 className='m-0 my-1 text-white float-right'>{t('resume.virtues-medium')}</h4>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='px-5'>
                                            <Accordion>
                                                {mediumVirtues?.map((virtue, id)=>{
                                                    return (
                                                        virtue.virtue !== '' 
                                                        ?
                                                            <Row key={id}>
                                                                <Col xs={12} md={4}>
                                                                    <Accordion.Item eventKey={id} key={id} className="virtudes-sincolor">
                                                                        <Accordion.Header>{t('virtues.'+virtue)}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            {t('descriptions.'+virtue)}
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Col>
                                                                <Col xs={12} md={8} className='mb-4'>
                                                                    <Form.Group>
                                                                        <Form.Control
                                                                            type='text'
                                                                            name={virtue+'Manifest'}
                                                                            defaultValue={manifestVirtues?.[virtue+'Manifest'] ||  ''}
                                                                            onChange={(e) =>inputChange(e)}
                                                                            required
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        :
                                                            <></>
                                                    )
                                                })}
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                        <Row className='my-4 botones-pasos'>
                            <Col className="d-flex justify-content-end flex-wrap">                                
                                <Button variant='primary' type="submit" onClick={sendManifests}>
                                    {t('global.save')}
                                </Button>
                            </Col>
                        </Row>  
                    </Col>
                </Row>
            </Container>
        </>
    )
}