import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import NotFoundPage from '../pages/404/NotFoundPage'
import {Container} from 'react-bootstrap'
import MenuPrivate from '../components/menu-private'
import MenuAdmin from '../components/menu-admin'
import Initial from '../pages/client/test/Initial'
import Conscience from '../pages/client/test/Conscience'
import Profile from '../pages/client/Profile'
import Editprofile from '../pages/client/Editprofile'
import ResultInitialWeek from '../pages/client/test/ResultInitialWeek'
import Resume from '../pages/client/test/Resume'
import Resume2 from '../pages/client/test/Resume2'
import Polarity from '../pages/client/test/Polarity'
import { Footer } from '../components/footer'
import Weak from '../pages/client/test/conscience/01-weak'
import Prospect from '../pages/client/test/conscience/02-prospect'
import Mediumhard from '../pages/client/test/conscience/03-mediumhard'
import Harmful from '../pages/client/test/conscience/04-harmful'
import useAuth from '../context/auth/useAuth'

import Weekly from '../pages/client/test/responsibility/weekly'
import Weeklyresult from '../pages/client/test/responsibility/weeklyresult'
import Evaluation from '../pages/client/test/responsibility/evaluation'
import Historical from '../pages/client/test/responsibility/historical'
import Training from '../pages/client/test/responsibility/training'
import Essential from '../pages/client/test/responsibility/essential'
import Guide from '../pages/client/test/responsibility/guide'
import Explain from '../pages/client/test/responsibility/explain'
import Manifest from '../pages/client/test/responsibility/manifest'

import Personal from '../pages/client/test/impact/personal'
import Personalresume from '../pages/client/test/impact/personalresume'
import Professional from '../pages/client/test/impact/professional'
import Couple from '../pages/client/test/impact/couple'

export default function TestRouter () {
    const {client} = useAuth()
	const [color, setColor] = useState('transparent')
    const [fixed, setFixed] = useState('no-fixed')
	window.addEventListener('scroll', function(e) {
	  if(this.scrollY>50&&color==='transparent'){      
      setColor('#fff')
      setFixed('fixed');
	  }else{
      if(this.scrollY<=50&&color==='#fff'){
        setFixed('no-fixed')
        setColor('transparent')        
      }
	  }
	});	     

    return (
        <>
            <Container fluid>
                {
                    client?.tests[0]==='all'
                    ?
                    <MenuAdmin background={color} fixed={fixed}/>        
                    :
                    <MenuPrivate/>
                }
                <Routes>
                    <Route path="/profile" element={<Profile />}/>
                    <Route path="/editprofile" element={<Editprofile />}/>
                    <Route path="/resume" element={<Resume />}/>
                    <Route path="/resume2" element={<Resume2 />}/>
                    <Route path="/polarity" element={<Polarity />}/>                    

                    {/* Fase 1 */}
                    <Route path="/test/conscience" element={<Conscience />}/>
                    <Route path="/test/conscience/weak" element={<Weak />}/>
                    <Route path="/test/conscience/mediumhard" element={<Mediumhard />}/>
                    <Route path="/test/conscience/prospect" element={<Prospect />}/>
                    <Route path="/test/conscience/harmful" element={<Harmful />}/>
                    <Route path="/test/initial" element={<Initial />}/>
                    <Route path="/result" element={<ResultInitialWeek />}/>

                    {/* Fase 2 */}
                    <Route path="/test/responsibility/weekly" element={<Weekly />}/>
                    <Route path="/test/responsibility/weeklyresult" element={<Weeklyresult />}/>
                    <Route path="/test/responsibility/evaluation" element={<Evaluation />}/>
                    <Route path="/test/responsibility/training" element={<Training />}/>
                    <Route path="/test/responsibility/historical" element={<Historical />}/>
                    <Route path="/test/responsibility/essential" element={<Essential />}/>
                    <Route path="/test/responsibility/guide" element={<Guide />}/>
                    <Route path="/test/responsibility/explain/:virtue" element={<Explain />}/>
                    <Route path="/test/responsibility/manifest" element={<Manifest />}/>

                    {/* Fase 3 */}
                    <Route path="/test/impact/personal" element={<Personal />}/>
                    <Route path="/test/impact/personalresume" element={<Personalresume />}/>
                    <Route path="/test/impact/professional" element={<Professional />}/>
                    <Route path="/test/impact/couple" element={<Couple />}/>

                    <Route path="/*" element={<NotFoundPage />}/>   
                </Routes>
            </Container>
            <Footer/>
        </>
    )
}