import DataTable from "react-data-table-component"
import DataTableExtensions from 'react-data-table-component-extensions'
import "react-data-table-component-extensions/dist/index.css"
import { useTranslation } from "react-i18next"

export const CustomDataTable = (props) => {
    const { t } = useTranslation()
    const customStyles = {
        headCells: { style: { paddingLeft: '20px', paddingRight: '5px' } },
        cells: { style: { paddingLeft: '5px', paddingRight: '5px' } },
    }

    const {columns, data} = props
  
    const tableData = {
        columns: columns,
        data: data,
        exportHeaders: true,
        print: false,
        export: false,
        filterPlaceholder: t('datatables.buscar') 
    }

    return (
        <>
            <DataTableExtensions {...tableData}>
                <DataTable
                    noDataComponent={t('datatables.sinresultados')}
                    labelRowsPerPage={t('datatables.elementosporpagina')}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    customStyles={customStyles}
                    pagination
                    paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
                    paginationPerPage={25}
                    paginationComponentOptions={{ 
                        rowsPerPageText: t('datatables.elementosporpagina'),
                        rangeSeparatorText: t('datatables.de'),
                        noRowsPerPage: false,
                        selectAllRowsItem: true,
                        selectAllRowsItemText: t('datatables.todos')
                    }}
                />
            </DataTableExtensions>
        </>
  )
}