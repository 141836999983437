import React,{useEffect, useState} from 'react'
import {Container,Row,Col,Button,Card,Accordion} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetail } from './../../../actions/usersActions'
import { getInitialTest } from './../../../actions/initialTestActions'
import { getWeakTest } from '../../../../src/actions/weakTestActions'
import { getProspectTest } from '../../../../src/actions/prospectTestActions'
import { getMediumhardTest } from '../../../../src/actions/mediumhardTestActions'
import { Fragment } from 'react'
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js'
import { Radar } from 'react-chartjs-2'
import { Link } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer'
import ResumeReport from './resumeReport'

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend)

export default function Resume() { 
    const { t } = useTranslation()
    const {client} = useAuth()
    
    const {user} = useSelector(state=>state.users)
    
    const dispatch = useDispatch()

    const {test} = useSelector(state => state.initialTest)
    const weakTests = useSelector(state => state.weakTest)
    const prospectTest = useSelector(state => state.prospectTest)
    const mediumhardTest = useSelector(state => state.mediumhardTest)

    const virtuesWeaksOfStrong = {
        "aceptacion": [{name: "perseverancia", value: "aceptacionperseverancia"}],
        "admiracion": [{name: "dignidad", value: "admiraciondignidad"}],
        "alegria": [{name: "responsabilidad", value: "alegriaresponsabilidad"}, {name: "disciplina", value: "alegriadisciplina"}],
        "compania": [{name: "dignidad", value: "companiadignidad"}, {name: "identidad", value: "companiaidentidad"}],
        "compasion": [{name: "dignidad", value: "compasiondignidad"}, {name: "justicia", value: "compasionjusticia"}],
        "dignidad": [{name: "humildad", value: "dignidadhumildad"}, {name: "generosidad", value: "dignidadgenerosidad"}, {name: "admiracion", value: "dignidadadmiracion"}, {name: "lealtad", value: "dignidadlealtad"}, {name: "responsabilidad", value: "dignidadresponsabilidad"}],
        "disciplina": [{name: "libertad", value: "disciplinalibertad"}, {name: "inspiracion", value: "disciplinainspiracion"}, {name: "paciencia", value: "disciplinapaciencia"}, {name: "alegria", value: "disciplinaalegria"}],
        "educacion": [{name: "dignidad", value: "educaciondignidad"}, {name: "identidad", value: "educacionidentidad"}, {name: "libertad", value: "educacionlibertad"}],
        "generosidad": [{name: "dignidad", value: "generosidaddignidad"}, {name: "justicia", value: "generosidadjusticia"}],
        "guia": [{name: "humildad", value: "guiahumildad"}],
        "humildad": [{name: "dignidad", value: "humildaddignidad"}, {name: "identidad", value: "humildadidentidad"}, {name: "justicia", value: "humildadjusticia"}, {name: "sabiduria", value: "humildadsabiduria"}],
        "identidad": [{name: "humildad", value: "identidadhumildad"}, {name: "educacion", value: "identidadeducacion"}],
        "inspiracion": [{name: "disciplina", value: "inspiraciondisciplina"}],
        "justicia": [{name: "compasion", value: "justiciacompasion"}, {name: "humildad", value: "justiciahumildad"}],
        "lealtad": [{name: "dignidad", value: "lealtaddignidad"}, {name: "libertad", value: "lealtadlibertad"}],
        "libertad": [{name: "responsabilidad", value: "libertadresponsabilidad"}, {name: "disciplina", value: "libertaddisciplina"}, {name: "educacion", value: "libertadeducacion"}],
        "paciencia": [{name: "disciplina", value: "pacienciadisciplina"}, {name: "perseverancia", value: "pacienciaperseverancia"}],
        "perdon": [{name: "dignidad", value: "perdondignidad"}, {name: "justicia", value: "perdonjusticia"}],
        "perseverancia": [{name: "paciencia", value: "perseveranciapaciencia"}, {name: "aceptacion", value: "perseveranciaaceptacion"}],
        "proteccion": [{name: "valentia", value: "proteccionvalentia"}],
        "responsabilidad": [{name: "dignidad", value: "responsabilidaddignidad"}, {name: "libertad", value: "responsabilidadlibertad"}, {name: "justicia", value: "responsabilidadjusticia"}, {name: "alegria", value: "responsabilidadalegria"}],
        "sabiduria": [{name: "humildad", value: "sabiduriahumildad"}],
        "valentia": [{name: "proteccion", value: "valentiaproteccion"}, {name: "paciencia", value: "valentiapaciencia"}, {name: "aceptacion", value: "valentiaaceptacion"}]
    }

    const [virtuesWeak, setVirtuesWeak] = useState([])
    const [virtuesMedium, setVirtuesMedium] = useState([])
    const [virtuesStrong, setVirtuesStrong] = useState([])
    const [virtuesWeakPolarity, setVirtuesWeakPolarity] = useState([])
    const [virtuesStrongPolarity, setVirtuesStrongPolarity] = useState([])

    const [virtuesWeakRadar, setVirtuesWeakRadar] = useState({"aceptacion": 0, "admiracion": 0, "alegria": 0, "compania": 0, "compasion": 0, "dignidad": 0, "disciplina": 0, "educacion": 0, "generosidad": 0, "guia": 0, "humildad": 0, "identidad": 0, "inspiracion": 0, "justicia": 0, "lealtad": 0, "libertad": 0, "paciencia": 0, "perdon": 0, "perseverancia": 0, "proteccion": 0, "responsabilidad": 0, "sabiduria": 0, "valentia": 0 })
    const [virtuesMediumRadar, setVirtuesMediumRadar] = useState({"aceptacion": 0, "admiracion": 0, "alegria": 0, "compania": 0, "compasion": 0, "dignidad": 0, "disciplina": 0, "educacion": 0, "generosidad": 0, "guia": 0, "humildad": 0, "identidad": 0, "inspiracion": 0, "justicia": 0, "lealtad": 0, "libertad": 0, "paciencia": 0, "perdon": 0, "perseverancia": 0, "proteccion": 0, "responsabilidad": 0, "sabiduria": 0, "valentia": 0 })
    const [virtuesStrongRadar, setVirtuesStrongRadar] = useState({"aceptacion": 0, "admiracion": 0, "alegria": 0, "compania": 0, "compasion": 0, "dignidad": 0, "disciplina": 0, "educacion": 0, "generosidad": 0, "guia": 0, "humildad": 0, "identidad": 0, "inspiracion": 0, "justicia": 0, "lealtad": 0, "libertad": 0, "paciencia": 0, "perdon": 0, "perseverancia": 0, "proteccion": 0, "responsabilidad": 0, "sabiduria": 0, "valentia": 0 })

    const [loadingWeakMediumHard, setLoadingWeakMediumHard] = useState(true)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })    
        if(client?.id){
            dispatch(getUserDetail(client?.id))
            dispatch(getInitialTest(client?.id))
            dispatch(getWeakTest(client?.id))
            dispatch(getProspectTest(client?.id))
            setTimeout(function(){
                dispatch(getMediumhardTest(client?.id))
            }, 400);
            setTimeout(function(){
                setLoadingWeakMediumHard(false)
            }, 600);
        }
    }, [dispatch, client])

    useEffect(() => {
        if(weakTests.test || prospectTest.tests){
            let array_virtues = []
            let arrayVirtuesWeakRadar = virtuesWeakRadar

            if(weakTests.test){
                weakTests.test?.responses?.map((response)=>{
                    array_virtues.push(response.weak)
                    arrayVirtuesWeakRadar[response.weak] = 2
                    return true
                })
            }

            if(prospectTest.tests){
                prospectTest.tests?.map((testrealizado, id)=>(
                    testrealizado?.feelings?.map((feeling)=>{
                        array_virtues.push(feeling.virtue)
                        arrayVirtuesWeakRadar[feeling.virtue] = 2
                        return true
                    })
                    &&
                    testrealizado?.conducts?.map((conduct)=>{
                        array_virtues.push(conduct.virtue)
                        arrayVirtuesWeakRadar[conduct.virtue] = 2
                        return true
                    })
                ))
            }

            let array_count = []
            array_virtues.forEach(function (virtue) {
                if (array_count.find(ele => ele.virtue === virtue)) {
                    array_count.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count.push({virtue: virtue, count: 1})
                }
            })
            array_count.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtuesWeak(array_count)
            setVirtuesWeakRadar(arrayVirtuesWeakRadar)
        }
    }, [weakTests.test, prospectTest.tests, virtuesWeakRadar])

    useEffect(() => {
        if(mediumhardTest.test){
            let array_virtues_hard = []
            let array_virtues_medium = []

            let array_count_hard = []
            let array_count_medium = []
            
            let arrayVirtuesMediumRadar = virtuesMediumRadar
            let arrayVirtuesStrongRadar = virtuesStrongRadar

            mediumhardTest.test?.responses?.map((response)=>{
                if(response.type === 'hard'){
                    array_virtues_hard.push(response.virtue)
                    arrayVirtuesStrongRadar[response.virtue] = 2
                }
                return true
            })
            array_virtues_hard.forEach(function (virtue) {
                if (array_count_hard.find(ele => ele.virtue === virtue)) {
                    array_count_hard.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count_hard.push({virtue: virtue, count: 1})
                }
            })
            array_count_hard.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtuesStrong(array_count_hard)
            setVirtuesStrongRadar(arrayVirtuesStrongRadar)

            mediumhardTest.test?.responses?.map((response)=>{
                if(response.type === 'medium'){
                    array_virtues_medium.push(response.virtue)
                    arrayVirtuesMediumRadar[response.virtue] = 2
                }
                return true
            })

            array_virtues_medium.forEach(function (virtue) {
                if (array_count_medium.find(ele => ele.virtue === virtue)) {
                    array_count_medium.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count_medium.push({virtue: virtue, count: 1})
                }
            })
            array_count_medium.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtuesMedium(array_count_medium)
            setVirtuesMediumRadar(arrayVirtuesMediumRadar)
        }
    }, [mediumhardTest.test, virtuesMediumRadar, virtuesStrongRadar])

    // POLARIDADES
    useEffect(() => {
        if(weakTests.test || prospectTest.tests){
            let array_virtues = []

            if(weakTests.test){
                weakTests.test?.responses?.map((response)=>(
                    array_virtues.push(response.weak)
                ))
            }

            if(prospectTest.tests){
                prospectTest.tests?.map((testrealizado, id)=>(
                    testrealizado?.feelings?.map((feeling)=>(
                        array_virtues.push(feeling.virtue)
                    ))
                    &&
                    testrealizado?.conducts?.map((conduct)=>(
                        array_virtues.push(conduct.virtue)
                    ))
                ))
            }

            setVirtuesWeakPolarity(array_virtues)
        }
    }, [weakTests.test, prospectTest.tests])

    useEffect(() => {
        if(mediumhardTest.test){
            let array_virtues_hard = []
            let array_count_hard = []

            mediumhardTest.test?.responses?.map((response)=>(
                response.type === 'hard' && array_virtues_hard.push(response.virtue)
            ))
            array_virtues_hard.forEach(function (virtue) {
                if (array_count_hard.find(ele => ele.virtue === virtue)) {
                    array_count_hard.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count_hard.push({virtue: virtue, count: 1})
                }
            })
            array_count_hard.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtuesStrongPolarity(array_count_hard)        
        }
    }, [mediumhardTest.test])

    const data = {
        labels: [t('virtues.aceptacion'), t('virtues.admiracion'), t('virtues.alegria'), t('virtues.compania'), t('virtues.compasion'), t('virtues.dignidad'), t('virtues.disciplina'), t('virtues.educacion'), t('virtues.generosidad'), t('virtues.guia'), t('virtues.humildad'), t('virtues.identidad'), t('virtues.inspiracion'), t('virtues.justicia'), t('virtues.lealtad'), t('virtues.libertad'), t('virtues.paciencia'), t('virtues.perdon'), t('virtues.perseverancia'), t('virtues.proteccion'), t('virtues.responsabilidad'), t('virtues.sabiduria'), t('virtues.valentia')],
        datasets: [
            {
                label: t('virtuestype.weak'),
                pointBorderColor: "rgba(0, 0, 0, 0)",
                pointBackgroundColor: "rgba(0, 0, 0, 0)",
                data: [virtuesWeakRadar.aceptacion, virtuesWeakRadar.admiracion, virtuesWeakRadar.alegria, virtuesWeakRadar.compania, virtuesWeakRadar.compasion, virtuesWeakRadar.dignidad, virtuesWeakRadar.disciplina, virtuesWeakRadar.educacion, virtuesWeakRadar.generosidad, virtuesWeakRadar.guia, virtuesWeakRadar.humildad, virtuesWeakRadar.identidad, virtuesWeakRadar.inspiracion, virtuesWeakRadar.justicia, virtuesWeakRadar.lealtad, virtuesWeakRadar.libertad, virtuesWeakRadar.paciencia, virtuesWeakRadar.perdon, virtuesWeakRadar.perseverancia, virtuesWeakRadar.proteccion, virtuesWeakRadar.responsabilidad, virtuesWeakRadar.sabiduria, virtuesWeakRadar.valentia].map(n => n === 0 ? null : n),
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgb(255, 185, 80)',
                borderWidth: 3,
            },
            {
                label: t('virtuestype.medium'),
                pointBorderColor: "rgba(0, 0, 0, 0)",
                pointBackgroundColor: "rgba(0, 0, 0, 0)",
                data: [virtuesMediumRadar.aceptacion, virtuesMediumRadar.admiracion, virtuesMediumRadar.alegria, virtuesMediumRadar.compania, virtuesMediumRadar.compasion, virtuesMediumRadar.dignidad, virtuesMediumRadar.disciplina, virtuesMediumRadar.educacion, virtuesMediumRadar.generosidad, virtuesMediumRadar.guia, virtuesMediumRadar.humildad, virtuesMediumRadar.identidad, virtuesMediumRadar.inspiracion, virtuesMediumRadar.justicia, virtuesMediumRadar.lealtad, virtuesMediumRadar.libertad, virtuesMediumRadar.paciencia, virtuesMediumRadar.perdon, virtuesMediumRadar.perseverancia, virtuesMediumRadar.proteccion, virtuesMediumRadar.responsabilidad, virtuesMediumRadar.sabiduria, virtuesMediumRadar.valentia].map(n => n === 0 ? null : n),
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgb(255, 228, 92)',
                borderWidth: 3,
            },
            {
                label: t('virtuestype.hard'),
                pointBorderColor: "rgba(0, 0, 0, 0)",
                pointBackgroundColor: "rgba(0, 0, 0, 0)",
                data: [virtuesStrongRadar.aceptacion, virtuesStrongRadar.admiracion, virtuesStrongRadar.alegria, virtuesStrongRadar.compania, virtuesStrongRadar.compasion, virtuesStrongRadar.dignidad, virtuesStrongRadar.disciplina, virtuesStrongRadar.educacion, virtuesStrongRadar.generosidad, virtuesStrongRadar.guia, virtuesStrongRadar.humildad, virtuesStrongRadar.identidad, virtuesStrongRadar.inspiracion, virtuesStrongRadar.justicia, virtuesStrongRadar.lealtad, virtuesStrongRadar.libertad, virtuesStrongRadar.paciencia, virtuesStrongRadar.perdon, virtuesStrongRadar.perseverancia, virtuesStrongRadar.proteccion, virtuesStrongRadar.responsabilidad, virtuesStrongRadar.sabiduria, virtuesStrongRadar.valentia].map(n => n === 0 ? null : n),
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgb(114, 233, 233)',
                borderWidth: 3,
            }
        ],
    }

    const options = {
        spanGaps: true,
        showLine: true,
        scales: {
            r: {
                max: 3,
                min: 0,
                ticks: {
                    stepSize: 1,
                    color: 'rgba(0, 0, 0, 1)',
                    display: false
                },
                angleLines: {
                    color: 'rgba(0, 0, 0, 0.1)',
                    lineWidth: 0.5,
                },
                grid: {
                    color: "rgba(0, 0, 0, 0.1)",
                    lineWidth: 0.5,
                    circular: true
                },
            },
        },
        plugins: {
            tooltip: {
                enabled: false
            }
        }
    }

    return (
        <>            
            <Container fluid className='contenido px-0 px-md-5 bg-modulo1'>
                <Row>
                    <Col className='d-flex justify-content-end'>
                        <Link to="/client/profile" className='position-fixed icono-home'>
                            <i className="fas fa-home fa-2x"></i>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col className='p-5'>
                        <div className='resumen-frase'>
                            <h2>{t('resume.hello')} {user?.firstname} {user?.lastname}
                                
                            </h2>
                            <div>
                            <PDFDownloadLink
                                    document={
                                        <ResumeReport
                                            name={user?.firstname + ' ' + user?.lastname}
                                            virtuesStrong={virtuesStrong}
                                            virtuesMedium={virtuesMedium}
                                            virtuesWeak={virtuesWeak}
                                            virtuesStrongPolarity={virtuesStrongPolarity}
                                            virtuesWeaksOfStrong={virtuesWeaksOfStrong}
                                            virtuesWeakPolarity={virtuesWeakPolarity}
                                        />
                                    } 
                                    fileName={'resume_conscience_profile.pdf'}
                                >
                                    <Button className='btn btn-primary'><i className="fa fa-download"></i> {t('resume.download')}</Button>
                                </PDFDownloadLink>
                            </div>
                            <p className='mt-4 mb-0 '>
                                {t('resume.phrase1a')} <strong>{t('resume.phrase1b')}</strong>
                            </p>
                            <p className=''>
                                {t('resume.phrase2')}
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex justify-content-center my-3'>
                        <div className="alert auto alert-default text-center border" role="alert">
                            <h3 className='m-0 text-white'>{t('resume.virtues-perfil')}</h3>
                        </div>
                    </Col>
                </Row>
                <Row className='justify-content-center justify-content-md-between mx-3'>
                    <Col className='columna-virtudes my-3 mx-2'>
                        <Card className='h-100 py-3 px-md-3 px-0'>
                            <Card.Body className='px-0 px-md-2'>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <div className="alert auto alert-success text-center" role="alert">                                            
                                            <h4 className='mb-0 text-white'>{t('resume.virtues-hard')}</h4>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        {loadingWeakMediumHard
                                            ?
                                                <><i className='fas fa-circle-notch fa-spin fa-2x verde'></i></>
                                            :
                                                <Accordion>
                                                {virtuesStrong?.map((virtue, id)=>{
                                                    return (
                                                        virtue.virtue !== '' 
                                                        ?
                                                            <Accordion.Item eventKey={id} key={id} className="virtudes-sincolor">
                                                                <Accordion.Header className="d-flex justify-content-center">{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                                                <Accordion.Body>
                                                                    {t('descriptions.'+virtue.virtue )}
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        :
                                                            <></>
                                                    )
                                                })}
                                                </Accordion>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='columna-virtudes my-3 mx-2'>
                        <Card className='h-100 p-3'>
                            <Card.Body className='px-2'>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <div className="alert auto alert-success text-center" role="alert">                                            
                                            <h4 className='mb-0 text-white'>{t('resume.virtues-to-train')}</h4>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        {loadingWeakMediumHard
                                            ?
                                                <><i className='fas fa-circle-notch fa-spin fa-2x verde'></i></>
                                            :
                                                <Accordion> 
                                                {virtuesWeak?.map((virtue, id)=>{
                                                    return (
                                                        virtue.virtue !== '' 
                                                        ?
                                                            <Accordion.Item eventKey={id} key={id} className='virtudes-sincolor'>
                                                                <Accordion.Header className="d-flex justify-content-center">{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                                                <Accordion.Body>
                                                                    {t('descriptions.'+virtue.virtue )}
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        :
                                                            <></>
                                                    )
                                                })}
                                                </Accordion>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='columna-virtudes my-3 mx-2'>
                        <Card className='h-100 p-3'>
                            <Card.Body className='px-2'>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <div className="alert auto alert-success text-center" role="alert">
                                            <h4 className='mb-0 text-white'>{t('resume.virtues-medium')}</h4>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        {loadingWeakMediumHard
                                            ?
                                                <><i className='fas fa-circle-notch fa-spin fa-2x'></i></>
                                            :
                                                <Accordion> 
                                                {virtuesMedium?.map((virtue, id)=>{
                                                    return (
                                                        virtue.virtue !== '' 
                                                        ?
                                                            <Accordion.Item eventKey={id} key={id} className="virtudes-sincolor">
                                                                <Accordion.Header className="d-flex justify-content-center">{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                                                <Accordion.Body>
                                                                    {t('descriptions.'+virtue.virtue )}
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        :
                                                            <></>
                                                    )
                                                })}
                                                </Accordion>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col className='d-flex justify-content-center justify-content-md-end'>
                        <Accordion className='ancho-select'> 
                            <Accordion.Item eventKey={1} key={1}>
                                <Accordion.Header className="text-end plus-green">{t('conscience.linktests2')}</Accordion.Header>
                                <Accordion.Body>
                                    {test && test[0]?.result
                                    ?
                                        <Link to="/client/result">
                                        <p>{t('conscience.initial')}</p>
                                        </Link>
                                    :
                                        <Link to="/client/test/initial">
                                        <p>{t('conscience.initial')}</p>
                                        </Link>
                                    }
                                    <Link to="/client/test/conscience/weak">
                                    <p>{t('conscience.virtues-to-train')}</p>
                                    </Link>
                                    <Link to="/client/test/conscience/prospect">
                                    <p>{t('conscience.limiting-expectations')}</p>
                                    </Link>
                                    <Link to="/client/test/conscience/mediumhard">
                                    <p>{t('conscience.medium-strong-virtues')}</p>
                                    </Link>
                                    <Link to="/client/test/conscience/harmful">
                                    <p>{t('conscience.harmful-habits')}</p>
                                    </Link>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex justify-content-center mt-5 mb-3'>
                        <div className="alert auto alert-default text-center border" role="alert">
                            <h3 className='m-0 text-white'>{t('resume.mypolarity')}</h3>
                        </div>
                    </Col>
                </Row>
                <Row className='pt-3 px-4 justify-content-center mx-2'>
                    <Col>
                        <Card className='px-2 py-5 px-md-5'>
                            <Card.Body className='px-2 px-md-5'>
                                <div className='sticky-md-top'>
                                    <Row>
                                        <Col>
                                            <p><strong>{t('polarity.paragraph')}</strong></p>
                                        </Col>
                                    </Row>
                                    <Row className="py-4 bg-white">
                                        <Col xs='12' md='4' className="px-4 d-none d-md-block">
                                            <div className="alert alert-success" role="alert">
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <h4 className='mx-3 m-0 text-white'>{t('polarity.apply-a-lot')}</h4>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs='12' md='4' className="px-4 d-none d-md-block">
                                            <div className="alert alert-success" role="alert">
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <h4 className='mx-3 m-0 text-white'>{t('polarity.not-apply-a-lot')}</h4>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs='12' md='4' className="px-4 d-none d-md-block">
                                            <div className="alert alert-success" role="alert">
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <h4 className='mx-3 m-0 text-white'>{t('polarity.result')}</h4>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                {virtuesStrongPolarity?.map((virtue, id, virtuesStrong)=>{
                                    let virtueCount = 0

                                    return (
                                        <Fragment key={id}>
                                        {virtuesWeaksOfStrong[virtue.virtue]?.map((weakVirtue, key)=>(
                                            virtuesWeakPolarity.includes(weakVirtue.name)
                                                ?
                                                    <Row key={key}>
                                                        <Col xs='12' md='4' className="px-4 py-2">
                                                            {virtueCount++ < 1
                                                                ?
                                                                    <>
                                                                        <div>
                                                                            <Col xs='12' md='4' className="px-4 d-block d-md-none">
                                                                                <div className="alert alert-success" role="alert">                                                                                    
                                                                                    <div className='d-flex align-items-center justify-content-center'>
                                                                                        <h4 className='mx-3 m-0 text-white'>{t('polarity.apply-a-lot')}</h4>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </div>
                                                                        <Col className='d-flex justify-content-center'>
                                                                            <Accordion> 
                                                                                <Accordion.Item eventKey={id} key={id} className="virtudes-sincolor">
                                                                                    <Accordion.Header>{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                                                                    <Accordion.Body>
                                                                                        {t('descriptions.'+virtue.virtue )}
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                            </Accordion> 
                                                                        </Col>
                                                                    </>
                                                                :
                                                                    <></>
                                                            }
                                                        </Col>
                                                        <Fragment key={key}>
                                                            <Col xs='12' md='4' className="px-4 py-2">
                                                                <div>
                                                                    <Col xs='12' md='4' className="px-4 d-block d-md-none">
                                                                        <div className="alert alert-success" role="alert">                                                                            
                                                                            <div className='d-flex align-items-center justify-content-center'>
                                                                                <h4 className='mx-3 m-0 text-white'>{t('polarity.not-apply-a-lot')}</h4>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </div>
                                                                <Col className='d-flex justify-content-center'>
                                                                    <Accordion>
                                                                        <Accordion.Item eventKey={key} key={key} className="virtudes-sincolor">
                                                                            <Accordion.Header>{t('virtues.'+weakVirtue.name)}</Accordion.Header>
                                                                            <Accordion.Body>
                                                                                {t('descriptions.'+weakVirtue.name)}
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    </Accordion> 
                                                                </Col>
                                                            </Col>
                                                            <Col xs='12' md='4' className="px-4 py-2">
                                                                <div>
                                                                    <Col className="px-4 d-block d-md-none">
                                                                        <div className="alert alert-success" role="alert">
                                                                            <div className='d-flex align-items-center justify-content-center'>
                                                                                <h4 className='ms-3 m-0 text-white'>{t('polarity.result')}</h4>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </div>
                                                                <div className='text-center'>
                                                                        {t('virtues.tips.'+weakVirtue.value)}
                                                                </div>
                                                            </Col>
                                                        </Fragment>
                                                    </Row>
                                                :
                                                    <Fragment key={key}></Fragment>
                                        ))}
                                        {virtueCount > 0 && id + 1 !== virtuesStrong.length ? <hr/> : <></>}
                                        </Fragment>
                                    )
                                })}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex justify-content-center mt-5 mb-3'>
                        <div className="alert auto alert-default text-center border" role="alert">
                            <h3 className='m-0 text-white'>{t('resume.virtues-map')}</h3>
                        </div>
                    </Col>
                </Row>
                <Row className='d-flex px-4 justify-content-center mx-2'>
                    <Col className='p5'>
                        <Card className='p-5'>
                            <Row>
                                <Col xs={12} sm={8} className="mx-auto">
                                    <Radar options={options} data={data} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}


                
