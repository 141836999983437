// ACTUALMENTE NO SE USA YA QUE ESTÁ IMPLEMENTADO EN EL RESUMEN DEL PERFIL ENTRE VIRTUDES Y MAPA RADAR
// FICHERO Resume.js
import React,{useEffect, useState} from 'react'
import {Container,Row,Col,Card, Accordion} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetail } from '../../../actions/usersActions'
import { getMediumhardTest } from '../../../actions/mediumhardTestActions'
import { Fragment } from 'react'
import { getWeakTest } from '../../../actions/weakTestActions'
import { getProspectTest } from '../../../actions/prospectTestActions'
import { Link } from 'react-router-dom'

export default function Polarity() { 
    const { t } = useTranslation()  
    const {client} = useAuth()
    const dispatch = useDispatch()

    const virtuesWeaksOfStrong = {
        "aceptacion": [{name: "perseverancia", value: "aceptacionperseverancia"}],
        "admiracion": [{name: "dignidad", value: "admiraciondignidad"}],
        "alegria": [{name: "responsabilidad", value: "alegriaresponsabilidad"}, {name: "disciplina", value: "alegriadisciplina"}],
        "compania": [{name: "dignidad", value: "companiadignidad"}, {name: "identidad", value: "companiaidentidad"}],
        "compasion": [{name: "dignidad", value: "compasiondignidad"}, {name: "justicia", value: "compasionjusticia"}],
        "dignidad": [{name: "humildad", value: "dignidadhumildad"}, {name: "generosidad", value: "dignidadgenerosidad"}, {name: "admiracion", value: "dignidadadmiracion"}, {name: "lealtad", value: "dignidadlealtad"}, {name: "responsabilidad", value: "dignidadresponsabilidad"}],
        "disciplina": [{name: "libertad", value: "disciplinalibertad"}, {name: "inspiracion", value: "disciplinainspiracion"}, {name: "paciencia", value: "disciplinapaciencia"}, {name: "alegria", value: "disciplinaalegria"}],
        "educacion": [{name: "dignidad", value: "educaciondignidad"}, {name: "identidad", value: "educacionidentidad"}, {name: "libertad", value: "educacionlibertad"}],
        "generosidad": [{name: "dignidad", value: "generosidaddignidad"}, {name: "justicia", value: "generosidadjusticia"}],
        "guia": [{name: "humildad", value: "guiahumildad"}],
        "humildad": [{name: "dignidad", value: "humildaddignidad"}, {name: "identidad", value: "humildadidentidad"}, {name: "justicia", value: "humildadjusticia"}, {name: "sabiduria", value: "humildadsabiduria"}],
        "identidad": [{name: "humildad", value: "identidadhumildad"}, {name: "educacion", value: "identidadeducacion"}],
        "inspiracion": [{name: "disciplina", value: "inspiraciondisciplina"}],
        "justicia": [{name: "compasion", value: "justiciacompasion"}, {name: "humildad", value: "justiciahumildad"}],
        "lealtad": [{name: "dignidad", value: "lealtaddignidad"}, {name: "libertad", value: "lealtadlibertad"}],
        "libertad": [{name: "responsabilidad", value: "libertadresponsabilidad"}, {name: "disciplina", value: "libertaddisciplina"}, {name: "educacion", value: "libertadeducacion"}],
        "paciencia": [{name: "disciplina", value: "pacienciadisciplina"}, {name: "perseverancia", value: "pacienciaperseverancia"}],
        "perdon": [{name: "dignidad", value: "perdondignidad"}, {name: "justicia", value: "perdonjusticia"}],
        "perseverancia": [{name: "paciencia", value: "perseveranciapaciencia"}, {name: "aceptacion", value: "perseveranciaaceptacion"}],
        "proteccion": [{name: "valentia", value: "proteccionvalentia"}],
        "responsabilidad": [{name: "dignidad", value: "responsabilidaddignidad"}, {name: "libertad", value: "responsabilidadlibertad"}, {name: "justicia", value: "responsabilidadjusticia"}, {name: "alegria", value: "responsabilidadalegria"}],
        "sabiduria": [{name: "humildad", value: "sabiduriahumildad"}],
        "valentia": [{name: "proteccion", value: "valentiaproteccion"}, {name: "paciencia", value: "valentiapaciencia"}, {name: "aceptacion", value: "valentiaaceptacion"}]
    }

    const mediumhardTest = useSelector(state => state.mediumhardTest)
    const [virtuesStrong, setVirtuesStrong] = useState([])
    
    const weakTests = useSelector(state => state.weakTest)
    const prospectTest = useSelector(state => state.prospectTest)
    const [virtuesWeak, setVirtuesWeak] = useState([])

    useEffect(() => {
        if(client?.id){
            dispatch(getUserDetail(client?.id))
            dispatch(getWeakTest(client?.id))
            dispatch(getProspectTest(client?.id))
            dispatch(getMediumhardTest(client?.id))
        }
    }, [dispatch, client])

    useEffect(() => {
        if(weakTests.test || prospectTest.tests){
            let array_virtues = []

            if(weakTests.test){
                weakTests.test?.responses?.map((response)=>(
                    array_virtues.push(response.weak)
                ))
            }

            if(prospectTest.tests){
                prospectTest.tests?.map((testrealizado, id)=>(
                    testrealizado?.feelings?.map((feeling)=>(
                        array_virtues.push(feeling.virtue)
                    ))
                    &&
                    testrealizado?.conducts?.map((conduct)=>(
                        array_virtues.push(conduct.virtue)
                    ))
                ))
            }

            setVirtuesWeak(array_virtues)
        }
    }, [weakTests.test, prospectTest.tests])

    useEffect(() => {
        if(mediumhardTest.test){
            let array_virtues_hard = []
            let array_count_hard = []

            mediumhardTest.test?.responses?.map((response)=>(
                response.type === 'hard' && array_virtues_hard.push(response.virtue)
            ))
            array_virtues_hard.forEach(function (virtue) {
                if (array_count_hard.find(ele => ele.virtue === virtue)) {
                    array_count_hard.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count_hard.push({virtue: virtue, count: 1})
                }
            })
            array_count_hard.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtuesStrong(array_count_hard)
        }
    }, [mediumhardTest.test])

    return (
        <>
            <Container fluid className='contenido' id="datos">
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <div className="alert alert-info px-5" role="alert">
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <h3 className='m-0 verde'>{t('polarity.mypolarity')}</h3>                                    
                                </div>
                            </div>
                        </div>
                        <Link to="/client/profile" className='position-fixed icono-home'>
                            <i className="fas fa-home fa-2x"></i>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col>                        
                        <Card className='p-5'>
                            <Card.Body className='px-5'>
                                <div className='sticky-top'>
                                    <Row>
                                        <Col>
                                            <p><strong>{t('polarity.paragraph')}</strong></p>
                                        </Col>
                                    </Row>
                                    <Row className="py-4 bg-white">
                                        <Col xs='4' className="px-4">
                                            <div className="alert alert-success ps-5" role="alert">
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='d-flex align-items-center justify-content-start'>
                                                        <h3 className='ms-3 m-0 text-white'>{t('polarity.apply-a-lot')}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs='4' className="px-4">
                                            <div className="alert alert-success ps-5" role="alert">
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='d-flex align-items-center justify-content-start'>
                                                        <h3 className='ms-3 m-0 text-white'>{t('polarity.not-apply-a-lot')}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs='4' className="px-4">
                                            <div className="alert alert-success ps-5" role="alert">
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='d-flex align-items-center justify-content-start'>
                                                        <h3 className='ms-3 m-0 text-white'>{t('polarity.result')}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                {virtuesStrong?.map((virtue, id, virtuesStrong)=>{
                                    let virtueCount = 0

                                    return (
                                        <Fragment key={id}>
                                        {virtuesWeaksOfStrong[virtue.virtue]?.map((weakVirtue, key)=>(
                                            virtuesWeak.includes(weakVirtue.name)
                                                ?
                                                    <Row key={key}>
                                                        <Col xs='4' className="px-4 py-2">
                                                            {virtueCount++ < 1
                                                                ?
                                                                    <>
                                                                        <Accordion> 
                                                                            <Accordion.Item eventKey={id} key={id} className="virtudes-sincolor">
                                                                                <Accordion.Header>{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    {t('descriptions.'+virtue.virtue )}
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        </Accordion> 
                                                                    </>
                                                                :
                                                                    <></>
                                                            }
                                                        </Col>
                                                        <Fragment key={key}>
                                                            <Col xs='4' className="px-4 py-2">
                                                                <Accordion>
                                                                    <Accordion.Item eventKey={key} key={key} className="virtudes-sincolor">
                                                                        <Accordion.Header>{t('virtues.'+weakVirtue.name)}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            {t('descriptions.'+weakVirtue.name)}
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion> 
                                                            </Col>
                                                            <Col xs='4' className="px-4 py-2">
                                                                <div>
                                                                        {t('virtues.tips.'+weakVirtue.value)}
                                                                </div>
                                                            </Col>
                                                        </Fragment>
                                                    </Row>
                                                :
                                                    <Fragment key={key}></Fragment>
                                        ))}
                                        {virtueCount > 0 && id + 1 !== virtuesStrong.length ? <hr/> : <></>}
                                        </Fragment>
                                    )
                                })}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}


                
