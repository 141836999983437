import Axios  from "axios"
import FormData from "form-data";


export const getUsers = (users) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/admin/user/getall/', {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )      
        if(status===200){            
            dispatch({
                type: 'USERS_GET_SUCCESS',
                payload: data.users
            }) 
        }

    } catch (error) {
        dispatch({
            type: 'USER_ERROR'           
        })         
    }
}
export const getUserDetail = (user) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/admin/user/get/'+ user, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )            
        if(status===200){            
            dispatch({
                type: 'USER_GET_DETAIL_SUCCESS',
                payload: data.user
            })             
        }

    } catch (error) {
        dispatch({
            type: 'USER_ERROR'           
        })         
    }
}

export const editUser = (username, email, password, firstname, lastname, address, access, phone, image, active_test, idUser) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        let imagen
        if(image !== null && image !== undefined && typeof image !== 'string'){
            let formData = new FormData()
            formData.append('image', image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: sessionStorage.getItem('jwt')
                }
            })
            imagen = data
        }else{
            imagen = image
        }
        
        await Axios.put(
            process.env.REACT_APP_DB_ADDRESS + '/api/admin/user/edit/' + idUser, {
                username: username,
                email: email,
                password: password,
                firstname: firstname,
                lastname: lastname,
                address: address,
                access: access,
                phone: phone,
                image: imagen,
                active_test: active_test
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        dispatch({
            type: 'USER_EDIT_SUCCESS',
            payload: {
                username: username,
                email: email,
                password: password,
                firstname: firstname,
                lastname: lastname,
                address: address,
                access: access,
                phone: phone,
                image: image,
                active_test: active_test,
                _id: idUser
            }
        })
    } catch (error) {
        dispatch({
            type: 'USER_ERROR'
        })
    }
}

export const activeBlock = (idUser) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        await Axios.put(
            process.env.REACT_APP_DB_ADDRESS + '/api/admin/user/activeblock/' + idUser, {}, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
    } catch (error) {
        dispatch({
            type: 'USER_ERROR'
        })
    }
}

export const editDataUser = (username, email, password, firstname, lastname, address, access, phone, image, active_test, idUser) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        await Axios.put(
            process.env.REACT_APP_DB_ADDRESS + '/api/admin/user/edit/' + idUser, {
                username: username,
                email: email,
                password: password,
                firstname: firstname,
                lastname: lastname,
                address: address,
                access: access,
                phone: phone,
                image: image,
                active_test: active_test
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        dispatch({
            type: 'USER_EDIT_SUCCESS',
            payload: {
                username: username,
                email: email,
                password: password,
                firstname: firstname,
                lastname: lastname,
                address: address,
                access: access,
                phone: phone,
                image: image,
                active_test: active_test,
                _id: idUser
            }
        })
    } catch (error) {
        dispatch({
            type: 'USER_ERROR'
        })
    }
}

export const addUser = (username, email, password, firstname, lastname, address, access, phone) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const {
            data,
            status
        } = await Axios.post(
            process.env.REACT_APP_DB_ADDRESS + '/api/admin/user/register', {
                username: username,
                email: email,
                password: password,
                firstname: firstname,
                lastname: lastname,
                address: address,
                access: access,
                phone: phone
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200) {
            let newUser = {
                _id: data.id,
                username: username,
                email: email,
                password: password,
                firstname: firstname,
                lastname: lastname,
                address: address,
                access: access,
                phone: phone
            }
            dispatch({
                type: 'USER_ADD_SUCCESS',
                payload: newUser
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_FAIL'
        })
    }
}

export const removeUser = (idUser) => async (dispatch) => {
    try {
        const {
            data,
            status
        } = await Axios.delete(
            process.env.REACT_APP_DB_ADDRESS + '/api/admin/user/remove/' + idUser, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200) {
            dispatch({
                type: 'USER_DELETE_SUCCESS',
                payload: {
                    idUser: idUser,
                    message: data.message,
                    count: data.count
                }
            })
        }else{
            dispatch({
                type: 'USER_DELETE_NOT_SUCCESS',
                payload: {
                    message: data.message,
                    count: data.count
                }
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_ERROR'
        })
    }
}