import Axios  from "axios"

export const getAccesses = () => async (dispatch) => {
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/admin/access/getall/', {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){            
            dispatch({
                type: 'ACCESS_GET_SUCCESS',
                payload: data.accesses
            }) 
        }

    } catch (error) {      
    }
}