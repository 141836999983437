export const uservirtueReducer = (state = {loading:true,result:null,uservirtue:null} , action) => {
    switch (action.type) {
        case 'USERVIRTUE_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                uservirtue: action.payload
            }
        case 'USERVIRTUE_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                uservirtue: action.payload.newuservirtue
            }
        case 'USERVIRTUE_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'USERVIRTUE_ERROR':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}

