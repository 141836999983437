import React, { useEffect, useState } from 'react'
import { useNavigate, Route, Routes } from 'react-router-dom'
import NotFoundPage from '../pages/404/NotFoundPage'
import {Container} from 'react-bootstrap'
import MenuAdmin from '../components/menu-admin'
import Dashboard from '../pages/admin/Dashboard'
import Users from '../pages/admin/Users'
import Guides from '../pages/admin/Guides'
import UserEdit from '../pages/admin/UserEdit'
import UserInsert from '../pages/admin/UserInsert'
import { Footer } from '../components/footer'
import useAuth from '../context/auth/useAuth'
import BlockOne from '../pages/admin/BlockOne'
import BlockTwo from '../pages/admin/BlockTwo'
import BlockThree from '../pages/admin/BlockThree'
import Explain from '../pages/admin/Explain'

export default function AdminRouter () {
  const {client} = useAuth()
  const navigate = useNavigate()
	const [color, setColor] = useState('transparent')
  const [fixed, setFixed] = useState('no-fixed')
	window.addEventListener('scroll', function(e) {
	  if(this.scrollY>50&&color==='transparent'){      
      setColor('#fff')
      setFixed('fixed');
	  }else{
      if(this.scrollY<=50&&color==='#fff'){
        setFixed('no-fixed')
        setColor('transparent')        
      }
	  }
	});	   

  useEffect(() => {
    if(client?.tests[0]==="Initial"){
			navigate("/client/profile")
    }
  }, [client, navigate])


  return (
    <>
      <Container className='cabecera'>
        <MenuAdmin background={color} fixed={fixed}/>        
        <Routes>
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/users" element={<Users/>}/>
            <Route path="/guides" element={<Guides/>}/>
            <Route path="/explain/:virtue" element={<Explain/>}/>
            <Route exact path="/user/:id" element={<UserEdit/>}/>
            <Route path="/bloqueuno/:id" element={<BlockOne />}/>
            <Route path="/bloquedos/:id" element={<BlockTwo />}/>
            <Route path="/bloquetres/:id" element={<BlockThree />}/>
            <Route exact path="/user" element={<UserInsert/>}/>
            <Route path="/*" element={<NotFoundPage />}/>
        </Routes>
      </Container>
      <Footer/>
    </>
  )
}