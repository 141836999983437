import {useState} from 'react'
import Axios from 'axios'
import { useTranslation } from "react-i18next"
import Swal from 'sweetalert2'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

/**
 * 
 * @returns 
 */
export const Forgot = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false);
    
    /**
     * Comprueba los datos del formulario
     * @param {*} e evento
     */
    const handleSubmit = async (e) => {
        e.preventDefault();      

        // Obtenemos el mail del usuario
        const clientEmail =  {
            email: e.target.email.value
        };

        setIsLoading(true);
        
        // Enviamos la petición al /forgotpassword con el email
        await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/forgot/sendmail', clientEmail)
            .then((res) => {
                setIsLoading(false);                
                // Mostramos el popup informativo
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'success',
                    text: 'Revise su email, se le ha enviado un enlace para crear una nueva contraseña',
                    target: document.getElementById('root')
                })
                
            }).catch((err) => {
                setIsLoading(false);
                // Mostramos el popup informativo
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'error',
                    text: 'Ha habido un error al intentar enviar los datos, comprueba el correo introducido o vuelva a intentarlo más tarde'
                })
            });
    } 
    return (        
        <Container fluid className='login d-flex align-items-center'>
            <div className='bloque-login'>
                <Row>
                    <Col className='mx-auto text-center mb-5'>
                        <a href="/">
                            <img
                                src="/images/logotipo.png"
                                width="150"
                                className="d-inline-block align-top justify-center"
                                alt="Logotipo MIRACO"
                            />
                        </a>
                    </Col>
                </Row>
                <Row className='px-3 px-md-5 cuadro'>
                    <Col className='mx-auto'>
                        <Form className="form p-4" onSubmit={handleSubmit} id="kt_login_signin_form">
                            <div className="text-center mb-5">
                                <h1 className="mb-3">{t('forgot.question')}</h1>
                                <div className="text-gray-400 fw-bold fs-4">{t('forgot.help')}</div>
                            </div>
                            <div className="fv-row mb-3">
                                <Form.Label className="form-label">{t('forgot.email_label')}</Form.Label>
                                <Form.Control placeholder={t('forgot.email_label')} name="email" className="form-control-lg form-control-solid" type="email" autoComplete="off" />
                            </div>

                            <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                            <Button variant="primary" type="submit" id="kt_password_reset_submit" className="btn btn-lg fw-bolder me-4">
                                {isLoading
                                    ?
                                    <span className="indicator-label">
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                    :
                                    <span className="indicator-label">{t('forgot.submit')}</span>
                                }
                            </Button>
                                <Link to="/auth/login">
                                    <Button variant="light-primary" type="button" id="kt_login_password_reset_form_cancel_button" className="btn btn-lg fw-bolder">
                                        {t('forgot.cancel')}
                                    </Button>
                                </Link>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Container>
    )    
}
