import React, { useState, useEffect, Fragment } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Container, Row, Col, Form, Button, Card, Accordion, Table } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import Select from "react-select"
import useAuth from '../../../../context/auth/useAuth'
import { getHarmfulTest, sendHarmfulTest, removeHarmfulTest } from '../../../../actions/harmfulTestActions'
import { useNavigate, Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import emotionsJSON from "../conscience/virtues/emotions.json"


export default function Harmful() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {client} = useAuth()

    const {result, tests} = useSelector(state=>state.harmfulTest)

    const [habit, setHabit] = useState('')
    const [emotionsOptions, setEmotionsOptions] = useState([])
    const [action, setAction] = useState('')
    const [frequenciesOptions, setFrequenciesOptions] = useState([])
    const [quantity, setQuantity] = useState('')
    const [impact, setImpact] = useState('')
    const [virtues, setVirtues] = useState([])

    const [feelings, setFeelings] = useState([])

    const frequencies = ["cadahora", "diario", "semanal", "mensual", "cadaanho", "otro"]

    const emotions = []
    for (let indice in emotionsJSON) {
        emotions.push(indice)
    }

    const selectChangeEmotions = (emotions) => {
        let arrayFeelings = []
        emotions.forEach(emotion => {
            arrayFeelings.push({emotion: emotion.value, virtue: emotionsJSON[emotion.value]})
        })
        setFeelings(arrayFeelings)
        setEmotionsOptions({...emotionsOptions, selected: emotions})
    }

    const selectChangeFrequencies = (frequency) => {
        frequencies?.forEach(frequenc1 => {
            if(frequenc1 === frequency.value){
                let object = {value: frequenc1, label: t('frequencies.'+frequenc1)}
                setFrequenciesOptions({...frequenciesOptions, selected: object})
            }
        })
    }
    
    const inputHabit = (element) => { setHabit(element.target.value) }
    const inputAction = (element) => { setAction(element.target.value) }
    const inputQuantity = (element) => { setQuantity(element.target.value) }
    const inputImpact = (element) => { setImpact(element.target.value) }

    const handleSubmit = async(e) => {
        e.preventDefault()

        if(feelings.length > 0){
            dispatch(sendHarmfulTest(client.id, habit, feelings, action, frequenciesOptions.selected.value, quantity, impact))
            dispatch(getHarmfulTest(client?.id))
            setHabit('')
            setEmotionsOptions({...emotionsOptions, selected: []})
            setAction('')
            setFrequenciesOptions({...frequenciesOptions, selected: []})
            setQuantity('')
            setImpact('')
            setVirtues([])
            Swal.fire({
                showConfirmButton: true,
                icon: 'success',
                text: t('harmful.added')
            })
        }else{
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text: t('harmful.addfail')
            })
        }
    }

    useEffect(() => {
        if(result){
            // navigate('/client/resume')
        }
    }, [result, navigate])
    
    useEffect(() => {
        if(client?.id){
            let arrayEmotions = []
            emotions?.forEach(emotion => {
                let object = {value: emotion, label: t('emotions.'+emotion)}
                arrayEmotions.push(object)
            })
            setEmotionsOptions({options: arrayEmotions, selected: []})

            let arrayFrequencies = []
            let arrayFrequencySelected
            frequencies?.forEach(frequency => {
                let object = {value: frequency, label: t('frequencies.'+frequency)}
                arrayFrequencies.push(object)
                if(arrayFrequencySelected === undefined){
                    arrayFrequencySelected = object
                }
            })
            setFrequenciesOptions({options: arrayFrequencies, selected: arrayFrequencySelected})

            dispatch(getHarmfulTest(client.id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, dispatch, t])
    
    useEffect(() => {
        if(tests){
            let array_virtues = []

            tests?.map((testrealizado, id)=>(
                testrealizado?.feelings?.map((feeling)=>(
                    array_virtues.push(feeling.virtue)
                ))
            ))

            let array_count = []
            array_virtues.forEach(function (virtue) {
                if (array_count.find(ele => ele.virtue === virtue)) {
                    array_count.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count.push({virtue: virtue, count: 1})
                }
            })
            array_count.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtues(array_count)
        }
    }, [tests])

    const deleteHarmfulTest = (key) =>{
        dispatch(removeHarmfulTest(key))
        Swal.fire({
            showConfirmButton: true,
            icon: 'success',
            text: t('harmful.removed')
        })
    }
    
    return (
    <>
        <Container fluid className='contenido modulo1'>
            <Row className='px-1 px-md-5'>
                <Col xs="12" className='mx-auto'>
                    <div className="form p-md-4 p-0">
                        <div>
                            <Row>
                                <Col className='px-4 d-flex'>
                                    <h2 className='verde'>{t('harmful.title')}</h2>
                                    <Link to="/client/profile" className='position-fixed icono-home'>
                                        <i className="fas fa-home fa-2x"></i>
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='px-4 pb-3'>
                                    <div className="alert auto alert-success text-center" role="alert">
                                        <h3 className='m-0 text-white'>{t('harmful.alert')}</h3>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='px-4'>
                                    <p><strong>{t('harmful.paragraph1')}</strong></p>
                                    <p><strong>{t('harmful.paragraph2')}</strong></p>
                                </Col>
                            </Row>
                        </div>
                        <Card>
                            <Card.Body className='px-2 px-md-5'>
                                <Row className='py-4'>
                                    <Col xs="12" md="4" className='mb-4'>
                                        <Form.Group>
                                            <p><strong className="verde">{t('harmful.habit')}</strong></p>
                                            <Form.Control type="text" className='form-control-verde' name='habit' onChange={inputHabit} value={habit} disabled={!client?.active_test}/>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="4" className='mb-4'>
                                        <Form.Group>
                                            <p><strong className="verde">{t('harmful.feeling')}</strong></p>
                                            <Select isMulti className="form-control form-control-verde" name="emotions" onChange={selectChangeEmotions} options={emotionsOptions.options}  value={emotionsOptions.selected} isDisabled={!client?.active_test}/>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="4" className='mb-4'>
                                        <Form.Group>
                                            <p><strong className="verde">{t('harmful.action')}</strong></p>
                                            <Form.Control type="text" className='form-control-verde' name='action' onChange={inputAction} value={action} disabled={!client?.active_test}/>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="4" className='mb-4'>
                                        <p><strong className="verde">{t('harmful.frequency')}</strong></p>
                                        <Select className="form-control form-control-verde" name="frequencies" onChange={selectChangeFrequencies} options={frequenciesOptions.options} value={frequenciesOptions.selected} isDisabled={!client?.active_test}/>
                                    </Col>
                                    <Col xs="12" md="4" className='mb-4'>
                                        <Form.Group>
                                            
                                            <p><strong className="verde">{t('harmful.quantity')}</strong></p>
                                            <Form.Control type="text" className='form-control-verde' name='quantity' onChange={inputQuantity} value={quantity} disabled={!client?.active_test}/>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="4" className='mb-5'>
                                        <Form.Group>                                            
                                            <p><strong className="verde">{t('harmful.impact')}</strong></p>
                                            <Form.Control type="text" className='form-control-verde' name='impact' onChange={inputImpact} value={impact} disabled={!client?.active_test}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <div className="my-2 mt-md-0 text-center text-md-start">
                                            <Button variant='primary' className='bg-modulo1' type="submit" onClick={handleSubmit} disabled={!client?.active_test}>
                                                <span className="text-start">{t('harmful.add1')}</span> <span className="text-end">{t('harmful.add2')}</span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                         </Card>
                    </div>
                </Col>
            </Row>
            <Row className='px-3 px-md-5'>
                <Col xs='12' md='8' className='px-0 px-md-5'>
                {tests?.length>0
                ?
                    <Table responsive size='sm'>
                        <thead >
                            <tr>
                                <th></th>
                                <th className='d-none d-md-table-cell'>{t('harmful.habit')}</th>
                                <th className='d-none d-md-table-cell'>{t('harmful.feeling')}</th>
                                <th className='d-none d-md-table-cell'>{t('harmful.action')}</th>
                                <th className='d-none d-md-table-cell'>{t('harmful.frequency')}</th>
                                <th className='d-none d-md-table-cell'>{t('harmful.quantity')}</th>
                                <th className='d-none d-md-table-cell'>{t('harmful.impact')}</th>
                                <th className='d-none d-md-table-cell'>{t('harmful.weakvirtues')}</th>
                                <th className='d-table-cell d-md-none'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tests?.map((testrealizado,id)=>(
                                <tr key={id}>
                                    <td>
                                        <div className="mx-2 py-2 px-3" onClick={client?.active_test ? () => deleteHarmfulTest(testrealizado._id) : <></>}><i className="fas fa-trash"></i></div>
                                    </td>
                                    <td className='d-none d-md-table-cell'>{testrealizado.habit}</td>
                                    <td className='d-none d-md-table-cell'>
                                        {testrealizado?.feelings?.map((feeling,key)=>(
                                            <div key={key}>
                                                {t('emotions.'+feeling.emotion)}
                                            </div>
                                        ))}
                                    </td>
                                    <td className='d-none d-md-table-cell'>
                                        {testrealizado.action}
                                    </td>
                                    <td className='d-none d-md-table-cell'>
                                        {t('frequencies.'+testrealizado.frequency)}
                                    </td>
                                    <td className='d-none d-md-table-cell'>
                                        {testrealizado.quantity}
                                    </td>
                                    <td className='d-none d-md-table-cell'>
                                        {testrealizado.impact}
                                    </td>
                                    <td className='d-none d-md-table-cell'>
                                        {testrealizado?.feelings?.map((feeling,key)=>(
                                            <div key={key}>
                                                {t('virtues.'+feeling.virtue)}
                                            </div>
                                        ))}
                                    </td>
                                    <td className='d-table-cell d-md-none'>
                                        <strong className='d-block d-md-none'>{t('harmful.habit')}</strong>
                                        {testrealizado.habit}
                                        <br/>
                                        <strong className='d-block d-md-none'>{t('harmful.feeling')}</strong>
                                        {testrealizado?.feelings?.map((feeling,key)=>(
                                            <div key={key}>
                                                {t('emotions.'+feeling.emotion)}
                                            </div>
                                        ))}                              
                                        <strong className='d-block d-md-none'>{t('harmful.action')}</strong>
                                        {testrealizado.action}
                                        <br/>
                                        <strong className='d-block d-md-none'>{t('harmful.frequency')}</strong>
                                        {t('frequencies.'+testrealizado.frequency)}
                                        <br/>
                                        <strong className='d-block d-md-none'>{t('harmful.quantity')}</strong>
                                        {testrealizado.quantity}
                                        <br/>
                                        <strong className='d-block d-md-none'>{t('harmful.impact')}</strong>
                                        {testrealizado.impact}
                                        <br/>
                                        <strong className='d-block d-md-none'>{t('harmful.weakvirtues')}</strong>
                                        {testrealizado?.feelings?.map((feeling,key)=>(
                                            <div key={key}>
                                                {t('virtues.'+feeling.virtue)}
                                            </div>
                                        ))}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                : 
                    <></>
                }
                </Col>
            </Row>
            <Row className='px-3 px-md-5 mt-3'>
                <Col xs='12' md='4' className='px-0 px-md-5'>
                {tests?.length>0
                ? 
                    <>
                        <h4 className='verde'>{t('resume.virtues-to-train')}</h4>
                        <Accordion> 
                        {virtues?.map((virtue, id)=>{
                            return (
                                <Accordion.Item eventKey={id} key={id} className='virtudes-sincolor'>
                                    <Accordion.Header>{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                    <Accordion.Body>
                                        {t('descriptions.'+virtue.virtue )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                        </Accordion>
                    </>
                :
                    <></>
                }
                </Col>
            </Row>
        </Container>
    </>
    )
}