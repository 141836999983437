import React, { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { getCoupleTest, sendCoupleTest } from '../../../../actions/coupleTestActions'
import { Link } from 'react-router-dom'
import { getUservirtue } from '../../../../actions/uservirtueActions'
import { useState } from 'react'
import Swal from 'sweetalert2'
import Select from "react-select"
import { Fragment } from 'react'

export default function Couple() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const {uservirtue} = useSelector(state=>state.uservirtue)
    const {coupleTest} = useSelector(state=>state.coupleTest)

    const [weakVirtues, setWeakVirtues] = useState([])
    const [hardVirtues, setHardVirtues] = useState([])

    const [virtues, setVirtues] = useState({})

    const virtuespolarities = {
        'aceptacion': ['perseverancia'],
        'admiracion': ['dignidad'],
        'alegria': ['responsabilidad', 'disciplina'],
        'compania': ['dignidad', 'identidad'],
        'compasion': ['dignidad', 'justicia'],
        'dignidad': ['admiracion', 'humildad', 'generosidad', 'responsabilidad'],
        'disciplina': ['alegria', 'libertad', 'inspiracion', 'paciencia'],
        'educacion': ['dignidad', 'identidad', 'libertad'],
        'generosidad': ['dignidad', 'justicia'],
        'guia': ['humildad'],
        'humildad': ['dignidad', 'identidad', 'justicia', 'sabiduria'],
        'identidad': ['educacion', 'humildad'],
        'inspiracion': ['disciplina'],
        'justicia': ['compasion', 'humildad'],
        'lealtad': ['dignidad', 'libertad'],
        'libertad': ['disciplina', 'educacion', 'lealtad', 'responsabilidad'],
        'paciencia': ['disciplina', 'perseverancia'],
        'perdon': ['dignidad', 'justicia'],
        'perseverancia': ['aceptacion', 'paciencia'],
        'proteccion': ['valentia'],
        'responsabilidad': ['alegria', 'dignidad', 'justicia', 'libertad'],
        'sabiduria': ['humildad'],
        'valentia': ['aceptacion', 'paciencia', 'proteccion']
    }

    const [allVirtuesPolarities, setAllVirtuesPolarities] = useState({
        aceptacion: {polarity: 'empty', virtues_couple: '', potential: ''}, admiracion: {polarity: 'empty', virtues_couple: '', potential: ''}, alegria: {polarity: 'empty', virtues_couple: '', potential: ''}, compania: {polarity: 'empty', virtues_couple: '', potential: ''}, compasion: {polarity: 'empty', virtues_couple: '', potential: ''}, dignidad: {polarity: 'empty', virtues_couple: '', potential: ''}, disciplina: {polarity: 'empty', virtues_couple: '', potential: ''},
        educacion: {polarity: 'empty', virtues_couple: '', potential: ''}, generosidad: {polarity: 'empty', virtues_couple: '', potential: ''}, guia: {polarity: 'empty', virtues_couple: '', potential: ''}, humildad: {polarity: 'empty', virtues_couple: '', potential: ''}, identidad: {polarity: 'empty', virtues_couple: '', potential: ''}, inspiracion: {polarity: 'empty', virtues_couple: '', potential: ''}, justicia: {polarity: 'empty', virtues_couple: '', potential: ''},
        lealtad: {polarity: 'empty', virtues_couple: '', potential: ''}, libertad: {polarity: 'empty', virtues_couple: '', potential: ''}, paciencia: {polarity: 'empty', virtues_couple: '', potential: ''}, perdon: {polarity: 'empty', virtues_couple: '', potential: ''}, perseverancia: {polarity: 'empty', virtues_couple: '', potential: ''}, proteccion: {polarity: 'empty', virtues_couple: '', potential: ''}, responsabilidad: {polarity: 'empty', virtues_couple: '', potential: ''},
        sabiduria: {polarity: 'empty', virtues_couple: '', potential: ''}, valentia: {polarity: 'empty', virtues_couple: '', potential: ''},
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client?.id){
            dispatch(getCoupleTest(client.id))
            dispatch(getUservirtue(client.id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, dispatch, t])
    
    useEffect(() => {
        if(uservirtue){
            let weakVirtuesArray = []
            let hardVirtuesArray = []

            for (const virtue in uservirtue) {
                if(virtue !== '_id' && virtue !== '__v' && virtue !== 'user'){
                    if(uservirtue[virtue] === 'debil'){
                        weakVirtuesArray.push(virtue)
                    }
                    if(uservirtue[virtue] === 'fuerte'){
                        hardVirtuesArray.push(virtue)
                    }
                }
            }

            setWeakVirtues(weakVirtuesArray)
            setHardVirtues(hardVirtuesArray)

            if(coupleTest?.complementary){
                setAllVirtuesPolarities(coupleTest?.complementary)
            }

            let jsonVirtues = {}

            weakVirtuesArray?.map((virtue1)=>{
                if(coupleTest?.virtues[virtue1]?.length > 0 && coupleTest.virtues[virtue1][6] === 'weak'){
                    jsonVirtues[virtue1] = [coupleTest.virtues[virtue1][0], coupleTest.virtues[virtue1][1], coupleTest.virtues[virtue1][2], coupleTest.virtues[virtue1][3], coupleTest.virtues[virtue1][4], coupleTest.virtues[virtue1][5], coupleTest.virtues[virtue1][6]]
                }else{
                    jsonVirtues[virtue1] = ['empty', 'empty', 'empty', '-', '-', '-', 'weak']
                }
                return true
            })

            hardVirtuesArray?.map((virtue1)=>{
                if(coupleTest?.virtues[virtue1]?.length > 0 && coupleTest?.virtues[virtue1][6] === 'hard'){
                    jsonVirtues[virtue1] = [coupleTest.virtues[virtue1][0], coupleTest.virtues[virtue1][1], coupleTest.virtues[virtue1][2], coupleTest.virtues[virtue1][3], coupleTest.virtues[virtue1][4], coupleTest.virtues[virtue1][5], coupleTest.virtues[virtue1][6]]
                }else{
                    jsonVirtues[virtue1] = ['empty', 'empty', 'empty', '-', '-', '-', 'hard']
                }
                return true
            })
            setVirtues(jsonVirtues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uservirtue, coupleTest, t])

    const selectWeakVirtueOptionHandle = (e, virtue, position) => {
        let jsonVirtues = {}

        weakVirtues?.map((virtue1)=>{
            if(virtues[virtue1]){
                jsonVirtues[virtue1] = [virtues[virtue1][0], virtues[virtue1][1], virtues[virtue1][2], virtues[virtue1][3], virtues[virtue1][4], virtues[virtue1][5], 'weak']
            }
            return true
        })

        hardVirtues?.map((virtue1)=>{
            if(virtues[virtue1]){
                jsonVirtues[virtue1] = [virtues[virtue1][0], virtues[virtue1][1], virtues[virtue1][2], virtues[virtue1][3], virtues[virtue1][4], virtues[virtue1][5], 'hard']
            }
            return true
        })

        jsonVirtues[virtue][position] = e.value

        if(jsonVirtues[virtue][0] === true){
            if(jsonVirtues[virtue][0] !== 'empty' && jsonVirtues[virtue][1] !== 'empty' && jsonVirtues[virtue][2] !== 'empty'){
                if(jsonVirtues[virtue][1] === true){
                    if(jsonVirtues[virtue][2] === true){
                        jsonVirtues[virtue][3] = 'wow'
                        jsonVirtues[virtue][4] = 'resultweakcouple6'
                        jsonVirtues[virtue][5] = 'actionweakcouple6'
                    }else{
                        jsonVirtues[virtue][3] = 'challenge'
                        jsonVirtues[virtue][4] = 'resultweakcouple4'
                        jsonVirtues[virtue][5] = 'actionweakcouple4'
                    }
                }else{
                    if(jsonVirtues[virtue][2] === true){
                        jsonVirtues[virtue][3] = 'flow'
                        jsonVirtues[virtue][4] = 'resultweakcouple5'
                        jsonVirtues[virtue][5] = 'actionweakcouple5'
                    }else{
                        jsonVirtues[virtue][3] = 'crisis'
                        jsonVirtues[virtue][4] = 'resultweakcouple3'
                        jsonVirtues[virtue][5] = 'actionweakcouple3'
                    }
                }
            }else{
                jsonVirtues[virtue][3] = '-'
                jsonVirtues[virtue][4] = '-'
                jsonVirtues[virtue][5] = '-'
            }
        }else{
            if(jsonVirtues[virtue][0] !== 'empty' && jsonVirtues[virtue][2] !== 'empty'){
                if(jsonVirtues[virtue][2] === true){
                    jsonVirtues[virtue][3] = 'challenge'
                    jsonVirtues[virtue][4] = 'resultweakcouple2'
                    jsonVirtues[virtue][5] = 'actionweakcouple2'
                }else{
                    jsonVirtues[virtue][3] = 'crisischallenge'
                    jsonVirtues[virtue][4] = 'resultweakcouple1'
                    jsonVirtues[virtue][5] = 'actionweakcouple1'
                }
            }else{
                jsonVirtues[virtue][3] = '-'
                jsonVirtues[virtue][4] = '-'
                jsonVirtues[virtue][5] = '-'
            }
        }

        setVirtues(jsonVirtues)
    }

    const selectHardVirtueOptionHandle = (e, virtue, position) => {
        let jsonVirtues = {}

        weakVirtues?.map((virtue1)=>{
            if(virtues[virtue1]){
                jsonVirtues[virtue1] = [virtues[virtue1][0], virtues[virtue1][1], virtues[virtue1][2], virtues[virtue1][3], virtues[virtue1][4], virtues[virtue1][5], 'weak']
            }
            return true
        })

        hardVirtues?.map((virtue1)=>{
            if(virtues[virtue1]){
                jsonVirtues[virtue1] = [virtues[virtue1][0], virtues[virtue1][1], virtues[virtue1][2], virtues[virtue1][3], virtues[virtue1][4], virtues[virtue1][5], 'hard']
            }
            return true
        })

        jsonVirtues[virtue][position] = e.value

        if(jsonVirtues[virtue][0] === true){
            jsonVirtues[virtue][3] = 'wow'
            jsonVirtues[virtue][4] = 'resulthardcouple4'
            jsonVirtues[virtue][5] = 'actionhardcouple4'
        }else{
            if(jsonVirtues[virtue][1] === true){
                if(jsonVirtues[virtue][0] !== 'empty' && jsonVirtues[virtue][1] !== 'empty'){
                    if(jsonVirtues[virtue][2] === true){
                        jsonVirtues[virtue][3] = 'challenge'
                        jsonVirtues[virtue][4] = 'resulthardcouple3'
                        jsonVirtues[virtue][5] = 'actionhardcouple3'
                    }else{
                        if(jsonVirtues[virtue][2] !== 'empty'){
                            jsonVirtues[virtue][3] = 'crisis'
                            jsonVirtues[virtue][4] = 'resulthardcouple2'
                            jsonVirtues[virtue][5] = 'actionhardcouple2'
                        }else{
                            jsonVirtues[virtue][3] = '-'
                            jsonVirtues[virtue][4] = '-'
                            jsonVirtues[virtue][5] = '-'
                        }
                    }
                }else{
                    jsonVirtues[virtue][3] = '-'
                    jsonVirtues[virtue][4] = '-'
                    jsonVirtues[virtue][5] = '-'
                }
            }else{
                jsonVirtues[virtue][3] = 'flow'
                jsonVirtues[virtue][4] = 'resulthardcouple1'
                jsonVirtues[virtue][5] = 'actionhardcouple1'
            }
        }

        setVirtues(jsonVirtues)
    }

    const saveCouple = () => {
        dispatch(sendCoupleTest({user: client.id, virtues: virtues}))
        Swal.fire({
            icon: 'success',
            text: t('impact.coupleedited'),
            showConfirmButton: true,
            confirmButtonText: t('impact.great')
        })
    }

    const saveComplementary = () => {
        let newDataVirtuesArray = allVirtuesPolarities

        hardVirtues?.map((virtue)=>{
            let newDataVirtue = {
                polarity: document.querySelector(`[virtue=${virtue}]`).querySelector('[name=selectcomplementary]').value,
                virtues_couple: document.querySelector(`[virtue=${virtue}]`).querySelector('[name=othervirtues]').value,
                potential: document.querySelector(`[virtue=${virtue}]`).querySelector('[name=creations]').value
            }

            newDataVirtuesArray[virtue] = newDataVirtue
            return true
        })

        dispatch(sendCoupleTest({user: client.id, complementary: newDataVirtuesArray}))
        Swal.fire({
            icon: 'success',
            text: t('impact.coupleedited'),
            showConfirmButton: true,
            confirmButtonText: t('impact.great')
        })
    }

    return (
    <>
        <Container fluid className='contenido modulo3'>
            <Row className='px-1 px-md-5'>
                <Col xs="12" className='mx-auto'>
                    <div className="form p-md-4 p-0">
                        <div>
                            <Row>
                                <Col className='px-4 d-flex'>
                                    <h2 className='verde'>{t('impact.title')}</h2>
                                    <Link to="/client/profile" className='position-fixed icono-home'>
                                        <i className="fas fa-home fa-2x"></i>
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='px-4 pb-3'>
                                    <div className="alert auto alert-success text-center" role="alert">
                                        <h3 className='m-0 text-white'>{t('impact.alertcouple')}</h3>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <h4 className='text-center d-block d-md-none'>{t('impact.myvirtuesweak')}</h4>
                        <Card className='mb-3 impacto'>
                            <Card.Body className='px-3 px-md-5'>
                                <Row>
                                    <Col>
                                        {virtues
                                            ?
                                            <>
                                                <Row className='pb-3 d-none d-md-flex align-items-center small border-bottom border-3 cabecera-fija'>
                                                    <Col className='text-center'><strong>{t('impact.myvirtuesweak')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.mycouplehave')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.igetbycouple')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.thankstocouple')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.level')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.result')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.action')}</strong></Col>
                                                </Row>
                                                {weakVirtues?.map((virtue, id)=>(
                                                    <>
                                                    {virtues[virtue]
                                                        ?
                                                        <Row key={id} className={`py-2 small d-block d-md-flex ${id % 2 === 0 ? 'bg-striped-gray' : 'bg-striped-white'} `}>
                                                            <Col className='d-flex align-items-center'>  
                                                                <p className='text-uppercase'><strong>{t('virtues.'+virtue)}</strong></p>
                                                            </Col>
                                                            <Col className='d-block d-md-flex align-items-center'>
                                                                <p className='d-block d-md-none'><strong>{t('impact.mycouplehave')}</strong></p>
                                                                <Select
                                                                    className="form-control form-control-verde" 
                                                                    name={'select-'+virtue}
                                                                    options={[
                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                        {label: t('impact.true'), value: true},
                                                                        {label: t('impact.false'), value: false}
                                                                    ]}
                                                                    value={{label: t('impact.'+virtues[virtue][0]), value: virtues[virtue][0]}}
                                                                    onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 0)}
                                                                />
                                                            </Col>
                                                            <Col className='d-block d-md-flex align-items-center'>
                                                                <p className='d-block d-md-none mt-2'><strong>{t('impact.igetbycouple')}</strong></p>
                                                                <Select
                                                                    className={`form-control form-control-verde ${virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? 'disabled' : '' }`} 
                                                                    name={'select-'+virtue}
                                                                    options={[
                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                        {label: t('impact.true'), value: true},
                                                                        {label: t('impact.false'), value: false}
                                                                    ]}
                                                                    value={virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? {label: t('impact.false'), value: false} : {label: t('impact.'+virtues[virtue][1]), value: virtues[virtue][1]}}
                                                                    onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 1)}
                                                                    isDisabled={virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? true : false}
                                                                />
                                                            </Col>
                                                            <Col className='d-block d-md-flex align-items-center justify-content-center'>
                                                                <p className='d-block d-md-none mt-2'><strong>{t('impact.thankstocouple')}</strong></p>
                                                                <Select
                                                                    className="form-control form-control-verde" 
                                                                    name={'select-'+virtue}
                                                                    options={[
                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                        {label: t('impact.true'), value: true},
                                                                        {label: t('impact.false'), value: false}
                                                                    ]}
                                                                    value={{label: t('impact.'+virtues[virtue][2]), value: virtues[virtue][2]}}
                                                                    onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 2)}
                                                                />
                                                            </Col>
                                                            <Col className='d-block d-md-flex align-items-center justify-content-center'>
                                                                <p className='d-block d-md-none mt-2'><strong>{t('impact.level')}</strong></p>
                                                                <p>{t('impact.'+virtues[virtue][3])}</p>
                                                            </Col>
                                                            <Col className='d-block d-md-flex align-items-center'>
                                                                <p className='d-block d-md-none mt-2'><strong>{t('impact.result')}</strong></p>
                                                                <p>{t('impact.'+virtues[virtue][4])}</p>
                                                            </Col>
                                                            <Col className='d-block d-md-flex align-items-center'>
                                                                <p className='d-block d-md-none mt-2'><strong>{t('impact.action')}</strong></p>
                                                                <p>{t('impact.'+virtues[virtue][5])}</p>
                                                            </Col>
                                                            <hr className='d-block d-md-none'></hr>
                                                        </Row>
                                                        :
                                                        <></>
                                                    }
                                                    </>
                                                ))}
                                                {/* <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>{t('impact.myvirtuesweak')}</th>
                                                            <th>{t('impact.mycouplehave')}</th>
                                                            <th>{t('impact.igetbycouple')}</th>
                                                            <th>{t('impact.thankstocouple')}</th>
                                                            <th>{t('impact.level')}</th>
                                                            <th>{t('impact.result')}</th>
                                                            <th>{t('impact.action')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {weakVirtues?.map((virtue, id)=>(
                                                            <Fragment key={id}>
                                                                {virtues[virtue]
                                                                    ?
                                                                        <tr>
                                                                            <td>{t('virtues.'+virtue)}</td>
                                                                            <td>
                                                                                <Select
                                                                                    className="form-control form-control-verde" 
                                                                                    name={'select-'+virtue}
                                                                                    options={[
                                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                                        {label: t('impact.true'), value: true},
                                                                                        {label: t('impact.false'), value: false}
                                                                                    ]}
                                                                                    value={{label: t('impact.'+virtues[virtue][0]), value: virtues[virtue][0]}}
                                                                                    onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 0)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Select
                                                                                    className={`form-control form-control-verde ${virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? 'disabled' : '' }`} 
                                                                                    name={'select-'+virtue}
                                                                                    options={[
                                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                                        {label: t('impact.true'), value: true},
                                                                                        {label: t('impact.false'), value: false}
                                                                                    ]}
                                                                                    value={virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? {label: t('impact.false'), value: false} : {label: t('impact.'+virtues[virtue][1]), value: virtues[virtue][1]}}
                                                                                    onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 1)}
                                                                                    isDisabled={virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? true : false}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Select
                                                                                    className="form-control form-control-verde" 
                                                                                    name={'select-'+virtue}
                                                                                    options={[
                                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                                        {label: t('impact.true'), value: true},
                                                                                        {label: t('impact.false'), value: false}
                                                                                    ]}
                                                                                    value={{label: t('impact.'+virtues[virtue][2]), value: virtues[virtue][2]}}
                                                                                    onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 2)}
                                                                                />
                                                                            </td>
                                                                            <td>{t('impact.'+virtues[virtue][3])}</td>
                                                                            <td>{t('impact.'+virtues[virtue][4])}</td>
                                                                            <td>{t('impact.'+virtues[virtue][5])}</td>
                                                                        </tr>
                                                                    :
                                                                        <></>
                                                                }
                                                            </Fragment>
                                                        ))}
                                                    </tbody>
                                                </Table> */}
                                            </>
                                            :
                                                <>{t('impact.thereisnovirtues')}</>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <h4 className='text-center d-block d-md-none'>{t('impact.myvirtueshard')}</h4>
                        <Card className='impacto'>
                            <Card.Body className='px-3 px-md-5'>
                                <Row>
                                    <Col>
                                        {virtues
                                            ?
                                            <>
                                                <Row className='pb-3 d-none d-md-flex align-items-center small border-bottom border-3 cabecera-fija'>
                                                    <Col className='text-center'><strong>{t('impact.myvirtueshard')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.mycouplehave')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.mycouplehas')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.mycoupleget')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.level')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.result')}</strong></Col>
                                                    <Col className='text-center'><strong>{t('impact.action')}</strong></Col>
                                                </Row>
                                                {hardVirtues?.map((virtue, id)=>(
                                                    <>
                                                     {virtues[virtue]
                                                        ?
                                                        <Row key={id} className={`py-2 small d-block d-md-flex ${id % 2 === 0 ? 'bg-striped-gray' : 'bg-striped-white'} `}>
                                                            <Col className='d-flex align-items-center'>  
                                                                <p className='text-uppercase'><strong>{t('virtues.'+virtue)}</strong></p>
                                                            </Col>
                                                            <Col className='d-block d-md-flex align-items-center'>
                                                                <p className='d-block d-md-none'><strong>{t('impact.mycouplehave')}</strong></p>
                                                                <Select
                                                                    className="form-control form-control-verde" 
                                                                    name={'select-'+virtue}
                                                                    options={[
                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                        {label: t('impact.true'), value: true},
                                                                        {label: t('impact.false'), value: false}
                                                                    ]}
                                                                    value={{label: t('impact.'+virtues[virtue][0]), value: virtues[virtue][0]}}
                                                                    onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 0)}
                                                                />
                                                            </Col>
                                                            <Col className='d-block d-md-flex align-items-center'>
                                                                <p className='d-block d-md-none'><strong>{t('impact.mycouplehave')}</strong></p>
                                                                <Select
                                                                    className={`form-control form-control-verde ${virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? 'disabled' : '' }`}
                                                                    name={'select-'+virtue}
                                                                    options={[
                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                        {label: t('impact.true'), value: true},
                                                                        {label: t('impact.false'), value: false}
                                                                    ]}
                                                                    value={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? {label: t('impact.true'), value: true} : {label: t('impact.'+virtues[virtue][1]), value: virtues[virtue][1]}}
                                                                    onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 1)}
                                                                    isDisabled={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? true : false}
                                                                />
                                                            </Col>
                                                            <Col className='d-block d-md-flex align-items-center'>
                                                                <p className='d-block d-md-none'><strong>{t('impact.mycouplehave')}</strong></p>
                                                                <Select
                                                                    className={`form-control form-control-verde ${(virtues[virtue][0] === true || virtues[virtue][0] === 'true') || ((virtues[virtue][0] === false || virtues[virtue][0] === 'false') && (virtues[virtue][1] === false || virtues[virtue][1] === 'false')) ? 'disabled' : '' }`}
                                                                    name={'select-'+virtue}
                                                                    options={[
                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                        {label: t('impact.true'), value: true},
                                                                        {label: t('impact.false'), value: false}
                                                                    ]}
                                                                    value={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? {label: t('impact.true'), value: true} : {label: t('impact.'+virtues[virtue][2]), value: virtues[virtue][2]}}
                                                                    onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 2)}
                                                                    isDisabled={(virtues[virtue][0] === true || virtues[virtue][0] === 'true') || ((virtues[virtue][0] === false || virtues[virtue][0] === 'false') && (virtues[virtue][1] === false || virtues[virtue][1] === 'false')) ? true : false}
                                                                />
                                                            </Col>
                                                            <Col className='d-block d-md-flex align-items-center justify-content-center'>
                                                                <p className='d-block d-md-none mt-2'><strong>{t('impact.level')}</strong></p>
                                                                <p>{t('impact.'+virtues[virtue][3])}</p>
                                                            </Col>
                                                            <Col className='d-block d-md-flex align-items-center'>
                                                                <p className='d-block d-md-none mt-2'><strong>{t('impact.result')}</strong></p>
                                                                <p>{t('impact.'+virtues[virtue][4])}</p>
                                                            </Col>
                                                            <Col className='d-block d-md-flex align-items-center'>
                                                                <p className='d-block d-md-none mt-2'><strong>{t('impact.action')}</strong></p>
                                                                <p>{t('impact.'+virtues[virtue][5])}</p>
                                                            </Col>
                                                            <hr className='d-block d-md-none'></hr>
                                                        </Row>
                                                        :
                                                        <></>
                                                     }
                                                    </>
                                                ))}
                                                {/* <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>{t('impact.myvirtueshard')}</th>
                                                            <th>{t('impact.mycouplehave')}</th>
                                                            <th>{t('impact.mycouplehas')}</th>
                                                            <th>{t('impact.mycoupleget')}</th>
                                                            <th>{t('impact.level')}</th>
                                                            <th>{t('impact.result')}</th>
                                                            <th>{t('impact.action')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {hardVirtues?.map((virtue, id)=>(
                                                            <Fragment key={id}>
                                                                {virtues[virtue]
                                                                    ?
                                                                        <tr>
                                                                            <td>{t('virtues.'+virtue)}</td>
                                                                            <td>
                                                                                <Select
                                                                                    className="form-control form-control-verde" 
                                                                                    name={'select-'+virtue}
                                                                                    options={[
                                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                                        {label: t('impact.true'), value: true},
                                                                                        {label: t('impact.false'), value: false}
                                                                                    ]}
                                                                                    value={{label: t('impact.'+virtues[virtue][0]), value: virtues[virtue][0]}}
                                                                                    onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 0)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Select
                                                                                    className={`form-control form-control-verde ${virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? 'disabled' : '' }`}
                                                                                    name={'select-'+virtue}
                                                                                    options={[
                                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                                        {label: t('impact.true'), value: true},
                                                                                        {label: t('impact.false'), value: false}
                                                                                    ]}
                                                                                    value={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? {label: t('impact.true'), value: true} : {label: t('impact.'+virtues[virtue][1]), value: virtues[virtue][1]}}
                                                                                    onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 1)}
                                                                                    isDisabled={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? true : false}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Select
                                                                                    className={`form-control form-control-verde ${(virtues[virtue][0] === true || virtues[virtue][0] === 'true') || ((virtues[virtue][0] === false || virtues[virtue][0] === 'false') && (virtues[virtue][1] === false || virtues[virtue][1] === 'false')) ? 'disabled' : '' }`}
                                                                                    name={'select-'+virtue}
                                                                                    options={[
                                                                                        {label: t('impact.empty'), value: 'empty'},
                                                                                        {label: t('impact.true'), value: true},
                                                                                        {label: t('impact.false'), value: false}
                                                                                    ]}
                                                                                    value={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? {label: t('impact.true'), value: true} : {label: t('impact.'+virtues[virtue][2]), value: virtues[virtue][2]}}
                                                                                    onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 2)}
                                                                                    isDisabled={(virtues[virtue][0] === true || virtues[virtue][0] === 'true') || ((virtues[virtue][0] === false || virtues[virtue][0] === 'false') && (virtues[virtue][1] === false || virtues[virtue][1] === 'false')) ? true : false}
                                                                                />
                                                                            </td>
                                                                            <td>{t('impact.'+virtues[virtue][3])}</td>
                                                                            <td>{t('impact.'+virtues[virtue][4])}</td>
                                                                            <td>{t('impact.'+virtues[virtue][5])}</td>
                                                                        </tr>
                                                                    :
                                                                        <></>
                                                                }
                                                            </Fragment>
                                                        ))}
                                                    </tbody>
                                                </Table> */}
                                            </>
                                            :
                                                <>{t('impact.thereisnovirtues')}</>
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Row className='my-3'>
                            <Col className='text-end'>
                                <Button variant='primary' type='buttom' onClick={saveCouple}>
                                    {t('global.save')}
                                </Button>
                            </Col>
                        </Row>
                        <Card>
                            <Card.Body className='px-2 px-md-5'>
                                <Row>
                                    <Col className='px-3'>
                                        <Row className='pb-3 d-none d-md-flex align-items-center small border-bottom border-3'>                                            
                                            <Col className='text-center'><strong>{t('impact.level')}</strong></Col>
                                            <Col className='text-center'><strong>{t('impact.scene')}</strong></Col>
                                            <Col className='text-center'><strong>{t('impact.recommendation')}</strong></Col>
                                        </Row>
                                        <Row className='py-4 d-block d-md-flex align-items-center small border-bottom border-3'>
                                            <Col className='text-center text-uppercase'><strong>{t('impact.crisis')}</strong></Col>  
                                            <Col>
                                                <strong className='d-block d-md-none'>{t('impact.scene')}</strong>
                                                <p>{t('impact.crisisscenecouple1')}</p>
                                                <p>{t('impact.crisisscenecouple2')}</p>
                                                <p>{t('impact.crisisscenecouple3')}</p>
                                                <p>{t('impact.crisisscenecouple4')}</p>
                                            </Col>
                                            <Col className='text-start text-md-center'>
                                                <strong className='d-block d-md-none'>{t('impact.recommendationcouple')}</strong>
                                                {t('impact.crisisrecommendationcouple')}
                                            </Col>
                                        </Row>
                                        <Row className='py-4 d-block d-md-flex align-items-center small border-bottom border-3'>
                                            <Col className='text-center text-uppercase'><strong>{t('impact.challenge')}</strong></Col>  
                                            <Col>
                                                <strong className='d-block d-md-none'>{t('impact.scene')}</strong>
                                                <p>{t('impact.challengescenecouple1')}</p>
                                                <p>{t('impact.challengescenecouple2')}</p>                                                
                                            </Col>
                                            <Col className='text-start text-md-center'>
                                                <strong className='d-block d-md-none'>{t('impact.recommendationcouple')}</strong>
                                                {t('impact.challengerecommendationcouple')}
                                            </Col>
                                        </Row>
                                        <Row className='py-4 d-block d-md-flex align-items-center small border-bottom border-3'>
                                            <Col className='text-center text-uppercase'><strong>{t('impact.flow')}</strong></Col>  
                                            <Col>
                                                <strong className='d-block d-md-none'>{t('impact.scene')}</strong>
                                                <p>{t('impact.flowscenecouple1')}</p>
                                                <p>{t('impact.flowscenecouple2')}</p>
                                            </Col>
                                            <Col className='text-start text-md-center'>
                                                <strong className='d-block d-md-none'>{t('impact.recommendationcouple')}</strong>
                                                {t('impact.flowrecommendationcouple')}
                                            </Col>
                                        </Row>   
                                        <Row className='py-4 d-block d-md-flex align-items-center small'>
                                            <Col className='text-center text-uppercase'><strong>{t('impact.wow')}</strong></Col>  
                                            <Col>
                                                <strong className='d-block d-md-none'>{t('impact.scene')}</strong>
                                                <p>{t('impact.wowscenecouple1')}</p>
                                                <p>{t('impact.wowscenecouple2')}</p>
                                            </Col>
                                            <Col className='text-start text-md-center'>
                                                <strong className='d-block d-md-none'>{t('impact.recommendationcouple')}</strong>
                                                {t('impact.wowrecommendationcouple')}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                         </Card>

                        <Row>
                            <Col className='px-4 pb-3 mt-4'>
                                <h2 className='verde'>{t('impact.alertcomplementary')}</h2>
                            </Col>
                        </Row>
                        <Card>
                            <Card.Body className='px-2 px-md-5'>
                                <Row>
                                    <Col className='px-3'>
                                        <Row className='pb-3 d-none d-md-flex align-items-center small border-bottom border-3'>                                            
                                            <Col className='text-center'><strong>{t('impact.strongvirtues')}</strong></Col>
                                            <Col className='text-center'><strong>{t('impact.virtuespolarity')}</strong></Col>
                                            <Col className='text-center'><strong>{t('impact.virtuesofcouple')}</strong></Col>
                                            <Col className='text-center'><strong>{t('impact.creations')}</strong></Col>
                                        </Row>
                                        {hardVirtues?.map((virtue, id)=>(
                                            <>
                                                <Row key={id} virtue={virtue} className='px-2 py-2 small d-block d-md-flex'>
                                                    <Col className='d-flex align-items-center'>  
                                                        <p className='text-uppercase'><strong>{t('virtues.'+virtue)}</strong></p>
                                                    </Col>
                                                    <Col className='d-block d-md-flex align-items-center'>
                                                        <p className='d-block d-md-none mb-0'><strong>{t('impact.virtuespolarity')}</strong></p>
                                                        <select
                                                            className="form-control form-control-verde" 
                                                            name='selectcomplementary'
                                                            defaultValue={allVirtuesPolarities[virtue]?.polarity}
                                                            //onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 0)}
                                                        >
                                                            <option value='empty'>{t('impact.select')}</option>
                                                            {virtuespolarities[virtue]?.map((virtueNow, id)=>(
                                                                <option key={id} value={virtueNow}>{t('virtues.'+virtueNow)}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col className='d-block d-md-flex align-items-center'>
                                                        <p className='d-block d-md-none mt-3 mb-0'><strong>{t('impact.virtuesofcouple')}</strong></p>
                                                        <Form.Control
                                                            as="textarea"
                                                            className="form-control form-control-verde"
                                                            name='othervirtues'
                                                            rows='2'
                                                            defaultValue={allVirtuesPolarities[virtue]?.virtues_couple || ''}
                                                        />
                                                    </Col>
                                                    <Col className='d-block d-md-flex align-items-center'>
                                                        <p className='d-block d-md-none mt-3 mb-0'><strong>{t('impact.creations')}</strong></p>
                                                        <Form.Control
                                                            as="textarea"
                                                            className="form-control form-control-verde"
                                                            name='creations'
                                                            rows='2'
                                                            defaultValue={allVirtuesPolarities[virtue]?.potential || ''}
                                                        />                                                         
                                                    </Col>                                                    
                                                </Row>
                                                <Row>
                                                    <Col className='px-2'>
                                                        <hr className='d-block d-md-none'></hr>
                                                    </Col>
                                                </Row>
                                            </>
                                        ))}
                                        {/* <Table>
                                            <thead>
                                                <tr>
                                                    <th className='bg-verde-table text-center'>{t('impact.strongvirtues')}</th>
                                                    <th className='bg-blanco-table text-center'>{t('impact.virtuespolarity')}</th>
                                                    <th className='bg-blanco-table text-center'>{t('impact.virtuesofcouple')}</th>
                                                    <th className='bg-verde-table text-center'>{t('impact.creations')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {hardVirtues?.map((virtue, id)=>(
                                                    <tr virtue={virtue} key={id}>
                                                        <td>{t('virtues.'+virtue)}</td>
                                                        <td>
                                                            <select
                                                                className="form-control form-control-verde" 
                                                                name='selectcomplementary'
                                                                defaultValue={allVirtuesPolarities[virtue]?.polarity}
                                                                //onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 0)}
                                                            >
                                                                <option value='empty'>{t('impact.select')}</option>
                                                                {virtuespolarities[virtue]?.map((virtueNow, id)=>(
                                                                    <option key={id} value={virtueNow}>{t('virtues.'+virtueNow)}</option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    className="form-control form-control-verde"
                                                                    name='othervirtues'
                                                                    rows='2'
                                                                    defaultValue={allVirtuesPolarities[virtue]?.virtues_couple || ''}
                                                                /> 
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    className="form-control form-control-verde"
                                                                    name='creations'
                                                                    rows='2'
                                                                    defaultValue={allVirtuesPolarities[virtue]?.potential || ''}
                                                                /> 
                                                            </Form.Group>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table> */}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Row className='my-3'>
                            <Col className='text-end'>
                                <Button variant='primary' type='buttom' onClick={saveComplementary}>
                                    {t('global.save')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className='my-4 botones-pasos'>
                <Col className="d-flex justify-content-md-space-around justify-content-center flex-wrap">
                    
                    <Link to="/client/test/impact/personalresume">
                        <Button className='boton-gris' variant='primary' type="submit">
                            {t('impact.personal')}
                        </Button>
                    </Link>
                    <Link to="/client/test/impact/professional">
                        <Button className='boton-gris' variant='primary' type="submit">
                            {t('impact.professional')}
                        </Button>
                    </Link>
            
                    <Button className='boton-blanco' variant='primary' type="submit">
                        {t('impact.couple')}
                    </Button>
                            
                </Col>
            </Row>
        </Container>
    </>
    )
}