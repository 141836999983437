import i18n from "i18next"
import { initReactI18next } from "react-i18next"
// import LanguageDetector from 'i18next-browser-languagedetector'
// import dotenv from  'dotenv'
import en_translate from './languages/en.json'
import es_translate from './languages/es.json'

// Adrian
import adrian_en_trans from './languages/adrian.en.json'
import adrian_es_trans from './languages/adrian.es.json'

// Alfonso
import alfonso_en_trans from './languages/alfonso.en.json'
import alfonso_es_trans from './languages/alfonso.es.json'

// Manolo
import manolo_en_trans from './languages/manolo.en.json'
import manolo_es_trans from './languages/manolo.es.json'

let userLang = navigator.language || navigator.userAgent.userLanguage

if(localStorage.getItem('motif-lang') !== undefined && localStorage.getItem('motif-lang')){
    userLang = localStorage.getItem('motif-lang')
}

const defaultLocale = userLang

let en_translate_user = ''
let es_translate_user = ''

if (typeof process.env.REACT_APP_USER !== 'undefined') {
    if(process.env.REACT_APP_USER === 'adrian'){
        en_translate_user = adrian_en_trans
        es_translate_user = adrian_es_trans
    }
    if(process.env.REACT_APP_USER === 'alfonso'){
        en_translate_user = alfonso_en_trans
        es_translate_user = alfonso_es_trans
    }
    if(process.env.REACT_APP_USER === 'manolo'){
        en_translate_user = manolo_en_trans
        es_translate_user = manolo_es_trans
    }
}

// Se recorren todos los indices del elemento que se va a añadir en español
Object.keys(es_translate_user).forEach(element => {
    // Si existe tal elemento
    if(typeof es_translate[element] !== 'undefined'){
        // Se recorren todos los valores de cada elemento
        Object.keys(es_translate_user[element]).forEach(values => {
            // Si no existe tal valor, lo añade
            if(typeof es_translate[element][values] === 'undefined'){
                es_translate[element][values] = es_translate_user[element][values];
            }
        });
    }else{
    // Si no existe tal elemento, lo añade
        es_translate[element] = es_translate_user[element];
    }
});

// Se recorren todos los indices del elemento que se va a añadir en inglés
Object.keys(en_translate_user).forEach(element => {
    // Si existe tal elemento
    if(typeof en_translate[element] !== 'undefined'){
        // Se recorren todos los valores de cada elemento
        Object.keys(en_translate_user[element]).forEach(values => {
            // Si no existe tal valor, lo añade
            if(typeof en_translate[element][values] === 'undefined'){
                en_translate[element][values] = en_translate_user[element][values];
            }
        });
    }else{
        // Si no existe tal elemento, lo añade
        en_translate[element] = en_translate_user[element];
    }
});

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: en_translate
        },
        es: {
            translation: es_translate
        }
    },
    lng: defaultLocale,
    fallbackLng: defaultLocale,
    interpolation: {
        escapeValue: false
    }
})