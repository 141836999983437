import { useTranslation } from "react-i18next"
import { Button, Form } from 'react-bootstrap'
import Select from "react-select"
import { useEffect, useState } from "react"

export default function FormUser({submit, isNew, inputChange, state, accesses}) {
    const { t } = useTranslation()
    
    const [accessOptions, setAccessOptions] = useState([])
    const [newpassword, setNewpassword] = useState('')
    const [confpassword, setConfNewpassword] = useState('')
    const [match, setMatch] = useState(true)

    useEffect(() => {  
        let accessesToUse = []
        let accessSelected = null

        accesses.access?.forEach(access => {
            let object = {value: access._id, label: access.name}
            accessesToUse.push(object)
            if (state.access !== undefined) {
                if (state.access === access._id) {
                    accessSelected = object
                }
            }
        })

        setAccessOptions({options: accessesToUse, selected: accessSelected})
    }, [accesses, state])

    const handleSelectChange = (selectedOption) => {
        inputChange({target:{name: 'access', value: selectedOption.value}})
    }

    const inputNewPassChange = (e) => {
        setNewpassword(e.target.value)
    }

    const inputConfNewPassChange = (e) => {
        setConfNewpassword(e.target.value)
    }

    const handleCheckboxChange = (e) => {
        inputChange({target:{name: 'active_test', value: e.target.checked}})
    }

    useEffect(() => {
        if(newpassword.length > 5 && confpassword.length > 5){
            if(newpassword === confpassword){
                inputChange({target:{name: 'password', value: newpassword}})
                setMatch(true)
            }else{
                inputChange({target:{name: 'password', value: ''}})
                setMatch(false)
            }
        }else{
            inputChange({target:{name: 'password', value: ''}})
            setMatch(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newpassword, confpassword])

    useEffect(() => {
        if(state.password === ''){
            setNewpassword('')
            setConfNewpassword('')
        }
    }, [state.password])

    return (
        <>
            <Form onSubmit={submit}>
                <Form.Group>
                    <Form.Label className="form-label mt-3">{t('users.profile')}</Form.Label>
                    <Select onChange={handleSelectChange} name="access" placeholder={t('users.access')} options={accessOptions.options} value={accessOptions.selected}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="form-label mt-3">{t('users.firstname')}</Form.Label>
                    <Form.Control className="form-control"
                        type='text'
                        name='firstname' 
                        value={state.firstname || state.firstnameEdit ||  ''}
                        placeholder={t('users.firstname')}
                        onChange={inputChange}
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="form-label mt-3">{t('users.lastname')}</Form.Label>
                    <Form.Control className="form-control"
                        type='text'
                        name='lastname' 
                        value={state.lastname || state.lastnameEdit ||  ''}
                        placeholder={t('users.lastname')}
                        onChange={inputChange}
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="form-label mt-3">{t('users.email')}</Form.Label>
                    <Form.Control className="form-control"
                        type='text'
                        name='email' 
                        value={state.email || state.emailEdit ||  ''}
                        placeholder={t('users.email')}
                        onChange={inputChange}
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="form-label mt-3">{t('users.address')}</Form.Label>
                    <Form.Control className="form-control"
                        type='text'
                        name='address' 
                        value={state.address || state.addressEdit ||  ''}
                        placeholder={t('users.address')}
                        onChange={inputChange}
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="form-label mt-3">{t('users.phone')}</Form.Label>
                    <Form.Control className="form-control"
                        type='text'
                        name='phone' 
                        value={state.phone || state.phoneEdit ||  ''}
                        placeholder={t('users.phone')}
                        onChange={inputChange}
                        required
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="form-label mt-3">{t('users.username')}</Form.Label>
                    <Form.Control className="form-control"
                        type='text'
                        name='username' 
                        value={state.username || state.usernameEdit ||  ''}
                        placeholder={t('users.username')}
                        onChange={inputChange}
                        required
                    />
                </Form.Group>
                <Form.Group>
                    { isNew === false
                    ?
                    <>
                        <Form.Label className="form-label mt-3">{t('users.newpassword')}</Form.Label>
                        <Form.Control className="form-control"  autoComplete="new-password"
                            type='password'
                            name='password' 
                            value={newpassword}
                            placeholder={t('users.newpassword')}
                            onChange={inputNewPassChange}
                            minLength='6'
                        />
                    </>
                    :
                    <>
                        <Form.Label className="form-label mt-3">{t('users.password')}</Form.Label>
                        <Form.Control className="form-control"  autoComplete="new-password"
                            type='password'
                            name='password' 
                            value={newpassword}
                            placeholder={t('users.password')}
                            onChange={inputNewPassChange}
                            minLength='6'
                            required
                        />
                    </>
                    }
                    { isNew === false
                    ?
                    <>
                        <Form.Label className="form-label mt-3">{t('users.confirmnewpassword')}</Form.Label>
                        <Form.Control className="form-control"
                            type='password'
                            name='passwordconf' 
                            value={confpassword}
                            placeholder={t('users.confirmpassword')}
                            onChange={inputConfNewPassChange}
                        />
                    </>
                    :
                    <>
                        <Form.Label className="form-label mt-3">{t('users.confirmpassword')}</Form.Label>
                        <Form.Control className="form-control"
                            type='password'
                            name='passwordconf' 
                            value={confpassword}
                            placeholder={t('users.confirmpassword')}
                            onChange={inputConfNewPassChange}
                            required
                        />
                    </>
                    }
                        { match === false
                        ?
                            <span className="text-danger">* {t('admin.user.passnotmatch')}</span>
                        :
                            <span></span>
                        }
                </Form.Group>
                <Form.Group className="d-flex mt-3">    
                { isNew === false
                    ?
                    <>                
                        <Form.Check
                            className="mx-3"
                            type='checkbox'
                            name='active_test' 
                            placeholder={t('users.active_test')}
                            onChange={handleCheckboxChange}
                            checked={state.active_test === true || state.active_testEdit === true ? true : false}
                        />
                    </>
                    :
                    <>
                        <Form.Check
                            className="mx-3"
                            type='checkbox'
                            name='active_test' 
                            placeholder={t('users.active_test')}
                            onChange={handleCheckboxChange}                            
                            checked={true}
                        />
                    </>
                }
                    <Form.Label className="form-label">{t('users.active_test')}</Form.Label>
                </Form.Group>
                <Form.Group>
                    <Button className="mt-3 float-end" type="submit"><i className="fas fa-save"></i> {t('admin.user.actualizar')}</Button>
                </Form.Group>
            </Form>
        </>
    )    
}
