import React,{useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { Container, Row, Col, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getInitialTest } from '../../actions/initialTestActions'
import { getUserDetail, addUser } from '../../actions/usersActions'
import { getAccesses } from '../../actions/accessActions'
import FormUser from '../../components/client/FormUser'
import Swal from 'sweetalert2'

export default function UserCard() {     
    const { t } = useTranslation()
    const {id} = useParams()
    const {user} = useSelector(state=>state.users)   
    const dispatch = useDispatch()


    const accesses = useSelector((state) => state.access)

    const [formState, setFormState] = useState({})
    const {username, email, password, firstname, lastname, address, access, phone} = formState

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()

        dispatch(addUser(username, email, password, firstname, lastname, address, access, phone))
        setFormState({
            username: '',
            email: '',
            password: '',
            firstname: '',
            lastname: '',
            address: '',
            access: '',
            phone: ''
        })
        Swal.fire({
            showConfirmButton: true,
            icon: 'success',
            text: t('admin.user.add')
        })
    }

    useEffect(()=>{
        dispatch(getUserDetail(id))
        dispatch(getAccesses())
    }, [dispatch,id])

    useEffect(() => {
        if (user) {
            dispatch(getInitialTest(id))  
            setFormState({
                username: user.username,
                email: user.email,
                password: '',
                firstname: user.firstname,
                lastname: user.lastname,
                address: user.address,
                access: user.access,
                phone: user.phone
            })
        }
    }, [user, dispatch, id])


    return (
        <Container className='cuerpo'>
            <Row>
                <Col className='text-center'>
                    <Button className="float-end" variant="outline-success" href={`/admin/users`}>
                        <i className='fas fa-angle-left'></i> {t('global.gotousers')}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>

                    <h1>{t('admin.newuser')}</h1>
                </Col>
            </Row>
            <Row>
                <Col xs="12" md="6" className='mx-auto'>
                    <FormUser submit={handleEdit} isNew={true} inputChange={handleInputChange} state={formState} accesses={accesses}></FormUser>
                </Col>
            </Row>
        </Container>
    )
}
