import React from 'react'
import {Row,Col} from 'react-bootstrap'
import { useTranslation } from "react-i18next"

export const NeverAlways = () => {
  const { t } = useTranslation()  
  return (
    <Row className='never-always text-center'>
        <Col>{t('global.never')}</Col>
        <Col><span dangerouslySetInnerHTML={{__html: t('global.little')}}></span></Col>
        <Col>{t('global.sometimes')}</Col>
        <Col>{t('global.always')}</Col>
    </Row>
  )
}
