import React,{useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { Row, Col, Navbar,Nav,Container, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetail, editUser, removeUser } from '../../actions/usersActions'
import { getAccesses } from '../../actions/accessActions'
import FormUser from '../../components/client/FormUser'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

export default function UserCard() {
    const { t } = useTranslation()
    const {id} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const {user} = useSelector(state=>state.users)
    const accesses = useSelector((state) => state.access)

    const [formState, setFormState] = useState({})
    const {username, email, password, firstname, lastname, address, access, phone, image, active_test, idUser} = formState
    

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleEdit = async(e) => {
        e.preventDefault()

        dispatch(editUser(username, email, password, firstname, lastname, address, access, phone, image, active_test, idUser))
        Swal.fire({
            showConfirmButton: true,
            icon: 'success',
            text: t('admin.user.edit')
        })
    }

    useEffect(()=>{
        dispatch(getUserDetail(id))
        dispatch(getAccesses())
    }, [dispatch, id])

    useEffect(() => {
        if (user) {
            setFormState({
                username: user.username,
                email: user.email,
                password: '',
                firstname: user.firstname,
                lastname: user.lastname,
                address: user.address,
                access: user.access,
                phone: user.phone,
                image: user.image,
                active_test: user.active_test,
                idUser: user._id
            })
        }
    }, [user, dispatch, id])

    const deleteThisUser = (idUsuario) => {
        Swal.fire({
            title: t('admin.areusure'),
            showDenyButton: true,
            confirmButtonText: t('admin.delete'),
            denyButtonText: t('admin.cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeUser(idUsuario))
                Swal.fire(t('admin.successfullydeleted'), '', 'success')
                navigate('/admin/users')
            }
        })
    }

    return (
        <Container className='cuerpo'>
            <Row>
                <Col>
                    <Button className="float-end" variant="outline-success" href={`/admin/users`}>
                        <i className='fas fa-angle-left'></i> {t('global.gotousers')}
                    </Button>
                    <h1>{t('admin.user.user')}:&nbsp;<span className='text-uppercase'>{user?.firstname} {user?.lastname}</span></h1>            
                    <Navbar  className="bg-gray my-3">
                        <Container>                    
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">                        
                                <Nav.Link href={`/admin/bloqueuno/`+id}>{t('global.phase')} 1</Nav.Link>
                                <Nav.Link href={`/admin/bloquedos/`+id}>{t('global.phase')} 2</Nav.Link>
                                <Nav.Link href={`/admin/user/`+id}>{t('global.data')}</Nav.Link>
                            </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className='float-end' variant='danger' type="submit" onClick={() => deleteThisUser(id)}>
                        <i className='fas fa-trash'></i> {t('admin.deleteuser')}
                    </Button>  
                </Col>
            </Row>
            <Container>
                <Row className='py-4'>
                    <Col xs="12" md="6" className='mx-auto'>
                        <FormUser submit={handleEdit} isNew={false} inputChange={handleInputChange} state={formState} accesses={accesses}></FormUser>
                    </Col>
                </Row>
            </Container>

        </Container>
    )
}
