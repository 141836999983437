import React from 'react'
import { useTranslation } from "react-i18next"

export default function NotFoundPage () {
  const { t } = useTranslation()

  return (
    <div>
      {t('home.404')}
    </div>
  )
}
