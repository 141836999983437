import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Container,Row,Col,Form,Button,Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { getWeakTest } from '../../../../actions/weakTestActions'
import { getProspectTest } from '../../../../actions/prospectTestActions'
import { getMediumhardTest, sendMediumhardTest } from '../../../../actions/mediumhardTestActions'
import { useNavigate, Link } from 'react-router-dom'
import Virtues from './Virtues'
import Swal from 'sweetalert2'

export default function Mediumhard() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {client} = useAuth()

    const mediumhard = [
        'aceptacion','admiracion','alegria','compania',
        'compasion','dignidad','disciplina','educacion',
        'generosidad','guia','humildad','identidad',
        'inspiracion','justicia','lealtad','libertad',
        'paciencia','perdon','perseverancia','proteccion',
        'responsabilidad','sabiduria','valentia'
    ]

    const {test} = useSelector(state=>state.mediumhardTest)
    const weakTests = useSelector(state => state.weakTest)
    const prospectTest = useSelector(state => state.prospectTest)

    const [virtuesWeak, setVirtuesWeak] = useState([])

    const minimoElementos = 23

    let questions = []
    for(let i=0; i<=22; i++){
        questions.push(i)
    }
    const [selectedState, setSelectedState] = useState(new Array(minimoElementos).fill(0))
    const [selectedStatePeroBien, setStatePeroBien] = useState(new Array(minimoElementos).fill(0))

    const handleClick = (target)=>{
        setSelectedState(selectedState.map((answer, index) => (index === target.target.name-1 ? target.target.getAttribute('seleccion') : answer)))
        setStatePeroBien(selectedStatePeroBien.map((answer, index) => (index === target.target.name-1 ? parseInt(target.target.getAttribute('seleccion')) : answer)))
    }

    const handleSubmit = async(e) => {
        e.preventDefault()

        let countVirtuesNotCount = new Array(minimoElementos).fill(1)
        let elementsCorrect = 0

        mediumhard?.map((mhvirtue, index) => (
            virtuesWeak.includes(mhvirtue) ? countVirtuesNotCount[index] = 0 : <></>
        ))

        selectedStatePeroBien?.map((currentState,index) => {
            if(currentState >= countVirtuesNotCount[index]){
                elementsCorrect++
            }

            return true
        })

        if(elementsCorrect === minimoElementos){
            let respuestas = []
            selectedState?.map( (currentState,index) => {
                if(currentState){
                    respuestas.push({number:index+1, type: selectedState[index] === '1' ? 'medium' : 'hard' , virtue: mediumhard[index]})
                }
                return true
            })
            dispatch(sendMediumhardTest(respuestas,client.id))
            navigate('/client/resume')
        }else{
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text: t('mediumhard.notselected')
            })
        }
    }
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client?.id){
            dispatch(getMediumhardTest(client?.id))
            dispatch(getWeakTest(client?.id))
            dispatch(getProspectTest(client?.id))
        }
    }, [client, dispatch, t])
    
    useEffect(() => {
        if(test !== null){
            let testfound = new Array(minimoElementos).fill(false)
            let testfoundPeroBien = new Array(minimoElementos).fill(false)
            test.responses.forEach(response => {
                if(response.type === 'medium'){
                    testfound[response.number-1] = '1'
                    testfoundPeroBien[response.number-1] = 1
                }else{
                    testfound[response.number-1] = '2'
                    testfoundPeroBien[response.number-1] = 2
                }
            })
            setSelectedState(testfound)
            setStatePeroBien(testfoundPeroBien)
        }
    }, [test])

    useEffect(() => {
        if(weakTests.test || prospectTest.tests){
            let array_virtues = []
            let virtues_count = 0

            if(weakTests.test){
                weakTests.test?.responses?.map((response)=>{
                    array_virtues.includes(response.weak) ? <></> : virtues_count++
                    array_virtues.push(response.weak)
                    return true
                })
            }

            if(prospectTest.tests){
                prospectTest.tests?.map((testrealizado, id)=>(
                    testrealizado?.feelings?.map((feeling)=>{
                        array_virtues.includes(feeling.virtue) ? <></> : virtues_count++
                        array_virtues.push(feeling.virtue)
                        virtues_count++
                        return true
                    })
                    &&
                    testrealizado?.conducts?.map((conduct)=>{
                        array_virtues.includes(conduct.virtue) ? <></> : virtues_count++
                        array_virtues.push(conduct.virtue)
                        virtues_count++
                        return true
                    })
                ))
            }

            setVirtuesWeak(array_virtues)
        }
    }, [weakTests.test, prospectTest.tests])
    
    return (
    <>
        <Container fluid className='contenido modulo1'>
            <Row className='px-1 px-md-5'>
                <Col xs="12" className='mx-auto'>
                    <div className="form p-md-4 p-0">
                        <div>
                            <Row>
                                <Col className='px-4 d-flex'>
                                    <h2 className='verde'>{t('mediumhard.title')}</h2>
                                    <Link to="/client/profile" className='position-fixed icono-home'>
                                        <i className="fas fa-home fa-2x"></i>
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='px-4 pb-3'>
                                    <div className="alert auto alert-success text-center" role="alert">
                                        <h3 className='m-0 text-white'>{t('mediumhard.alert')}</h3>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='px-4'>
                                    <p><strong>{t('mediumhard.paragraph1')}</strong></p>
                                    <p><strong>{t('mediumhard.paragraph2')}</strong></p>
                                </Col>
                            </Row>
                            <div className='d-flex justify-content-start my-5 px-4'>
                                <div>
                                    <img
                                    src={`/images/icono-mano-check.png`}
                                    width={50}
                                    alt=""
                                    />
                                </div>
                                <div className='ps-5'>
                                    <p className='verde'><strong>{t('mediumhard.title-questions')}</strong></p>
                                    <p><strong>{t('mediumhard.explanation')}</strong></p>
                                </div>
                            </div>
                        </div>
                        <Card>
                            <Card.Header className='cabecera-fija sticky-top'>
                                    <Row>
                                        <Col xs="6" md="8">
                                        </Col>
                                        <Col  xs="3" md="2" className='pt-4 d-flex justify-content-center text-center'>
                                            <p><strong>{t('mediumhard.table-header1')}</strong></p>
                                        </Col>
                                        <Col  xs="3" md="2" className='pt-4 d-flex justify-content-center text-center'>
                                            <p><strong>{t('mediumhard.table-header2')}</strong></p>
                                        </Col>
                                    </Row>
                            </Card.Header>
                            <Card.Body className='px-3 px-md-5'>
                                    
                                {/* RECORREMOS TODAS LAS PREGUNTAS  */}
                                {selectedState?.map((radioSelected, key) => (
                                    <div key={key} attr_key={key} className='question-conscience'>
                                        <Row className='pt-5 pb-4'>
                                            <Col xs="6" md="8">
                                                {virtuesWeak.includes(mediumhard[key])
                                                    ?
                                                    <p className='virtudes-entrenar-title-question'><strong>{t('mediumhard.question'+(key+1))}</strong></p>
                                                    :
                                                    <p className='virtudes-entrenar-title-question-noblocked'><strong>{t('mediumhard.question'+(key+1))}</strong></p>
                                                }
                                            </Col>
                                            <Col  xs="3" md="2" className='d-flex justify-content-center align-items-center'>
                                                <Form.Check 
                                                    className='customized'
                                                    type="radio"
                                                    name={key+1}
                                                    seleccion={1}
                                                    checked={radioSelected === '1' ? true : false}
                                                    onChange={handleClick}
                                                    disabled={!client?.active_test || virtuesWeak.includes(mediumhard[key])}
                                                />
                                            </Col>
                                            <Col  xs="3" md="2" className='d-flex justify-content-center align-items-center'>
                                                <Form.Check 
                                                    className='customized'
                                                    type="radio"
                                                    name={key+1}
                                                    seleccion={2}
                                                    checked={radioSelected === '2' ? true : false}
                                                    onChange={handleClick}
                                                    disabled={!client?.active_test || virtuesWeak.includes(mediumhard[key])}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </Card.Body>
                         </Card>
                        {/* PIE Y BOTON */}
                        <Row className='my-4 botones-pasos'>
                            <Col className="d-flex justify-content-md-between justify-content-center flex-wrap">
                                    <Link to="/client/result">
                                        <Button className='boton-gris' variant='primary' type="submit">
                                            {t('conscience.initial')}
                                        </Button>
                                    </Link>
                                
                                    <Link to="/client/test/conscience/weak">
                                        <Button className='boton-gris' variant='primary' type="submit">
                                            {t('conscience.virtues-to-train')}
                                        </Button>
                                    </Link>
                                
                                    <Link to="/client/test/conscience/prospect">
                                        <Button className='boton-gris' variant='primary' type="submit">
                                            {t('conscience.limiting-expectations')}
                                        </Button>
                                    </Link>
                                
                                    <Button className='boton-blanco' variant='primary' type="submit">
                                        {t('conscience.medium-strong-virtues')}
                                    </Button>
                                
                                    <Button variant='primary' type="submit" onClick={handleSubmit} disabled={!client?.active_test}>
                                        <i className='fas fa-save'></i> {t('global.saveandgo')}
                                    </Button>                                    
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Virtues></Virtues>
            </Row>
        </Container>
    </>
    )
}