export const harmfulTestReducer = (state = {loading:true,result:null,test:null,tests:null} , action) => {
    switch (action.type) {
        case 'HARMFUL_TEST_SEND_SUCCESS':
            return {
                ...state,
                loading:false,
                result: null,
                tests: [...state.tests, action.payload]
            }
        case 'HARMFUL_TEST_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                tests: action.payload
            }
        case 'HARMFUL_TEST_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'HARMFUL_TEST_ERROR':
            return {
                ...state,
                loading:false
            }
        case 'HARMFUL_DELETE_SUCCESS':
            return {...state,
                loading:false,
                result: action.payload.message,
                tests: state.tests.filter(tests => tests._id !== action.payload.idHarmful)
            }
        case 'HARMFUL_DELETE_NOT_SUCCESS':
            return {...state,
                loading: false,
                result: action.payload
            }
        case 'HARMFUL_ERROR':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}

