import React from 'react'
import {Container,Row,Col} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'

export default function Dashboard() { 
	const { t } = useTranslation()

	return (
		<>
			<Container fluid className='cuerpo px-4'>
					<Row>
						<Col className='d-flex justify-conten-start align-items-center'>
							<img
								src="/images/icono-profile.png"
								width="100"
								className="d-inline-block align-top justify-center float-left"
								alt="Profile"
							/>     
							<div className='ms-5'>
								<h2>¡{t('profile.hello')} {t('profile.administrador')}</h2>
							</div>
						</Col>
					</Row>
					<Row className='pt-5 pb-4 d-flex justify-content-center'>
						<Col xs="auto" className='px-4 pb-3'>
							<Link to="/admin/users">								
								<h3 className='m-0 text-center p-4 bg-gray rounded'><i className='fas fa-user fa-2x'></i><br/>{t('profile.users')}</h3>								
							</Link>
						</Col>
						<Col xs="auto" className='px-4 pb-3'>
							<Link to="/admin/guides">								
								<h3 className='m-0 text-center p-4 bg-gray rounded'><i className='fas fa-play fa-2x'></i><br/>{t('profile.guides')}</h3>
								
							</Link>
						</Col>
					</Row>
				
			</Container>
		</>
	)
}