import Axios  from "axios"

export const sendEvaluationTest = (user, evaluation) => async (dispatch) => {
    dispatch({
        type: 'EVALUATION_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.post(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/evaluation/register', {
                user: user,
                evaluation: evaluation
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'EVALUATION_TEST_SEND_SUCCESS',
                payload: data.message
            }) 
        }

    } catch (error) {
        dispatch({
            type: 'EVALUATION_TEST_ERROR'
        })         
    }
}

export const getAllEvaluationTest = (user) => async (dispatch) => {
    dispatch({
        type: 'EVALUATION_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/evaluation/getbyuser/' + user, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'EVALUATION_ALLTEST_GET_SUCCESS',
                payload: data.evaluations
            })
        }
        if(status===263){
            dispatch({
                type: 'EVALUATION_ALLTEST_GET_EMPTY'
            })
        }
    } catch (error) {
        dispatch({
            type: 'EVALUATION_TEST_ERROR'
        })
    }
}