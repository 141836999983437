import React,{useEffect, useState} from 'react'
import {Container,Row,Col,Card, Accordion} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetail } from './../../../../actions/usersActions'
import { getWeakTest } from '../../../../../src/actions/weakTestActions'
import { getProspectTest } from '../../../../../src/actions/prospectTestActions'
import { getMediumhardTest } from '../../../../../src/actions/mediumhardTestActions'

export default function Virtues() { 
    const { t } = useTranslation()
    const {client} = useAuth()
    
    const dispatch = useDispatch()

    const weakTests = useSelector(state => state.weakTest)
    const prospectTest = useSelector(state => state.prospectTest)
    const mediumhardTest = useSelector(state => state.mediumhardTest)

    const [virtuesWeak, setVirtuesWeak] = useState([])
    const [virtuesMedium, setVirtuesMedium] = useState([])
    const [virtuesStrong, setVirtuesStrong] = useState([])

    useEffect(() => {
        if(client?.id){
            dispatch(getUserDetail(client?.id))
            dispatch(getWeakTest(client?.id))
            dispatch(getProspectTest(client?.id))
            dispatch(getMediumhardTest(client?.id))
        }
    }, [dispatch, client])

    useEffect(() => {
        if(weakTests.test || prospectTest.tests){
            let array_virtues = []

            if(weakTests.test){
                weakTests.test?.responses?.map((response)=>{
                    array_virtues.push(response.weak)
                    return true
                })
            }

            if(prospectTest.tests){
                prospectTest.tests?.map((testrealizado, id)=>(
                    testrealizado?.feelings?.map((feeling)=>{
                        array_virtues.push(feeling.virtue)
                        return true
                    })
                    &&
                    testrealizado?.conducts?.map((conduct)=>{
                        array_virtues.push(conduct.virtue)
                        return true
                    })
                ))
            }

            let array_count = []
            array_virtues.forEach(function (virtue) {
                if (array_count.find(ele => ele.virtue === virtue)) {
                    array_count.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count.push({virtue: virtue, count: 1})
                }
            })
            array_count.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtuesWeak(array_count)
        }
    }, [weakTests.test, prospectTest.tests])

    useEffect(() => {
        if(mediumhardTest.test){
            let array_virtues_hard = []
            let array_virtues_medium = []

            let array_count_hard = []
            let array_count_medium = []

            mediumhardTest.test?.responses?.map((response)=>{
                if(response.type === 'hard'){
                    array_virtues_hard.push(response.virtue)
                }
                return true
            })
            array_virtues_hard.forEach(function (virtue) {
                if (array_count_hard.find(ele => ele.virtue === virtue)) {
                    array_count_hard.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count_hard.push({virtue: virtue, count: 1})
                }
            })
            array_count_hard.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtuesStrong(array_count_hard)

            mediumhardTest.test?.responses?.map((response)=>{
                if(response.type === 'medium'){
                    array_virtues_medium.push(response.virtue)
                }
                return true
            })

            array_virtues_medium.forEach(function (virtue) {
                if (array_count_medium.find(ele => ele.virtue === virtue)) {
                    array_count_medium.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count_medium.push({virtue: virtue, count: 1})
                }
            })
            array_count_medium.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtuesMedium(array_count_medium)
        }
    }, [mediumhardTest.test])

    return (
        <>            
            <Container fluid>
                <Row>
                    <Col className='d-flex justify-content-center my-3'>
                        <div className="alert auto alert-default text-center border" role="alert">
                            <h2 className='m-0 text-white'>{t('resume.virtues-perfil')}</h2>
                        </div>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col className='columna-virtudes my-3'>
                        <Card className='h-100 p-3'>
                            <Card.Body className='px-2'>
                                <Row>
                                    <Col>
                                        <div className="alert alert-default text-center" role="alert">
                                            {/* <img className='float-left mx-2' src="/images/virtudes-sol.png" alt=''/> */}
                                            <h4 className='text-white mb-0'>{t('resume.virtues-hard')}</h4>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <Accordion> 
                                        {virtuesStrong?.map((virtue, id)=>{
                                            return (
                                                virtue.virtue !== '' 
                                                ?
                                                    <Accordion.Item eventKey={id} key={id} className="virtudes-sincolor">
                                                        <Accordion.Header className="d-flex justify-content-center">{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                                        <Accordion.Body>
                                                            {t('descriptions.'+virtue.virtue )}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                :
                                                    <></>
                                            )
                                        })}
                                        </Accordion>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='columna-virtudes my-3'>
                        <Card className='h-100 p-3'>
                            <Card.Body className='px-2'>
                                <Row>
                                    <Col>
                                        <div className="alert  alert-default text-center" role="alert">
                                            {/* <img className='float-left mx-2' src="/images/virtudes-mediosol.png" alt=''/> */}
                                            <h4 className='text-white mb-0'>{t('resume.virtues-to-train')}</h4>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <Accordion> 
                                        {virtuesWeak?.map((virtue, id)=>{
                                            return (
                                                virtue.virtue !== '' 
                                                ?
                                                    <Accordion.Item eventKey={id} key={id} className='virtudes-sincolor'>
                                                        <Accordion.Header className="d-flex justify-content-center">{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                                        <Accordion.Body>
                                                            {t('descriptions.'+virtue.virtue )}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                :
                                                    <></>
                                            )
                                        })}
                                        </Accordion>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='columna-virtudes my-3'>
                        <Card className='h-100 p-3'>
                            <Card.Body className='px-2'>
                                <Row>
                                    <Col>
                                        <div className="alert alert-default text-center" role="alert">
                                            {/* <img className='float-left mx-2' src="/images/virtudes-nube.png" alt=''/> */}
                                            <h4 className='mb-0 text-white'>{t('resume.virtues-medium')}</h4>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <Accordion> 
                                        {virtuesMedium?.map((virtue, id)=>{
                                            return (
                                                virtue.virtue !== '' 
                                                ?
                                                    <Accordion.Item eventKey={id} key={id} className="virtudes-sincolor">
                                                        <Accordion.Header className="d-flex justify-content-center">{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                                        <Accordion.Body>
                                                            {t('descriptions.'+virtue.virtue )}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                :
                                                    <></>
                                            )
                                        })}
                                        </Accordion>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}


                
