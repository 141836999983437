import { Modal } from 'react-bootstrap'

export default function FormModal({id='loading', show = false}){

    return (
        <>
        <Modal 
            className='loading-modal'
            show={show} 
            id={id} 
            tabIndex={-1} 
            aria-labelledby="contained-modal-title-vcenter"
            size="sm"
            centered
            role="dialog">                        
                <div className="modal-content modal-rounded modal-xs">
                    <Modal.Body>
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
        </Modal>
        </>
      )
}