export const evaluationTestReducer = (state = {loading:true,result:null,tests:null} , action) => {
    switch (action.type) {
        case 'EVALUATION_TEST_SEND_SUCCESS':
            return {
                ...state,
                loading:false,
                result: action.payload
            }
        case 'EVALUATION_ALLTEST_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                tests: action.payload
            }
        case 'EVALUATION_TEST_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'EVALUATION_TEST_ERROR':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}