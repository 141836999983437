export const prospectTestReducer = (state = {loading:true,result:null,test:null,tests:null} , action) => {
    switch (action.type) {
        case 'PROSPECT_TEST_SEND_SUCCESS':
            return {
                ...state,
                loading:false,
                result: action.message,
                tests: action.payload
            }
        case 'PROSPECT_TEST_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                tests: action.payload
            }
        case 'PROSPECT_TEST_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'PROSPECT_TEST_ERROR':
            return {
                ...state,
                loading:false
            }
        case 'PROSPECT_DELETE_SUCCESS':
            return {...state,
                loading:false,
                result: action.payload.message,
                tests: state.tests.filter(tests => tests._id !== action.payload.idProspect)
            }
        case 'PROSPECT_DELETE_NOT_SUCCESS':
            return {...state,
                loading: false,
                result: action.payload
            }
        case 'PROSPECT_ERROR':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}

