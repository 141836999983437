import React, {useEffect} from 'react'
import {Container,Row,Col,Card,Button} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import useAuth from '../../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getInitialTest } from '../../../actions/initialTestActions'

export default function Conscience() {    
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const {test} = useSelector(state=>state.initialTest)

    useEffect(() => {
        if(client?.id){
            dispatch(getInitialTest(client?.id))
        }
    }, [dispatch, client])
    
    return (
    <>    
        <Container fluid className='contenido conscience'>
            <Row className='px-3 px-md-5'>
                <Col xs="12" className='mx-auto p-5'>                    
                        <Card>
                            <Card.Body>   
                                <Row>
                                    <Col className='d-flex align-items-start'>
                                        <img
                                            src="/images/icono-consciencia.png"
                                            width={80}
                                            alt=""
                                        />
                                        <div className='ms-4'>
                                            <h2 className='verde mb-0'>{t('profile.conscience')}</h2>
                                            <p>{t('profile.phrase-conscience')}</p>
                                        </div>
                                        <Link to="/client/profile" className='position-absolute icono-home-card'>
                                        <i className="fas fa-home fa-2x"></i>
                                        </Link>
                                    </Col>
                                </Row>   
                                <Row>
                                    <Col xs="12" sm="5" className='p-3'>
                                        {test && test[0]?.result
                                            ?
                                                <Button className='boton-fases' href="/client/result" >
                                                    {t('conscience.initial')}
                                                </Button>
                                            :
                                                <Button className='boton-fases' href="/client/test/initial" >
                                                    {t('conscience.initial')}
                                                </Button>
                                        }
                                        <Button className='boton-fases' href="/client/test/conscience/weak" >
                                            {t('conscience.virtues-to-train')}
                                        </Button>
                                        <Button className='boton-fases' href="/client/test/conscience/prospect" >
                                            {t('conscience.limiting-expectations')}
                                        </Button>
                                    </Col>
                                    <Col xs="12" sm="5" className='p-3'>
                                        <Button className='boton-fases'  href="/client/test/conscience/mediumhard" >
                                            {t('conscience.medium-strong-virtues')}
                                        </Button>
                                        <Button className='boton-fases' href="/client/test/conscience/harmful" >
                                            {t('conscience.harmful-habits')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>                    
                </Col>
            </Row>
        </Container>
    </>
    )
}
