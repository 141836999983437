import Axios  from "axios"

export const sendWeeklyTest = (responses,user) => async (dispatch) => {
    dispatch({
        type: 'WEEKLY_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.post(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/weekly/register', {
                responses: responses,
                user: user
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'WEEKLY_TEST_SEND_SUCCESS',
                payload: data.message
            }) 
        }

    } catch (error) {
        dispatch({
            type: 'WEEKLY_TEST_ERROR'
        })         
    }
}

export const getAllWeeklyTest = (user) => async (dispatch) => {
    dispatch({
        type: 'WEEKLY_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/weekly/getbyuser/' + user, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'WEEKLY_ALLTEST_GET_SUCCESS',
                payload: data.weekly
            })
        }
        if(status===263){
            dispatch({
                type: 'WEEKLY_ALLTEST_GET_EMPTY'
            })
        }
    } catch (error) {
        dispatch({
            type: 'WEEKLY_TEST_ERROR'
        })
    }
}