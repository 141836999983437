import React from 'react'
import {Navbar,Nav,Container} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../context/auth/useAuth'

export default function MenuPrivate() {
	const { t } = useTranslation()     
	const {logout} = useAuth()
  	return (
		<Navbar expand="xs" fixed="top" id="menu-private" className={`py-4 mb-5 `}>
			<Container fluid>
				<Navbar.Brand href="/admin/dashboard" className='ps-5 d-flex align-items-center'>
					<img
						src="/images/logotipo-invertido.png"
						width="150"						
						alt="Logotipo MIRACO"
					/>
					<span className='ms-3 mt-2 text-white'>| {t('menu.connect')} <strong>{t('menu.withyou')}</strong></span>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" className='custom-toggler' />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="text-end">					
						<Nav.Link href="/client/profile">{t('menu.profile')}</Nav.Link>
						<Nav.Link href="#" onClick={logout}>{t('menu.logout')}</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}
