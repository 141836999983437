import React, {useState,useEffect} from 'react'
import { useTranslation } from "react-i18next"
import {Table} from 'react-bootstrap'


export const InitialTableData = ({responses,fecha}) => {
    const { t } = useTranslation()
    const [resultado, setResultado] = useState([])            

    useEffect(() => {        
        if(responses.length>0){
            
            // const fecha = new Date(test[test.length-1].date)
            // setFecha(fecha.toLocaleString())
            setResultado(calculoInitial(responses))
        }
    }, [responses])       
  return (
    <>
        {fecha
        ?   <div>
                <p><strong>{t('initial.whendone')}{ new Date(Date.parse(fecha)).getDate()<10 ? '0'+new Date(Date.parse(fecha)).getDate() : new Date(Date.parse(fecha)).getDate()}/{ new Date(Date.parse(fecha)).getMonth()+1<9 ? '0'+parseInt(new Date(Date.parse(fecha)).getMonth()+1): parseInt(new Date(Date.parse(fecha)).getMonth()+1)}/{ new Date(Date.parse(fecha)).getFullYear()}</strong>
                &nbsp;|&nbsp;{t('initial.hourwhendone')}{ new Date(Date.parse(fecha)).getHours()}:{ new Date(Date.parse(fecha)).getMinutes()<10 ? '0'+new Date(Date.parse(fecha)).getMinutes() : new Date(Date.parse(fecha)).getMinutes()}:{ new Date(Date.parse(fecha)).getSeconds()<10 ? '0'+new Date(Date.parse(fecha)).getSeconds() : new Date(Date.parse(fecha)).getSeconds()}</p>
            </div>
        : null
        }

        <Table>
            <thead>
                <tr>
                    <th>{t('global.query')}</th>
                    <th className='text-center'>{t('global.response')}</th>
                    <th className='text-center'>{t('global.media')}</th>                        
                </tr>
            </thead>
            <tbody>
            {responses?.map((respuesta,id) => (
                <tr key={id}>
                    <td>{t('initial.question'+(parseInt(id)+1))}</td>
                    <td className='text-center'>{respuesta}</td>
                    {id===0
                        ?
                        <td rowSpan={5} className='text-center bg-gray'>{resultado[1]}</td>
                        : null
                    }
                    {id===5
                        ?
                        <td rowSpan={5} className='text-center bg-graylight'>{resultado[2]}</td>
                        : null
                    }  
                    {id===10
                        ?
                        <td rowSpan={5} className='text-center bg-gray'>{resultado[3]}</td>
                        : null
                    }
                    {id===15
                        ?
                        <td rowSpan={5} className='text-center bg-graylight'>{resultado[4]}</td>
                        : null
                    } 

                </tr>
                
            ))}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={2} className='text-end text-uppercase'>{t('global.total')}</th>
                    <th className='text-center'>{parseFloat(resultado[0]).toFixed(2)} / 4</th>
                </tr>
            </tfoot>
        </Table>
    </>
  )
}

function calculoInitial(datos){
    let totalA=0 , promedioA=0, promedioAsimple=0
    let totalB=0 , promedioB=0, promedioBsimple=0
    let totalC=0 , promedioC=0, promedioCsimple=0
    let totalD=0 , promedioD=0, promedioDsimple=0
    let promedioTotal=0         
    for(var i=0;i<=19;i++){                
        if(i<=4){
            totalA+=datos[i]
        }else{
            if(i<=9){
                totalB+=datos[i]
            }else{
                if(i<=14){
                    totalC+=datos[i]
                }else{
                    totalD+=datos[i]
                }
            }
        }
    }
    promedioAsimple = totalA /5
    promedioA = totalA /5*2
    promedioBsimple = totalB /5    
    promedioB = totalB /5*2
    promedioCsimple = totalC /5
    promedioC = totalC /5*2
    promedioDsimple = totalD /5
    promedioD = totalD /5*4
    promedioTotal= (promedioA+promedioB+promedioC+promedioD)/10
    return [promedioTotal,promedioAsimple,promedioBsimple,promedioCsimple,promedioDsimple]
  }
