import { Navigate } from "react-router-dom";

/**
 * Comprueba si el usuario está logeado
 * @returns Redirecciona al login si no está logeado y si lo está renderiza la vista pedida
 */
const PrivateRouter = ({children}) => {
    const jwt = require("jsonwebtoken");
    const token = sessionStorage.getItem('jwt')
    let token_decode = null
    if (token!=='undefined'){
        token_decode = jwt.decode(token, { header: true })
    }
    
    return token_decode?.id ? children : <Navigate to="/auth"/>
}

export default PrivateRouter;