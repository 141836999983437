import React from 'react'

export const LinesTest = ({position,cantidad, module = null}) => {
    let iterations=[]
    for(var i=1;i<=cantidad;i++){
        iterations.push(i)
    } 
    return (
    <>  
        <div className='initial-line-green-startend'></div>
        {iterations.map((paso) => (
            <div key={paso}>                
                {(() => {
                    if (paso === '1'){
                        return (
                            <>
                                <div className={`initial-line-green ${module}`} attr_dato={paso} attr_dato2={position}></div>
                                <div className='initial-line-green-separator'></div>
                            </>     
                        )
                    }  
                    if(paso===position){
                        return (
                            <>
                                <div className={`initial-line-green ${module}`} attr_dato={paso} attr_dato2={position}></div>
                                <div className='initial-line-green-separator'></div>
                            </>     
                        )
                    }else{
                        if(paso<=position){
                            return (
                                <>
                                    <div className='initial-line-gray' attr_dato={paso} attr_dato2={position}></div>
                                    <div className='initial-line-green-separator'></div>
                                </>
                            )
                        }else{
                            return (
                                <>
                                <div className='initial-line-grey' attr_dato={paso} attr_dato2={position}></div>
                                <div className='initial-line-green-separator'></div>
                                </>
                            )
                        }
                    }
                })()}                
            </div>
        ))}        
        <div className='initial-line-green-startend'></div>
    </>
    )
}
