import React, { useState, useEffect, Fragment } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Container,Row,Col,Form,Button,Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { NeverAlways } from '../../../components/never-always'
import useAuth from '../../../context/auth/useAuth'
import Swal from 'sweetalert2'
import { sendInitialTest, getInitialTest } from '../../../actions/initialTestActions'
import { LinesTest } from '../../../components/linesTest'
import { useNavigate } from 'react-router-dom'
import Loading from '../../../components/design/Modal/Loading'

export default function Initial() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {client} = useAuth()
    const {result} = useSelector(state=>state.initialTest)
    const [step, setStep] = useState(1)

    const [responsesTotal, setResponsesTotal] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client !== null){
            dispatch(getInitialTest(client.id))
        }
    }, [client, dispatch, t])

    useEffect(() => {
        if(result){
            navigate('/client/result')
        }else{
            setLoading(false)
        }
    }, [result, navigate])

    const setResponse = (index, value) => {
        setResponsesTotal({
            ...responsesTotal, [index]: value
        })
    }

    const handleClick = async(e) => {
        e.preventDefault()
        if(step < 4){
            if(comprobarBloqueCompletado(step)){
                setStep(step+1)
                var element = document.querySelector("#inicial_test_header");
                element.scrollIntoView({ behavior: 'smooth', block: 'end'});
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'error',
                    text: 'Complete todas las preguntas para continuar.'
                })
            }
        }else{
            if(comprobarBloqueCompletado(step)){
                let responses = []
                for(var i=1;i<=20;i++){
                    responses.push(verradiovalue('q'+i))
                }
                dispatch(sendInitialTest(responses,client.id))
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'error',
                    text: 'Complete todas las preguntas para continuar.'
                })
            }
        }
    }

    return (
        <>
            <Container className='contenido bg-gray borders-verdes'>
                <Row className='px-0 px-md-5'>
                    <Col xs="12" className='mx-auto'>
                        <div className="form p-md-4 p-0" id="inicial_test">
                            <div id="inicial_test_header">
                                <Row>
                                    <Col>
                                        <h2>{t('initial.title')}</h2>
                                        <p>{t('initial.description')}</p>
                                    </Col>
                                </Row>
                            </div>          
                            <Card>
                                <Card.Body>
                            {['health','success','company','effort'].map((steps,id) => (     

                                <div id={`inital_test_${steps}`} style={step === id+1 ? null : {display: "none"}} key={steps} >
                                    <h3 className='titulo-paso-test d-flex align-items-center'>
                                        <img
                                            src={`/images/initial_test_${id+1}.png`}
                                            className="d-inline-block align-top justify-center pe-3"
                                            alt=""
                                            width="80"
                                        />
                                        {t('initial.'+steps)}
                                    </h3>
                                    {[1,2,3,4,5].map((question, indice) => (
                                        <Fragment key={indice}>
                                            <div key={`${steps+question}`} attr_key={`${steps+question}`}>
                                                <Row className='mt-5'>
                                                    <Col xs="12" lg="6">
                                                        <p>{t('initial.question'+(question+(id*5)))}</p>
                                                    </Col>
                                                    <Col  xs="12" lg="6" className='d-flex flex-column'>
                                                        <NeverAlways/>
                                                        <Row className='text-center'>
                                                        {[1,2,3,4].map((number) => (
                                                            <Col key={number}>
                                                                <Form.Check type="radio" onClick={() => setResponse((question+(id*5))-1, number)} name={`q${question+(id*5)}`} value={`${number}`} inline='true' key={`${steps+question+number}`}/>
                                                            </Col>
                                                        ))}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Fragment>
                                    ))}
                                </div>
        
                            ))}  
                                </Card.Body>
                            </Card>                       
                            {/* PIE Y BOTON */}
                            <Row id="inicial_test_footer">   
                            </Row>
                            <Row className='my-4 d-flex align-items-center'>
                                <Col xs="12" md="8" id="inicial_test_footer_bottom">
                                    <LinesTest position={step} cantidad={4} />
                                </Col>
                                <Col xs="12" md="4" id="inicial_test_footer_right">
                                    <div className="d-grid d-block gap-2 text-end mt-2 mt-md-0">
                                        <Button variant='primary' type="submit" onClick={handleClick}>
                                            {t('global.saveandgo')}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row className='d-block d-md-flex justify-content-around px-0 px-md-5'>
                    {['health','success','company','effort'].map((steps,id) => (                        
                            (steps === 'health' && step >= 2) || (steps === 'success' && step >= 3) || (steps === 'company' && step >= 4) || (steps === 'effort' && step >= 5) 
                            ?
                                <Col xs={12} sm={3} key={id}>
                                    <Card className="my-4 mx-auto px-3 ">
                                        <Card.Body>
                                            <div id={`inital_test_${steps}`} key={steps} >
                                                <h3 className='titulo-paso-test d-flex align-items-center'>
                                                    <img
                                                        src={`/images/initial_test_${id+1}.png`}
                                                        className="d-inline-block align-top justify-center pe-3"
                                                        alt=""
                                                        width="80"
                                                    />
                                                    {t('initial.'+steps)}
                                                </h3>
                                                {[1,2,3,4,5].map((question, indice) => (
                                                    <Fragment key={indice}>
                                                        <div key={`${steps+question}`} attr_key={`${steps+question}`}>
                                                            <Row className='mt-5'>
                                                                <Col xs="10">
                                                                    <p>{t('initial.question'+(question+(id*5)))}</p>
                                                                </Col>
                                                                <Col  xs="2" className='d-flex flex-column'>
                                                                    <Row className='text-center'>
                                                                        <Col>
                                                                            <input className="form-check-input" type="radio" name={`q${question+(id*5)}`} value={responsesTotal[question+(id*5)-1]} inline='true'/>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Fragment>
                                                ))}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            :
                            <Fragment key={id}></Fragment>
                    ))}
                </Row>
            </Container>
            <Loading show={loading} />
        </>
    )
}

function verradiovalue(id){
    var i 
    var elemento = document.getElementsByName(id)        
    for (i = 0; i < elemento.length; i++){ 
       if (elemento[i].checked) {
        return elemento[i].value
      }
    }         
}
function comprobarBloqueCompletado(step){
    var contador=0
    for(var i=1+(step-1)*5;i<=step*5;i++){                   
        if(verradiovalue('q'+i)>0){
            contador++                
        }            
    }    
    if(contador>=5){
        return true
    }else{
        return false
    }
}
