import Axios  from "axios"

export const sendProspectTest = (user, me, who, prospect, feelings, conducts) => async (dispatch) => {
    dispatch({
        type: 'PROSPECT_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.post(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/conscience/prospect/register', {
                user: user,
                me: me,
                who: who,
                prospect: prospect,
                feelings: feelings,
                conducts: conducts
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )      
        if(status===200){
            dispatch({
                type: 'PROSPECT_TEST_SEND_SUCCESS',
                message: data.message,
                payload: data.tests
            }) 
        }

    } catch (error) {
        dispatch({
            type: 'PROSPECT_TEST_ERROR'
        })         
    }
}

export const getProspectTest = (user) => async (dispatch) => {
    dispatch({
        type: 'PROSPECT_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/conscience/prospect/get/'+ user, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'PROSPECT_TEST_GET_SUCCESS',
                payload: data.prospect
            })
        }

    } catch (error) {
        dispatch({
            type: 'PROSPECT_TEST_ERROR'
        })         
    }
}

export const removeProspectTest = (idProspect) => async (dispatch) => {
    try {
        const {
            data,
            status
        } = await Axios.delete(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/conscience/prospect/remove/' + idProspect, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200) {
            dispatch({
                type: 'PROSPECT_DELETE_SUCCESS',
                payload: {
                    idProspect: idProspect,
                    message: data.message,
                    count: data.count
                }
            })
        }else{
            dispatch({
                type: 'PROSPECT_DELETE_NOT_SUCCESS',
                payload: {
                    message: data.message,
                    count: data.count
                }
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROSPECT_ERROR'
        })
    }
}