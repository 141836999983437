import React, {useEffect, useState} from 'react'
import { useTranslation } from "react-i18next"
import i18n from "i18next";
import { Button } from 'react-bootstrap';

export default function ChangeLanguage() {
	const { t } = useTranslation()     
    const normal = localStorage.getItem('motif-lang')
	const [language, swapLanguage] = useState((navigator.language || navigator.userAgent.userLanguage).split('-')[0]);

	useEffect(() => {
		if(normal !== undefined && normal){
			swapLanguage(localStorage.getItem('motif-lang'))
		}
	}, [normal, t]);

	const changeLanguage = (button) => {
		localStorage.setItem('motif-lang', button.target.value)
		swapLanguage(button.target.value)
		i18n.changeLanguage(button.target.value.split('-')[0]);
	}
    
    return (
        <div className='d-flex align-items-center'>
            <Button className={ language === 'es' ? "language_active" : "language_inactive"} onClick={changeLanguage} value={'es'}>{t('menu.lang.es')}</Button>
			<span className="text-white">|</span>
            <Button className={ language === 'en' ? "language_active" : "language_inactive"} onClick={changeLanguage} value={'en'}>{t('menu.lang.en')}</Button>
        </div>
    )
}