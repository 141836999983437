export const coupleTestReducer = (state = {loading:true,result:null,coupleTest:null} , action) => {
    switch (action.type) {
        case 'COUPLE_TEST_SEND_SUCCESS':
            return {
                ...state,
                loading:false,
                result: action.payload
            }
        case 'COUPLE_TEST_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                coupleTest: action.payload
            }
        case 'COUPLE_TEST_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'COUPLE_TEST_ERROR':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}