import Axios  from "axios"

export const sendGuide = (virtue, url, text1, text2, text3, text4) => async (dispatch) => {
    dispatch({
        type: 'GUIDE_LOADING'
    })
    try {
        const {data,status} = await Axios.post(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/guide/register', {
                virtue: virtue,
                url: url,
                text1: text1,
                text2: text2,
                text3: text3,
                text4: text4
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'GUIDE_SEND_SUCCESS',
                payload: data.message
            }) 
        }

    } catch (error) {
        dispatch({
            type: 'GUIDE_ERROR'           
        })         
    }
}

export const getGuide = (virtue) => async (dispatch) => {
    dispatch({
        type: 'GUIDE_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/guide/getbyvirtue/'+ virtue, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'GUIDE_GET_SUCCESS',
                payload: data.guide
            })
        }else{
            dispatch({
                type: 'GUIDE_GET_SUCCESS',
                payload: null
            })
        }
    } catch (error) {
        dispatch({
            type: 'GUIDE_ERROR'
        })         
    }
}

export const getAllGuide = () => async (dispatch) => {
    dispatch({
        type: 'GUIDE_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/guide/getall/', {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'GUIDE_ALL_GET_SUCCESS',
                payload: data.guides
            })
        }
    } catch (error) {
        dispatch({
            type: 'GUIDE_ERROR'
        })
    }
}

export const editGuide = (guide, url, text1, text2, text3, text4) => async (dispatch) => {
    try {
        const {data, status} = await Axios.put(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/guide/edit/' + guide, {
                url: url,
                text1: text1,
                text2: text2,
                text3: text3,
                text4: text4
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200) {
            dispatch({
                type: 'GUIDE_DONE_ONE_SUCCESS',
                payload: data.guide
            })
        }
    } catch (error) {
        dispatch({
            type: 'GUIDE_ERROR'
        })
    }
}

export const deleteComment = (guide, comment) => async (dispatch) => {
    try {
        const {data, status} = await Axios.put(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/guide/editcomments/' + guide, {
                comment: comment
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200) {
            dispatch({
                type: 'GUIDE_DONE_ONE_SUCCESS',
                payload: data.guide
            })
        }
    } catch (error) {
        dispatch({
            type: 'GUIDE_ERROR'
        })
    }
}

export const aprovecomment = (guide, comment) => async (dispatch) => {
    try {
        const {data, status} = await Axios.put(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/guide/aprovecomment/' + guide, {
                comment: comment
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200) {
            dispatch({
                type: 'GUIDE_DONE_ONE_SUCCESS',
                payload: data.guide
            })
        }
    } catch (error) {
        dispatch({
            type: 'GUIDE_ERROR'
        })
    }
}

export const addreview = (user, guide, comment) => async (dispatch) => {
    try {
        const {data, status} = await Axios.put(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/guide/addreview/' + guide, {
                user: user,
                comment: comment
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200) {
            dispatch({
                type: 'GUIDE_DONE_ONE_SUCCESS',
                payload: data.guide
            })
        }
    } catch (error) {
        dispatch({
            type: 'GUIDE_ERROR'
        })
    }
}

export const removeGuide = (idguide) => async (dispatch) => {
    try {
        const {data, status} = await Axios.delete(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/guide/remove/' + idguide, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200) {
            dispatch({
                type: 'GUIDE_DELETE_SUCCESS',
                payload: {
                    idHarmful: idguide,
                    message: data.message,
                    count: data.count
                }
            })
        }else{
            dispatch({
                type: 'GUIDE_DELETE_NOT_SUCCESS',
                payload: {
                    message: data.message,
                    count: data.count
                }
            })
        }
    } catch (error) {
        dispatch({
            type: 'GUIDE_ERROR'
        })
    }
}