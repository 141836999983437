export const guideReducer = (state = {loading:true,result:null,guide:null,guides:null} , action) => {
    switch (action.type) {
        case 'GUIDE_SEND_SUCCESS':
            return {
                ...state,
                loading:false,
                result: action.payload
            }
        case 'GUIDE_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                guide: action.payload
            }
        case 'GUIDE_ALL_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                guides: action.payload
            }
        case 'GUIDE_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'GUIDE_DELETE_SUCCESS':
            return {...state,
                loading:false,
                result: action.payload.message,
                guides: state.guides.filter(guides => guides._id !== action.payload.idGuide)
            }
        case 'GUIDE_DONE_SUCCESS':
            return {
                ...state,
                guides: state.guides?.map(exercise => {
                    if (exercise._id === action.payload._id) {
                        return action.payload
                    } else {
                        return exercise
                    }
                })
            }
        case 'GUIDE_DONE_ONE_SUCCESS':
            return {
                ...state,
                guide: action.payload
            }
        case 'GUIDE_ERROR':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}

