import Axios  from "axios"

export const sendInitialTest = (responses,user) => async (dispatch) => {
    dispatch({
        type: 'INITIAL_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.post(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/initial/register', {
                responses: responses,
                user: user
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )      
        if(status===200){            
            dispatch({
                type: 'INITIAL_TEST_SEND_SUCCESS',
                payload: data.message
            }) 
        }

    } catch (error) {
        dispatch({
            type: 'INITIAL_TEST_ERROR'           
        })         
    }
}

export const getInitialTest = (user) => async (dispatch) => {
    dispatch({
        type: 'INITIAL_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/initial/get/'+ user, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )              
        if(status===200){            
            dispatch({
                type: 'INITIAL_TEST_GET_SUCCESS',
                payload: data.initial
            })             
        }

    } catch (error) {
        dispatch({
            type: 'INITIAL_TEST_ERROR'           
        })         
    }
}

export const getAllInitialTest = () => async (dispatch) => {
    dispatch({
        type: 'INITIAL_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/initial/getall/', {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'INITIAL_ALLTEST_GET_SUCCESS',
                payload: data.initials
            })
        }

    } catch (error) {
        dispatch({
            type: 'INITIAL_TEST_ERROR'
        })
    }
}