import React, { useEffect, useState, Fragment} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Container, Row, Col, Card, Button, Form, Modal, Table } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { getPersonalTest, sendPersonalTest } from '../../../../actions/personalTestActions'
import { getUservirtue } from '../../../../actions/uservirtueActions'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Select from "react-select"


export default function Personal() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {client} = useAuth()

    const {personalTest} = useSelector(state=>state.personalTest)
    const {uservirtue} = useSelector(state=>state.uservirtue)
    const [modalShow, setModalShow] = useState(false)

    const [hardVirtues, setHardVirtues] = useState([])
    const [motivateVirtues, setMotivateVirtues] = useState([])
    const [favourite, setFavourite] = useState('')

    const [dimensionOptions, setDimensionOptions] = useState({
        options: [
            {label: t('impact.family'), value: 'family'},
            {label: t('impact.community'), value: 'community'},
            {label: t('impact.country'), value: 'country'},
            {label: t('impact.world'), value: 'world'}
        ],
        selected: ''
    })

    const [durationOptions, setDurationOptions] = useState({
        options: [
            {label: t('impact.ilive'), value: 'ilive'},
            {label: t('impact.iimpact'), value: 'iimpact'},
            {label: t('impact.intime'), value: 'intime'}
        ],
        selected: ''
    })

    const [lifeproject, setLifeproject] = useState('')
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client?.id){
            dispatch(getUservirtue(client.id))
            dispatch(getPersonalTest(client.id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, dispatch, t])
    
    useEffect(() => {
        if(personalTest){
            if(personalTest.motivate){
                setMotivateVirtues(personalTest.motivate)
            }
            if(personalTest.dimension){
                setDimensionOptions({options: dimensionOptions.options, selected: {label: t('impact.'+personalTest.dimension), value: personalTest.dimension}})
            }
            if(personalTest.duration){
                setDurationOptions({options: durationOptions.options, selected: {label: t('impact.'+personalTest.duration), value: personalTest.duration}})
            }
            if(personalTest.lifeproject){
                setLifeproject(personalTest.lifeproject)
            }
            if(personalTest.favourite){
                setFavourite(personalTest.favourite)
            }
        }
    }, [personalTest, dimensionOptions.options, durationOptions.options, t])

    useEffect(() => {
        if(uservirtue){
            let hardVirtuesArray = []

            for (const virtue in uservirtue) {
                if(virtue !== '_id' && virtue !== '__v' && virtue !== 'user'){
                    if(uservirtue[virtue] === 'fuerte'){
                        hardVirtuesArray.push(virtue)
                    }else{
                        let arrayMotivateVirtues = motivateVirtues
                        if(arrayMotivateVirtues?.includes(virtue)){
                            setMotivateVirtues(arrayMotivateVirtues.filter(virtueFilter => virtueFilter !== virtue))
                        }
                    }
                }
            }
            setHardVirtues(hardVirtuesArray)
        }
    }, [uservirtue, motivateVirtues])

    const addVirtueMotivate = (virtue) => {
        let arrayMotivateVirtues = motivateVirtues

        if(arrayMotivateVirtues.includes(virtue)){
            setMotivateVirtues(arrayMotivateVirtues.filter(virtueFilter => virtueFilter !== virtue))
        }else{
            if(arrayMotivateVirtues.length < 3){
                setMotivateVirtues([...motivateVirtues, virtue])
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'warning',
                    text: t('impact.cantaddmotivate')
                })
            }
        }
    }

    const selectDimensionHandle = (e) => {
        setDimensionOptions({options: dimensionOptions.options, selected: {label: t('impact.'+e.value), value: e.value}})
    }

    const selectDurationHandle = (e) => {
        setDurationOptions({options: durationOptions.options, selected: {label: t('impact.'+e.value), value: e.value}})
    }

    const savePersonal = () => {
        dispatch(sendPersonalTest({
            user: client.id,
            motivate: motivateVirtues,
            favourite: favourite,
            dimension: dimensionOptions.selected?.value,
            duration: durationOptions.selected?.value,
            lifeproject: lifeproject
        }))
        Swal.fire({
            icon: 'success',
            text: t('impact.personaledited'),
            showConfirmButton: true,
            confirmButtonText: t('impact.gotoresume')
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/client/test/impact/personalresume')
            }
        })
    }

    return (
    <>
        <Container fluid className='contenido modulo3'>
            <Row className='px-1 px-md-5'>
                <Col xs="12" className='mx-auto'>
                    <div className="form p-md-4 p-0">
                        <Row>
                            <Col className='px-4 d-flex'>
                                <h2 className='verde'>{t('impact.title')}</h2>
                                <Link to="/client/profile" className='position-fixed icono-home'>
                                    <i className="fas fa-home fa-2x"></i>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='px-4 pb-3'>
                                <div className="alert auto alert-success text-center" role="alert">
                                    <h3 className='m-0 text-white'>{t('impact.alert')}</h3>
                                </div>
                            </Col>
                        </Row>
                        <Card className='mb-3'>
                            <Card.Body className='px-2 px-md-5'>
                                <Row>
                                    <Col xs="12" md="7">
                                        <p><strong className="verde">{t('impact.motivate1-header')}</strong></p>
                                        {t('impact.motivate1-body')}
                                        <Row>
                                        {hardVirtues?.length > 0
                                            ?
                                                hardVirtues?.map((virtue, ivirtue) => (
                                                    <Col xs='6' sm='5' md='4' className='mt-2 mb-2' key={ivirtue}>
                                                        {!motivateVirtues?.includes(virtue)
                                                            ?
                                                                <Button className='btn btn-primary boton-blanco w-100 f-small' onClick={() => addVirtueMotivate(virtue)}>{t('virtues.'+virtue)}</Button>
                                                            :
                                                                <Button className='btn btn-primary w-100 f-small' onClick={() => addVirtueMotivate(virtue)}>{t('virtues.'+virtue)}</Button>
                                                        }
                                                    </Col>
                                                ))
                                            :
                                                <Fragment></Fragment>
                                        }
                                        </Row>
                                    </Col>
                                    <Col xs="12" md="5">
                                        <p><strong className="verde">{t('impact.motivate2-header')}</strong></p>
                                        <ul>
                                            <li className="li-verde my-3"><span>{t('impact.motivate2-body1')}</span></li>
                                            <li className="li-verde my-3"><span>{t('impact.motivate2-body2')}</span></li>
                                            <li className="li-verde my-3"><span>{t('impact.motivate2-body3')}</span></li>
                                            <li className="li-verde my-3"><span>{t('impact.motivate2-body4')}</span></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Card.Body>
                         </Card>
                        <Card className='mb-3'>
                            <Card.Body className='px-2 px-md-5'>
                                <Row>
                                    <Col xs="12" md="7">
                                        <p><strong className="verde">{t('impact.motivate3-header')}</strong></p>
                                        <p>{t('impact.motivate3-body')}</p>
                                        <Button variant='primary' className='my-3' type='buttom' onClick={() => setModalShow(true)}>
                                            {t('impact.examplesfavourites')}
                                        </Button>
                                    </Col>
                                    <Col xs="12" md="5">
                                        <p><strong className="verde">{t('impact.motivate4-header')}</strong></p>
                                        <Form.Group>
                                            <Form.Control
                                                    as="textarea"
                                                    className="form-control form-control-verde"
                                                    name='favourite'
                                                    rows='6'
                                                    value={favourite}
                                                    onChange={(e) => setFavourite(e.target.value)}
                                            />  
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                         </Card>
                        <Card className='mb-3'>
                            <Card.Body className='px-2 px-md-5'>
                                <Row>
                                    <Col>
                                        <p><strong className="verde">{t('impact.motivate5-header')}</strong></p>
                                        <Select
                                            className="form-control form-control-verde" 
                                            name="dimension" 
                                            options={dimensionOptions.options}
                                            value={dimensionOptions.selected}
                                            onChange={selectDimensionHandle}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                         </Card>
                        <Card className='mb-3'>
                            <Card.Body className='px-2 px-md-5'>
                                <Row>
                                    <Col>
                                        <p><strong className="verde">{t('impact.motivate6-header')}</strong></p>
                                        <Select
                                            className="form-control form-control-verde" 
                                            name="duration" 
                                            options={durationOptions.options}
                                            value={durationOptions.selected}
                                            onChange={selectDurationHandle}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                         </Card>
                        <Card>
                            <Card.Body className='px-2 px-md-5'>
                                <Row>
                                    <Col>
                                        <p><strong className="verde">{t('impact.motivate7-header')}</strong></p>
                                        {t('impact.motivate7-body')}
                                        <Form.Group>
                                            <Form.Control 
                                                as="textarea"
                                                className="form-control form-control-verde"
                                                rows='6'                                                
                                                name='lifeproject'
                                                value={lifeproject}
                                                onChange={(e) => setLifeproject(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Row>
                            <Col className='text-end my-3'>
                                <Button variant='primary' type='buttom' onClick={savePersonal}>
                                    {t('global.save')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Table>
                                <thead>
                                    <tr className='verde text-center'>
                                        <th><strong>{t('impact.virtue')}</strong></th>
                                        <th><strong>{t('impact.satisfaction')}</strong></th>
                                        <th><strong>{t('impact.peoplefavourite')}</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hardVirtues?.map((virtue, id)=>(
                                        <tr key={id} className='p-3'>
                                            <td className='text-center'>{t('virtues.'+virtue)}</td>
                                            <td>{t('impact.virtuesfavourites.satisfaction.'+virtue)}</td>
                                            <td>{t('impact.virtuesfavourites.peoplefavourite.'+virtue)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Button onClick={() => setModalShow(false)}>{t('prospect.close')}</Button>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </Container>
    </>
    )
}