import React, { useState } from 'react'
import {Container,Row,Col,Button,Form} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom';
import useAuth from "../../context/auth/useAuth"

const clientCredentials = {};

export default function Login() {    
    const { t } = useTranslation()    
    const [islogging, setIslogging] = useState(false)
    const {loginClient} = useAuth();

    const handleSubmit = async(e) => {
        e.preventDefault()        
        setIslogging(true)   
        loginClient(clientCredentials)
    }

    return (
    <>
        
        <Container fluid className='login d-flex align-items-center'>
            <div className='bloque-login'>
                <Row>
                    <Col className='mx-auto text-center mb-5'>
                        <a href="/">
                            <img
                                src="/images/logotipo.png"
                                width="250"
                                className="d-inline-block align-top justify-center"
                                alt="Logotipo MIRACO"
                            />
                        </a>
                    </Col>
                </Row>
                <Row className='px-3 px-md-5 cuadro'>
                    <Col className='mx-auto'>
                        <Form className="form p-4" onSubmit={ handleSubmit} id="login">
                            <div className="text-center mb-5">
                                <h1 className="mb-3">{t('signin.signin')}</h1>
                            </div>
                            <div className="fv-row mb-3">
                                <Form.Label className="form-label">{t('signin.username')}</Form.Label>
                                <Form.Control placeholder={t('signin.username')} name="login_username" className="form-control-lg form-control-solid" type="text" autoComplete="off" onChange={ (e) => clientCredentials.username = e.target.value} />
                            </div>
                            <div className="fv-row mb-2">
                                <div>
                                    <Form.Label className="form-label">{t('signin.password')}</Form.Label>
                                </div>
                                <Form.Control type="password" autoComplete="off" name="login_password" className="form-control-lg form-control-solid" onChange={ (e) => clientCredentials.password = e.target.value} />
                            </div>
                            <div className="text-center">
                                <Button type="submit" className="btn btn-primary btn-lg w-100 my-3 text-uppercase">
                                {islogging
                                    ?
                                    <span className="indicator-label">
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                    :
                                    <span className="indicator-label">{t('signin.login')}</span>
                                }
                                </Button>
                                <div className="text-center">
                                    <Link to="/auth/forgotpassword" className='fs-6'>{t('signin.forgotpassword')}</Link>
                                </div>
                            </div>
                        </Form>
                        <div className='logo-spana'>
                            <img
                                src="/images/logos-espana.png"
                                width="370"
                                className="d-inline-block align-top justify-center mb-4 mt-2"
                                alt=""
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </>
    )
}
