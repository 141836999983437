export const usersReducer = (state = {loading:true,result:null,users:null,user:null} , action) => {
    switch (action.type) {
        case 'USER_SEND_SUCCESS':
            return {
                ...state,
                loading:false,
                result: action.payload
            }
        case 'USERS_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                users: action.payload
            }
        case 'USER_GET_DETAIL_SUCCESS':
            return {
                ...state,
                loading:false,
                user: action.payload
            }
        case 'USER_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'USER_ERROR':
            return {
                ...state,
                loading:false
            }
        case 'USER_DELETE_SUCCESS':
            return {...state,
                loading:false,
                result: action.payload.message,
                users: state.users.filter(users => users._id !== action.payload.idUser)
            }
        case 'USER_DELETE_NOT_SUCCESS':
            return {...state,
                loading: false,
                result: action.payload
            }
        default:
            return state
    }
}

