import Axios  from "axios"

export const sendProfessionalTest = (professionaldata) => async (dispatch) => {
    dispatch({
        type: 'PROFESSIONAL_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.put(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/impact/professional/edit', professionaldata, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'PROFESSIONAL_TEST_SEND_SUCCESS',
                payload: data.message
            }) 
        }

    } catch (error) {
        dispatch({
            type: 'PROFESSIONAL_TEST_ERROR'
        })         
    }
}

export const getProfessionalTest = (user) => async (dispatch) => {
    dispatch({
        type: 'PROFESSIONAL_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/impact/professional/get/' + user, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'PROFESSIONAL_TEST_GET_SUCCESS',
                payload: data.professional
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROFESSIONAL_TEST_ERROR'
        })
    }
}