export const accessReducer = (state = {loading:true,result:null,access:[]} , action) => {
    switch (action.type) {
        case 'ACCESS_SEND_SUCCESS':
            return {
                ...state,
                loading:false,
                result: action.payload
            }
        case 'ACCESS_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                access: action.payload
            }
        default:
            return state
    }
}

