export const personalTestReducer = (state = {loading:true,result:null,personalTest:null} , action) => {
    switch (action.type) {
        case 'PERSONAL_TEST_SEND_SUCCESS':
            return {
                ...state,
                loading:false,
                result: action.payload
            }
        case 'PERSONAL_TEST_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                personalTest: action.payload
            }
        case 'PERSONAL_TEST_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'PERSONAL_TEST_ERROR':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}

