import { createContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode'
import Axios from 'axios'

import Swal from 'sweetalert2'
import { useDispatch } from "react-redux";

export const AuthContext = createContext();

export default function AuthProvider({children}) {
    const navigate = useNavigate()

    let token = sessionStorage.getItem('jwt')
    // let token_decode = []

    // const jwt = require('jsonwebtoken')

    const [client, setClient] = useState(null);
    const dispatch = useDispatch()
    
    /**
     * Loguea un usuario y genera el token
     * @param {*} clientCredentials 
     */
    const loginClient = async (clientCredentials) => {  
        const options = {
            headers: { "Access-Control-Allow-Origin": "*" },
        };
        const result = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/login/in', { username: clientCredentials.username, password: clientCredentials.password },options) 
        sessionStorage.setItem('jwt', result.data['token'])
        token = sessionStorage.getItem('jwt')
        
        if (token!=='undefined') {
            // token_decode = jwtDecode(token, { header: true })            
            setClient(jwtDecode(token));
            let test = jwtDecode(token).tests
            if(test[0]==='all'){
                navigate('/admin/dashboard')
            }else{
                navigate('/client/profile')
            }
        } else {
            // Mostramos el popup informativo            
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text: 'Credenciales incorrectas'
            }).then(()=>{
                window.location.reload(false); 
            })           
        }                 
    }

    /**
     * Desloguea un usuario eliminando token y seteando el usuario a null
     */
    const logout = () => {        
        setClient(null);
        dispatch({ type: 'USER_LOGOUT'})
        sessionStorage.removeItem('jwt')   
        navigate('/')         
    }
        

    /**
     * Comprueba el estado del usuario y devuelve true o false
     */
     const isLogged = () => !!client;

    

    /**
     * Usamos el useEffect para comprobar que el token no ha cambiado
     */
    // token = sessionStorage.getItem('jwt')
    useEffect(() =>{        
        try {                        
            setClient(jwtDecode(token))
        } catch (error) {
            setClient(null);
            sessionStorage.removeItem('jwt')            
        }            
    }, [token]);

    /**
     * Variables accesibles desde cualquier componente
     */
    const contextValue = {
        client,
        isLogged,
        loginClient, 
        logout
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}
