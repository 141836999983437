import { Document, Page, Text, View, Image, StyleSheet} from "@react-pdf/renderer"
import { useTranslation } from "react-i18next"


export default function ManifestReport(info){
    const { t } = useTranslation()  
	let styles = StyleSheet.create({
		pagina: {			
			paddingTop: 20,
			paddingRight: 30,
			paddingLeft: 30,
			paddingBottom: 20,
			fontSize: 18,
			fontFamily: 'Helvetica'
		},
		cabeceraPagina:{
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			marginBottom: 30 
		},	
		image:{
			flex:1,	
			maxWidth: 150
		},
		titulo: {
			flex: 1,
			fontSize: 16,
			fontFamily: 'Helvetica-Bold',
			color: "#24B8B0",
			marginBottom: 20,
            textAlign: 'right'
		},
		titulo2: {
			borderWidth: 1,
			borderColor: "#24B8B0",
			borderRadius: 40,
			color: "#24B8B0",
			fontSize: 16,
			fontFamily: 'Helvetica-Bold',
			marginBottom: 20,	
			paddingVertical: 10,
			paddingHorizontal: 20,
			width: 250
		},
        texto : {
            fontSize: 12,
			fontFamily: 'Helvetica',
			color: "#24B8B0"			
        },
		caja: {
			borderWidth: 1,
			borderColor: "#24B8B0",
			borderRadius: 20,
            marginHorizontal: 5,
			marginVertical:5,
			paddingHorizontal: 20,
			paddingVertical:20,
		},
		virtud: {
			fontSize: 12,
			fontFamily: 'Helvetica-Bold',		
			color: "#24B8B0",
			marginBottom: 5,
            marginHorizontal: 5,
            textAlign: 'center',  
            textTransform: 'capitalize'          
		},
		datoTitulo: {
			color: "#24B8B0",
			flex: 1,
			fontFamily: 'Helvetica-Bold',
			fontSize: 10,
			paddingRight: 10,
			paddingVertical: 5,
			marginBottom: 5	
		},		
		dato: {
			color: "#24B8B0",
			flex: 1,
			fontFamily: 'Helvetica',
			fontSize: 10,
			paddingLeft: 10,
			paddingVertical: 5,
			marginBottom: 10	
		},		
		datosin: {
			color: "#c2c2c2",
			flex: 1,
			fontFamily: 'Helvetica',
			textDecorationStyle: 'italic',
			fontSize: 10,
			paddingLeft: 10,
			paddingVertical: 5,
			marginBottom: 10	
		},		
		pageNumber: {
			position: 'absolute',
			fontSize: 10,
			bottom: 15,
			left: 0,
			right: 0,
			textAlign: 'center',
			color: 'grey',
		  },

	})

	return (
		<Document>
            <Page size="A4" style={styles.pagina}>
				<View>
					<View style={styles.cabeceraPagina} fixed>
						<Image style={styles.image} src="/images/logotipo-fondo-claro.png"/>
						<View style={styles.titulo}>	
							<Text>{t('manifest.alert')}</Text>
						</View>						
					</View>    
					<View>						
						<View style={styles.caja}>
							<Text style={styles.titulo2}>{t('manifest.virtues-weak')}</Text>
							<View>
								{info.weakVirtues?.map((virtue, id)=> {	
									return(
										<View key={id}>
											<Text style={styles.datoTitulo}>{t('virtues.'+virtue)}</Text>
											{info.manifestVirtues?.[virtue+'Manifest'].length > 0
												?
												<Text style={styles.dato}>{info.manifestVirtues?.[virtue+'Manifest']}</Text>
												:
												<Text style={styles.datosin}>{t('manifest.nomanifest')}</Text>
											}
										</View>
									)
								})}
							</View>
						</View>  
						<View style={styles.caja} wrap={false}>
							<Text style={styles.titulo2}>{t('manifest.virtues-hard')}</Text>
							<View>
								{info.hardVirtues?.map((virtue, id)=> {	
									return(
										<View key={id}>
											<Text style={styles.datoTitulo}>{t('virtues.'+virtue)}</Text>
											{info.manifestVirtues?.[virtue+'Manifest'].length > 0
												?
												<Text style={styles.dato}>{info.manifestVirtues?.[virtue+'Manifest']}</Text>
												:
												<Text style={styles.datosin}>{t('manifest.nomanifest')}</Text>
											}
										</View>
									)
								})}
							</View>
						</View>  
						<View style={styles.caja} wrap={false}>
							<Text style={styles.titulo2}>{t('manifest.virtues-medium')}</Text>
							<View>
								{info.mediumVirtues?.map((virtue, id)=> {	
									return(
										<View key={id}>
											<Text style={styles.datoTitulo}>{t('virtues.'+virtue)}</Text>
											{info.manifestVirtues?.[virtue+'Manifest'].length > 0
												?
												<Text style={styles.dato}>{info.manifestVirtues?.[virtue+'Manifest']}</Text>
												:
												<Text style={styles.datosin}>{t('manifest.nomanifest')}</Text>
											}
										</View>
									)
								})}
							</View>
						</View>                        
					</View>                
				</View>
				<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        			`${pageNumber} / ${totalPages}`
      			)} fixed />
            </Page>
		</Document>
	)
}