import Axios  from "axios"

export const sendWeakTest = (responses,user) => async (dispatch) => {
    dispatch({
        type: 'WEAK_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.post(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/conscience/weak/register', {
                responses: responses,
                user: user
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )      
        if(status===200){            
            dispatch({
                type: 'WEAK_TEST_SEND_SUCCESS',
                payload: data.message
            }) 
        }

    } catch (error) {
        dispatch({
            type: 'WEAK_TEST_ERROR'           
        })         
    }
}

export const getWeakTest = (user) => async (dispatch) => {
    dispatch({
        type: 'WEAK_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/conscience/weak/get/'+ user, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )              
        if(status===200){            
            dispatch({
                type: 'WEAK_TEST_GET_SUCCESS',
                payload: data.weak
            })             
        }

    } catch (error) {
        dispatch({
            type: 'WEAK_TEST_ERROR'           
        })         
    }
}