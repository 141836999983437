import React,{Fragment, useEffect, useState} from 'react'
import { useTranslation } from "react-i18next"
import { Row, Col, Tabs, Tab, Container,Navbar,Nav, Button, Form, Table, Card, Popover, OverlayTrigger} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../context/auth/useAuth'
import { getAllWeeklyTest } from '../../actions/weeklyTestActions'
import { useParams } from 'react-router-dom'
import { getUserDetail } from '../../actions/usersActions'
import { getAllEvaluationTest } from '../../actions/evaluationTestActions'
import { getUservirtue } from '../../actions/uservirtueActions'
import { getAllTrainingTest } from '../../actions/trainingTestActions'
import { editUservirtue } from '../../actions/uservirtueActions'

export default function BlockOne() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
    const {id} = useParams()
	const {client} = useAuth()

	const {tests} = useSelector(state=>state.weeklyTest)
    const {user} = useSelector(state=>state.users)
    const {tests: evaluation} = useSelector(state=>state.evaluationTest)
    const {uservirtue} = useSelector(state=>state.uservirtue)
    const {exercises} = useSelector(state=>state.trainingTest)

    const [displayElements, setDisplayElements] = useState(false)
    const [weakVirtues, setWeakVirtues] = useState([])
    const [mediumVirtues, setMediumVirtues] = useState([])
    const [hardVirtues, setHardVirtues] = useState([])
    
    const [allVirtuesState, setAllVirtuesState] = useState([[], [], [], [], [], [], [], [], [], [], [], []])
    const [allVirtuesExercises, setAllVirtuesExercises] = useState({
        'aceptacion': [], 'admiracion': [], 'alegria': [], 'compania': [], 'compasion': [], 'dignidad': [], 'disciplina': [],
        'educacion': [], 'generosidad': [], 'guia': [], 'humildad': [], 'identidad': [], 'inspiracion': [], 'justicia': [],
        'lealtad': [], 'libertad': [], 'paciencia': [], 'perdon': [], 'perseverancia': [], 'proteccion': [], 'responsabilidad': [],
        'sabiduria': [], 'valentia': [],
    })

	useEffect(() => {
		if(client?.id){
			dispatch(getAllWeeklyTest(id))
            dispatch(getUserDetail(id))
            dispatch(getAllEvaluationTest(id))
            dispatch(getUservirtue(id))
            dispatch(getAllTrainingTest(id))
		}
	}, [dispatch, client, id])

    useEffect(() => {
        if(uservirtue){
            let weakVirtuesArray = []
            let mediumVirtuesArray = []
            let hardVirtuesArray = []

            for (const virtue in uservirtue) {
                if(virtue !== '_id' && virtue !== '__v' && virtue !== 'user'){
                    if(uservirtue[virtue] === 'debil'){
                        weakVirtuesArray.push(virtue)
                    }
                    if(uservirtue[virtue] === 'media'){
                        mediumVirtuesArray.push(virtue)
                    }
                    if(uservirtue[virtue] === 'fuerte'){
                        hardVirtuesArray.push(virtue)
                    }
                }
            }

            setWeakVirtues(weakVirtuesArray)
            setMediumVirtues(mediumVirtuesArray)
            setHardVirtues(hardVirtuesArray)
        }
    }, [uservirtue])

    useEffect(() => {
        if(evaluation){
            let arrayweakVirtuesCrisis = []
            let arrayweakVirtuesChallenge = []
            let arrayweakVirtuesFlow = []
            let arrayweakVirtuesWow = []

            let arraymediumVirtuesCrisis = []
            let arraymediumVirtuesChallenge = []
            let arraymediumVirtuesFlow = []
            let arraymediumVirtuesWow = []

            let arrayhardVirtuesCrisis = []
            let arrayhardVirtuesChallenge = []
            let arrayhardVirtuesFlow = []
            let arrayhardVirtuesWow = []
            
            evaluation?.map((test, itest) => {
                if(itest === 1){
                    weakVirtues?.map((virtue) => {
                        if(test[virtue] === 'diario'){
                            arrayweakVirtuesCrisis.push(virtue)
                        }
                        if(test[virtue] === 'algunos'){
                            arrayweakVirtuesChallenge.push(virtue)
                        }
                        if(test[virtue] === 'pocos'){
                            arrayweakVirtuesFlow.push(virtue)
                        }
                        if(test[virtue] === 'ninguno'){
                            arrayweakVirtuesWow.push(virtue)
                        }
    
                        return true
                    })
    
                    mediumVirtues?.map((virtue) => {
                        if(test[virtue] === 'diario'){
                            arraymediumVirtuesCrisis.push(virtue)
                        }
                        if(test[virtue] === 'algunos'){
                            arraymediumVirtuesChallenge.push(virtue)
                        }
                        if(test[virtue] === 'pocos'){
                            arraymediumVirtuesFlow.push(virtue)
                        }
                        if(test[virtue] === 'ninguno'){
                            arraymediumVirtuesWow.push(virtue)
                        }
    
                        return true
                    })
    
                    hardVirtues?.map((virtue) => {
                        if(test[virtue] === 'diario'){
                            arrayhardVirtuesCrisis.push(virtue)
                        }
                        if(test[virtue] === 'algunos'){
                            arrayhardVirtuesChallenge.push(virtue)
                        }
                        if(test[virtue] === 'pocos'){
                            arrayhardVirtuesFlow.push(virtue)
                        }
                        if(test[virtue] === 'ninguno'){
                            arrayhardVirtuesWow.push(virtue)
                        }
    
                        return true
                    })
                }

                return true
            })

            setAllVirtuesState([
                arrayweakVirtuesCrisis,
                arrayweakVirtuesChallenge,
                arrayweakVirtuesFlow,
                arrayweakVirtuesWow,
                arraymediumVirtuesCrisis,
                arraymediumVirtuesChallenge,
                arraymediumVirtuesFlow,
                arraymediumVirtuesWow,
                arrayhardVirtuesCrisis,
                arrayhardVirtuesChallenge,
                arrayhardVirtuesFlow,
                arrayhardVirtuesWow
            ])
        }
    }, [evaluation, weakVirtues, mediumVirtues, hardVirtues])

    useEffect(() => {
        if(exercises){
            saveExercises()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exercises])

    const saveExercises = async() => {
        let allVirtuesExercisesCopy = {
            'aceptacion': [],
            'admiracion': [],
            'alegria': [],
            'compania': [],
            'compasion': [],
            'dignidad': [],
            'disciplina': [],
            'educacion': [],
            'generosidad': [],
            'guia': [],
            'humildad': [],
            'identidad': [],
            'inspiracion': [],
            'justicia': [],
            'lealtad': [],
            'libertad': [],
            'paciencia': [],
            'perdon': [],
            'perseverancia': [],
            'proteccion': [],
            'responsabilidad': [],
            'sabiduria': [],
            'valentia': [],
        }

        let dateMax = null
        Promise.all(await exercises?.map(async(exercise) => {
            if(dateMax === null || dateMax < new Date(exercise.date)){
                dateMax = new Date(exercise.date).getTime()
            }
            if(exercise.done !== true || exercise.favourite === true){
                if(allVirtuesExercisesCopy[exercise.virtue]){
                    if(!allVirtuesExercisesCopy[exercise.virtue]?.find(exercisesFind => exercisesFind._id === exercise._id)){
                        allVirtuesExercisesCopy = {...allVirtuesExercisesCopy, [exercise.virtue]: [...allVirtuesExercisesCopy[exercise.virtue], exercise]}
                    }
                }else{
                    allVirtuesExercisesCopy = {...allVirtuesExercisesCopy, [exercise.virtue]: [exercise]}
                }
            }

            return true
        })).then(
            setAllVirtuesExercises(allVirtuesExercisesCopy)
        )
    }

    const changeTypeVirtue = (e, virtue) =>{
        dispatch(editUservirtue(id, virtue, e.target.value))
    }

    return (
        <Container className='cuerpo'>
            <Button className="float-end" variant="outline-success" href={`/admin/users`}>
                <i className='fas fa-angle-left'></i> {t('global.gotousers')}
            </Button>
            <h1>{t('admin.user.user')}:&nbsp;<span className='text-uppercase'>{user?.firstname} {user?.lastname}</span></h1>
            <Navbar  className="bg-gray my-3">
                <Container>                    
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">                        
                        <Nav.Link href={`/admin/bloqueuno/`+id}>{t('global.phase')} 1</Nav.Link>
                        <Nav.Link href={`/admin/bloquedos/`+id}>{t('global.phase')} 2</Nav.Link>
                        <Nav.Link href={`/admin/bloquetres/`+id}>{t('global.phase')} 3</Nav.Link>
                        <Nav.Link href={`/admin/user/`+id}>{t('global.data')}</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Tabs defaultActiveKey="virtues" id="datos-usuarios" className="mb-3 datos-usuarios">
                <Tab eventKey="virtues" title={t('admin.virtues')}>
                    <Row className='pt-5 pb-4'>
                        <Col xs="auto" className='px-4 pb-3'>
                            <div className="alert auto alert-success text-center" role="alert">
                                <h3 className='m-0 text-white'>{t('virtuestype.weak')}</h3>
                            </div>
                        </Col>
                    </Row>
                    {weakVirtues?.map((virtue, ivirtue) => (
                        <Row key={ivirtue}>
                            <Col xs={3}>
                                <button className="alert alert-info popovers d-flex justify-content-between align-items-center" data-toggle="popover">
                                    {t('virtues.'+virtue)}
                                </button>
                            </Col>
                            <Col xs={3}>
                                <Form.Control
                                    as='select'
                                    className='btn form-control form-control-verde text-start'
                                    name={`select-${virtue}`}
                                    value='debil'
                                    onChange={(e) => changeTypeVirtue(e, virtue)}
                                >
                                    <option value='debil'>{t('bloquedos.weak')}</option>
                                    <option value='media'>{t('bloquedos.medium')}</option>
                                    <option value='fuerte'>{t('bloquedos.hard')}</option>
                                </Form.Control>
                            </Col>
                        </Row>
                    ))}
                    <Row className='pt-5 pb-4'>
                        <Col xs="auto" className='px-4 pb-3'>
                            <div className="alert auto alert-success text-center" role="alert">
                                <h3 className='m-0 text-white'>{t('virtuestype.medium')}</h3>
                            </div>
                        </Col>
                    </Row>
                    {mediumVirtues?.map((virtue, ivirtue) => (
                        <Row key={ivirtue}>
                            <Col xs={3}>
                                <button className="alert alert-info popovers d-flex justify-content-between align-items-center" data-toggle="popover">
                                    {t('virtues.'+virtue)}
                                </button>
                            </Col>
                            <Col xs={3}>
                                <Form.Control
                                    as='select'
                                    className='btn form-control form-control-verde text-start'
                                    name={`select-${virtue}`}
                                    value='media'
                                    onChange={(e) => changeTypeVirtue(e, virtue)}
                                >
                                    <option value='debil'>{t('bloquedos.weak')}</option>
                                    <option value='media'>{t('bloquedos.medium')}</option>
                                    <option value='fuerte'>{t('bloquedos.hard')}</option>
                                </Form.Control>
                            </Col>
                        </Row>
                    ))}
                    <Row className='pt-5 pb-4'>
                        <Col xs="auto" className='px-4 pb-3'>
                            <div className="alert auto alert-success text-center" role="alert">
                                <h3 className='m-0 text-white'>{t('virtuestype.hard')}</h3>
                            </div>
                        </Col>
                    </Row>
                    {hardVirtues?.map((virtue, ivirtue) => (
                        <Row key={ivirtue}>
                            <Col xs={3}>
                                <button className="alert alert-info popovers d-flex justify-content-between align-items-center" data-toggle="popover">
                                    {t('virtues.'+virtue)}
                                </button>
                            </Col>
                            <Col xs={3}>
                                <Form.Control
                                    as='select'
                                    className='btn form-control form-control-verde text-start'
                                    name={`select-${virtue}`}
                                    value='fuerte'
                                    onChange={(e) => changeTypeVirtue(e, virtue)}
                                >
                                    <option value='debil'>{t('bloquedos.weak')}</option>
                                    <option value='media'>{t('bloquedos.medium')}</option>
                                    <option value='fuerte'>{t('bloquedos.hard')}</option>
                                </Form.Control>
                            </Col>
                        </Row>
                    ))}
                </Tab>
                <Tab eventKey="weekly" title={t('admin.weekly')}>
                    <Row className="px-5">
                        <Col className='text-start my-3'>
                            <Card className="my-4">
                                <Card.Body>
                                    <div>
                                        {tests
                                            ?
                                                [1,2,3,4,5,6,7,8].map((number, keynumber) => (
                                                    tests[tests?.length-number]
                                                        ?
                                                            <Row key={keynumber} className='align-items-center mx-5' number={number}>
                                                                <Col className='text-start mx-5'>
                                                                    <label className="texto-fechas">{ new Date(Date.parse(tests[tests?.length-number].date)).getDate()<10 ? '0'+new Date(Date.parse(tests[tests?.length-number].date)).getDate() : new Date(Date.parse(tests[tests?.length-number].date)).getDate()}/{ new Date(Date.parse(tests[tests?.length-number].date)).getMonth()+1<9 ? '0'+parseInt(new Date(Date.parse(tests[tests?.length-number].date)).getMonth()+1) : parseInt(new Date(Date.parse(tests[tests?.length-number].date)).getMonth()+1)}/{ new Date(Date.parse(tests[tests?.length-number].date)).getFullYear()}</label>
                                                                </Col>
                                                                <Col className='text-end mx-5'>
                                                                    {tests[tests?.length-number].result === 'crisis' ? 
                                                                        <Col className='pb-2 d-flex align-items-center justify-content-end'>
                                                                            <div className={"img-fluid bg-image-crisis"} alt="" width="94"/>
                                                                            <h5 className={'text-uppercase crisis-texto'}>{t('weekly.crisis')}</h5>
                                                                        </Col>
                                                                    : 
                                                                        <></>
                                                                    }
                                                                    {tests[tests?.length-number].result === 'challenge' ? 
                                                                        <Col className='pb-2 d-flex align-items-center justify-content-end'>
                                                                            <div className={"img-fluid bg-image-challenge"} alt="" width="94"/>
                                                                            <h5 className={'text-uppercase challenge-texto'}>{t('weekly.challenge')}</h5>
                                                                        </Col>
                                                                    :
                                                                        <></>
                                                                    }
                                                                    {tests[tests?.length-number].result === 'flow' ? 
                                                                        <Col className='pb-2 d-flex align-items-center justify-content-end'>
                                                                            <div className={"img-fluid bg-image-flow"} alt="" width="94"/>
                                                                            <h5 className={'text-uppercase flow-texto'}>{t('weekly.flow')}</h5>
                                                                        </Col>
                                                                    :
                                                                        <></>
                                                                    }
                                                                    {tests[tests?.length-number].result === 'wow' ? 
                                                                        <Col className='pb-2 d-flex align-items-center justify-content-end'>
                                                                            <div className={"img-fluid bg-image-wow"} alt="" width="94"/>
                                                                            <h5 className={'text-uppercase wow-texto'}>{t('weekly.wow')}</h5>
                                                                        </Col>
                                                                    :
                                                                        <></>
                                                                    }
                                                                </Col>
                                                                <hr/>
                                                            </Row>
                                                        :
                                                            <Fragment key={keynumber}></Fragment>
                                                ))
                                            :
                                                <></>
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="evaluation" title={t('admin.evaluation')}>
                    <Row className='pt-5 pb-4'>
                        <Col xs="auto" className='px-4 pb-3'>
                            <div className="alert auto alert-success text-center" role="alert">
                                <h3 className='m-0 text-white'>{t('virtuestype.weak')}</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5 pb-4'>
                        <div className='text-end'>
                            <span className='btn-vacio' hidden={displayElements} onClick={() => setDisplayElements(true)}>
                                <i className='fas fa-chevron-right'></i>
                            </span>
                            <span className='btn-vacio' hidden={!displayElements} onClick={() => setDisplayElements(false)}>
                                <i className='fas fa-chevron-left'></i>
                            </span>
                        </div>
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    {evaluation?.map((test, itest) => (
                                        <th key={itest} hidden={itest > 0 && itest < 7 ? displayElements : itest > 0 ? !displayElements : false}>{test.date}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {weakVirtues?.map((virtue, ivirtue) => (
                                    <tr key={ivirtue}>
                                        <td key={ivirtue} className='columnavirtudes'>
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="right"
                                                overlay={
                                                    <Popover>
                                                        <Popover.Header as="h3">{t('virtuetraining.comoentrenar')} {t('virtues.'+virtue)}</Popover.Header>
                                                        <Popover.Body>
                                                            {t('virtuetraining.'+virtue)}
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <button type="button" className="alert alert-info mt-3 popovers d-flex justify-content-between align-items-center" data-toggle="popover">
                                                    {t('virtues.'+virtue)}
                                                    <i className='fas fa-plus'></i>
                                                </button>
                                            </OverlayTrigger>
                                        </td>
                                        {evaluation?.map((test, itest) => (
                                            <td key={itest} className={`columna${itest} ${itest === 0 ? 'td-bg-grisclaro' : ''}`} hidden={itest > 0 && itest < 7 ? displayElements : itest > 0 ? !displayElements : false}>
                                                <div className={`bola-${test[virtue]}`}></div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                    <Row className='pt-5 pb-4'>
                        <Col xs="auto" className='px-4 pb-3'>
                            <div className="alert auto alert-success text-center" role="alert">
                                <h3 className='m-0 text-white'>{t('virtuestype.medium')}</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5 pb-4'>
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    {evaluation?.map((test, itest) => (
                                        <th key={itest} hidden={itest > 0 && itest < 7 ? displayElements : itest > 0 ? !displayElements : false}>{test.date}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {mediumVirtues?.map((virtue, ivirtue) => (
                                    <tr key={ivirtue}>
                                        <td key={ivirtue} className='columnavirtudes'>
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="right"
                                                overlay={
                                                    <Popover>
                                                        <Popover.Header as="h3">{t('virtuetraining.comoentrenar')} {t('virtues.'+virtue)}</Popover.Header>
                                                        <Popover.Body>
                                                            {t('virtuetraining.'+virtue)}
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <button type="button" className="alert alert-info mt-3 popovers d-flex justify-content-between align-items-center" data-toggle="popover">
                                                    {t('virtues.'+virtue)}
                                                    <i className='fas fa-plus'></i>
                                                </button>
                                            </OverlayTrigger>
                                        </td>
                                        {evaluation?.map((test, itest) => (
                                            <td key={itest} className={`columna${itest} ${itest === 0 ? 'td-bg-grisclaro' : ''}`} hidden={itest > 0 && itest < 7 ? displayElements : itest > 0 ? !displayElements : false}>
                                                <div className={`bola-${test[virtue]}`}></div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                    <Row className='pt-5 pb-4'>
                        <Col xs="auto" className='px-4 pb-3'>
                            <div className="alert auto alert-success text-center" role="alert">
                                <h3 className='m-0 text-white'>{t('virtuestype.hard')}</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5 pb-4'>
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    {evaluation?.map((test, itest) => (
                                        <th key={itest} hidden={itest > 0 && itest < 7 ? displayElements : itest > 0 ? !displayElements : false}>{test.date}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {hardVirtues?.map((virtue, ivirtue) => (
                                    <tr key={ivirtue}>
                                        <td key={ivirtue} className='columnavirtudes'>
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="right"
                                                overlay={
                                                    <Popover>
                                                        <Popover.Header as="h3">{t('virtuetraining.comoentrenar')} {t('virtues.'+virtue)}</Popover.Header>
                                                        <Popover.Body>
                                                            {t('virtuetraining.'+virtue)}
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <button type="button" className="alert alert-info mt-3 popovers d-flex justify-content-between align-items-center" data-toggle="popover">
                                                    {t('virtues.'+virtue)}
                                                    <i className='fas fa-plus'></i>
                                                </button>
                                            </OverlayTrigger>
                                        </td>
                                        {evaluation?.map((test, itest) => (
                                            <td key={itest} className={`columna${itest} ${itest === 0 ? 'td-bg-grisclaro' : ''}`} hidden={itest > 0 && itest < 7 ? displayElements : itest > 0 ? !displayElements : false}>
                                                <div className={`bola-${test[virtue]}`}></div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                </Tab>
                <Tab eventKey="training" title={t('admin.training')}>
                    <Row className='pt-5 pb-4'>
                        {allVirtuesState?.map((typevirtue, itypevirtue) => (
                            <Fragment key={itypevirtue}>
                                {itypevirtue === 0 || itypevirtue === 4 || itypevirtue === 8 
                                    ?
                                        <Row xs="auto" className='px-4 pb-3'>
                                            <div className="alert auto alert-success text-center" role="alert">
                                                {itypevirtue === 0
                                                    ?
                                                        <>
                                                            <h3 className='m-0 text-white'>{t('virtuestype.weak')}</h3>
                                                        </>
                                                    :
                                                        <></>
                                                }
                                                {itypevirtue === 4
                                                    ?
                                                        <>
                                                            <h3 className='m-0 text-white'>{t('virtuestype.medium')}</h3>
                                                        </>
                                                    :
                                                        <></>
                                                }
                                                {itypevirtue === 8
                                                    ?
                                                        <>
                                                            <h3 className='m-0 text-white'>{t('virtuestype.hard')}</h3>
                                                        </>
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        </Row>
                                    :
                                        <></>
                                }
                                {typevirtue.length > 0 && typevirtue?.map((virtue, ivirtue) => (
                                    <Fragment key={ivirtue}>
                                        <Row>
                                            <Col className='d-flex align-items-center'>
                                                <OverlayTrigger trigger="click" placement="right"
                                                    overlay={
                                                        <Popover>
                                                            <Popover.Header as="h3">{t('virtuetraining.comoentrenar')} {t('virtues.'+virtue)}</Popover.Header>
                                                            <Popover.Body>{t('virtuetraining.'+virtue)}</Popover.Body>
                                                        </Popover>
                                                    }>
                                                    <button type="button" className="alert alert-info mt-3 me-3 popovers d-flex justify-content-between align-items-center popover-training" data-toggle="popover">
                                                        {t('virtues.'+virtue)}
                                                        <i className='fas fa-plus ms-5'></i>
                                                    </button>
                                                </OverlayTrigger>
                                                {itypevirtue === 0 || itypevirtue === 4 || itypevirtue === 8 ? <div className='bola-diario bola-mx-auto'></div> : <></>}
                                                {itypevirtue === 1 || itypevirtue === 5 || itypevirtue === 9 ? <div className='bola-algunos bola-mx-auto'></div> : <></>}
                                                {itypevirtue === 2 || itypevirtue === 6 || itypevirtue === 10 ? <div className='bola-pocos bola-mx-auto'></div> : <></>}
                                                {itypevirtue === 3 || itypevirtue === 7 || itypevirtue === 11 ? <div className='bola-ninguno bola-mx-auto'></div> : <></>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th className='text-center'>{t('training.exercise')}</th>
                                                        <th className='text-center'>{t('training.level')}</th>
                                                        <th className='text-center'>{t('training.frequency')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allVirtuesExercises[virtue]?.length > 0 && allVirtuesExercises[virtue]?.map((exercise, iexercise) => (
                                                        <tr key={iexercise}>
                                                            <td className='text-center'>
                                                                <label>{exercise.favourite === true ? <i className="fas fa-star"></i> : <></>}</label>
                                                            </td>
                                                            <td>
                                                                <label>{exercise.description}</label>
                                                            </td>
                                                            <td className='text-center'>
                                                                <label>{t('training.'+exercise.level)}</label>
                                                            </td>
                                                            <td className='text-center'>
                                                                <label>{t('training.'+exercise.frequency)}</label>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Row>
                                    </Fragment>
                                ))}
                            </Fragment>
                        ))}
                    </Row>
                </Tab>
            </Tabs>
        </Container>
    )
}
