import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {Container,Row,Col,Button,Carousel} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import Menu from '../../components/menu-general'
import { Footer } from '../../components/footer';

export const Landing = () => {
    const { t } = useTranslation()  	
	const [color, setColor] = useState('transparent')
	window.addEventListener('scroll', function(e) {
	  if(this.scrollY>50&&color==='transparent'){      
		setColor('rgba(7, 8, 53, 1)')
	  }else{
		if(this.scrollY<=50&&color==='rgba(7, 8, 53, 1)'){
		  setColor('transparent')
		}
	  }
	});	
	return (
    <>
		<Menu background={color} />
		<Container fluid className='gradient-dark-blue text-center text-md-start pb-5 text-white contenido'> 
        	<Container>
        		<Row className='px-3 px-md-5'>
            		<Col xs="12" md="6" className='ps-lg-5'>
            			<h2 className='mt-5'>{t('landing.inicia')} <span className='cambio'>{t('landing.tuviaje')}</span>, <span className='fino'>{t('landing.condestino')}</span></h2>
            			<p className='py-4'>{t('landing.conoceydesarrolla')}
            			</p>
            		</Col>
            		<Col xs="12" lg="6" className='text-center'>
              			<figure className='mb-5'><img src="/images/ilustracion-avion.png" className='img-fluid' alt='' /></figure>
            		</Col>
          		</Row>
          		<Row className='px-5'>
            		<Col className='ps-lg-5'>
              			<Button variant="primary" className='mb-4' href="/client/profile">{t('landing.iniciarahora')}</Button><br/>
              			<Link to="/auth" className='text-white f14 d-md-none'>{t('landing.ingresacuenta')}</Link>
            		</Col>
          		</Row>
        	</Container>
      	</Container>
      	<Container className='py-5 px-4 text-center'> 
        	<Row className='px-md-5'>
          		<Col>      
            		<figure className='mb-5'><img src="/images/ilustracion-mapa.png" className='img-fluid' alt=''/></figure>
            		<h2 className='mt-5'>{t('landing.enquecon')}<span className='cambio'>{t('landing.liderazgosotenible')}</span>?</h2>
            		<p className='py-4'>{t('landing.consisteen')}
            		</p>              
            		<Link to="/auth" className='f14'>{t('landing.leermas')}</Link>
          		</Col>
        	</Row>
      	</Container>      
      	<Container className='mt-5 py-5 px-4 text-center'> 
        	<Row className='px-md-5'>
          		<Col>         
            		<h2 className='mt-5'>{t('landing.conocetemejor')}<span className='cambio'>{t('landing.nuestrometodo')}</span>.</h2>
            		<p className='py-4'>{t('landing.motiftepermite')}
            		</p>         
          		</Col>
        	</Row>     
        	<Row className='px-5'>
          		<Col xs="12" md="4" className='ps-lg-5'>                 
            		<figure className='my-3'><img src="/images/icono-taller.png" alt=''/></figure>
            		<h4>{t('landing.tallerwebinar')}</h4>
            		<p>3 {t('landing.horas')}.</p>
          		</Col>
          		<Col xs="12" md="4" className='ps-lg-5'>                 
            		<figure className='my-3'><img src="/images/icono-asesoria.png" alt=''/></figure>
            		<h4>{t('landing.asesoriaindividual')}</h4>
            		<p>7 {t('landing.sesiones')}.</p>
          		</Col>
          		<Col xs="12" md="4" className='ps-lg-5'>
            		<figure className='my-3'><img src="/images/icono-asesoriagrupal.png" alt=''/></figure>
            		<h4>{t('landing.asesoriagrupal')}</h4>
            		<p>7 {t('landing.sesiones')}.</p>
          		</Col>
        	</Row>
      	</Container>     
      	<Container  className='py-5 px-4 text-center'> 
        	<Row className='px-md-5'>
          		<Col className='ps-lg-5'>           
            		<figure className='mb-5'><img src="/images/ilustracion-barco.png" alt=''/></figure>
            		<p className='py-4'>
						{t('landing.sinimportarelformato')}
            		</p>              
          		</Col>
        	</Row>
      	</Container>   
      	<Container className='py-5 px-4 text-center'> 
        	<Row className='px-md-5'>
          		<Col className='ps-lg-5'>           
            		<figure className='mb-5'><img src="/images/ilustracion-viaje.png" alt=''/></figure>
            		<h2 className='mt-5'>{t('landing.conocetu')}<span className='cambio'>{t('landing.itinerario')}</span>.</h2>
            		<p className='py-4'>{t('landing.esteviajeincluye')}</p>              
          		</Col>
        	</Row>
        	<Row className="justify-content-center">
          		<Col className='col-12 col-sm-8'>  
            		<Row className='align-content-center'>
              			<Col className='col-auto'>
                			<img src="/images/icono-consciencia.png" alt=''/>
              			</Col>
              			<Col className='text-start align-self-center'>
                			<h4 className="m-0"><span className="cambio">{t('landing.consciencia')}</span></h4>                
              			</Col>
            		</Row>
            		<Row>
              			<Col className='text-start mt-3'>
                			<p>{t('landing.conoceloque')}</p>
              			</Col>  
            		</Row>
          		</Col>
        	</Row>
        	<Row className="justify-content-center">
          		<Col className='col-12 col-sm-8'>  
            		<Row className='align-content-center'>
              			<Col className='col-auto'>
                			<img src="/images/icono-responsabilidad.png" alt=''/>
              			</Col>
              			<Col className='text-start align-self-center'>
                			<h4 className="m-0"><span className="cambio">{t('landing.responsabilidad')}</span></h4>                
              			</Col>            
            		</Row>          
            		<Row>
              			<Col className='text-start mt-3'>
                			<p>{t('landing.construyeelcamino')}</p>
              			</Col>  
            		</Row>
          		</Col>
        	</Row>
        	<Row className="justify-content-center">
          		<Col className='col-12 col-sm-8'>  
            		<Row className='align-content-center'>
              			<Col className='col-auto'>
                			<img src="/images/icono-impacto.png" alt=''/>
              			</Col>
              			<Col className='text-start align-self-center'>
                			<h4 className="m-0"><span className="cambio">{t('landing.impacto')}</span></h4>                
              			</Col>            
            		</Row>          
            		<Row>
              			<Col className='text-start mt-3'>
                			<p>{t('landing.genrauna')}</p>
              			</Col>  
            		</Row>
          		</Col>
        	</Row>  
        	<Row>
          		<Col className='text-center pt-5'>
            		<Button variant="primary" className='mb-4' href="/client/profile">{t('landing.iniciarahora')}</Button><br/>
          		</Col>
        	</Row>            
      	</Container>   
      	<Container fluid className='gradient-light-blue text-center text-md-start py-5 text-white'> 
        	<Container>
          		<Row className='px-md-5 text-center'>
            		<Col className='ps-lg-5'>
						<Carousel variant="dark" controls={false} id="home">
							<Carousel.Item>
								<Carousel.Caption>
								<h3>{t('home.frase1',)} <span>{t('home.frase1b',)}</span></h3>
								<p>{t('home.subfrase1')}</p>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<Carousel.Caption>
								<h3>{t('home.frase1',)} <span>{t('home.frase1b',)}</span></h3>
								<p>{t('home.subfrase1')}</p>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<Carousel.Caption >
								<h3>{t('home.frase1',)} <span>{t('home.frase1b',)}</span></h3>
								<p>{t('home.subfrase1')}</p>
								</Carousel.Caption>
							</Carousel.Item>
						</Carousel>						
					</Col>
				</Row>
			</Container>
		</Container>
      	<Container fluid className='gradient-dark-blue text-center text-md-start pb-5 text-white'> 
        	<Container>
          		<Row className='px-md-5 text-center'>
            		<Col className='ps-lg-5'>
            			<h2 className='mt-5'>{t('landing.aquiempieza')}<span className='cambio'>{t('landing.tuviaje')}</span>,<br/><span className='fino'>{t('landing.dondesiempre')}</span></h2>
            		</Col>            
          		</Row>
          		<Row className='mt-5 pt-5 px-md-5'>
            		<Col xs="12" sm="5" className='text-center'>
              			<figure className='mb-5'><img src="/images/ilustracion-vida.png" className="img-fluid" alt=''/></figure>
            		</Col>            
            		<Col xs="12" sm="7" className='px-5 align-self-center'>
              			<p className='py-4'>{t('landing.alolargo')}
              			</p>
            		</Col>
          		</Row>          
          		<Row className='mt-5 pt-5 px-md-5'>          
            		<Col xs="12" sm="7" className='px-5 align-self-center order-2 order-sm-1'>
              			<p className='py-4'>{t('landing.compartiremoscontigo')}
              			</p>
            		</Col>
            		<Col xs="12" sm="5" className='text-center order-1 order-sm-2'>
              			<figure className='mb-5'><img src="/images/ilustracion-mapa.png" className="img-fluid" alt=''/></figure>
            		</Col>              
          		</Row>  
          		<Row>
            		<Col className='text-center pt-5'>
              			<Button variant="primary" className='mb-4' href="/client/profile">{t('landing.iniciarahora')}</Button><br/>
            		</Col>
          		</Row>             
        	</Container>
      	</Container>            
		<Footer/> 		              
    </>
  )
}
