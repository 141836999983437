import React,{Fragment, useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { Row, Col, Tabs, Tab, Navbar, Nav, Accordion, Container, Button, Form, Table} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getInitialTest } from '../../actions/initialTestActions'
import { getUserDetail } from '../../actions/usersActions'
import { getWeakTest } from '../../actions/weakTestActions'
import { getProspectTest } from '../../actions/prospectTestActions'
import { getMediumhardTest } from '../../actions/mediumhardTestActions'
import { getHarmfulTest } from '../../actions/harmfulTestActions'
import { getAccesses } from '../../actions/accessActions'
import { InitialTableData } from '../../components/tests/initialTableData'
import { Radar } from 'react-chartjs-2'

export default function BlockOne() {
    const { t } = useTranslation()
    const {id} = useParams()
    const dispatch = useDispatch()
    
    const {user} = useSelector(state=>state.users)
    const initialTest = useSelector(state=>state.initialTest)

    const weakTest = useSelector(state=>state.weakTest)
    const [checkedState, setCheckedState] = useState(new Array(23).fill(false))

    const prospectTest = useSelector(state=>state.prospectTest)
    const [virtues1, setVirtues1] = useState([])
    const [virtues2, setVirtues2] = useState([])

    const mediumhardTest = useSelector(state=>state.mediumhardTest)
    const [selectedState, setSelectedState] = useState(new Array(23).fill(0))
    
    const harmfulTest = useSelector(state=>state.harmfulTest)
    const [virtues, setVirtues] = useState([])
    const [virtuesWeak, setVirtuesWeak] = useState([])
    const [virtuesStrong, setVirtuesStrong] = useState([])

    const [virtuesWeakRadar, setVirtuesWeakRadar] = useState({"aceptacion": 0, "admiracion": 0, "alegria": 0, "compania": 0, "compasion": 0, "dignidad": 0, "disciplina": 0, "educacion": 0, "generosidad": 0, "guia": 0, "humildad": 0, "identidad": 0, "inspiracion": 0, "justicia": 0, "lealtad": 0, "libertad": 0, "paciencia": 0, "perdon": 0, "perseverancia": 0, "proteccion": 0, "responsabilidad": 0, "sabiduria": 0, "valentia": 0 })
    const [virtuesMediumRadar, setVirtuesMediumRadar] = useState({"aceptacion": 0, "admiracion": 0, "alegria": 0, "compania": 0, "compasion": 0, "dignidad": 0, "disciplina": 0, "educacion": 0, "generosidad": 0, "guia": 0, "humildad": 0, "identidad": 0, "inspiracion": 0, "justicia": 0, "lealtad": 0, "libertad": 0, "paciencia": 0, "perdon": 0, "perseverancia": 0, "proteccion": 0, "responsabilidad": 0, "sabiduria": 0, "valentia": 0 })
    const [virtuesStrongRadar, setVirtuesStrongRadar] = useState({"aceptacion": 0, "admiracion": 0, "alegria": 0, "compania": 0, "compasion": 0, "dignidad": 0, "disciplina": 0, "educacion": 0, "generosidad": 0, "guia": 0, "humildad": 0, "identidad": 0, "inspiracion": 0, "justicia": 0, "lealtad": 0, "libertad": 0, "paciencia": 0, "perdon": 0, "perseverancia": 0, "proteccion": 0, "responsabilidad": 0, "sabiduria": 0, "valentia": 0 })

    useEffect(()=>{
        dispatch(getUserDetail(id))
        dispatch(getAccesses())
    }, [dispatch, id])

    useEffect(() => {
        if (user) {
            dispatch(getInitialTest(id))
            dispatch(getWeakTest(id))
            dispatch(getProspectTest(id))
            dispatch(getMediumhardTest(id))
            dispatch(getHarmfulTest(id))
        }
    }, [user, dispatch, id])
    
    useEffect(() => {
        if(weakTest.test !== null){
            if(weakTest.test.responses?.length > 0){
                weakTest.test.responses.forEach(response => {
                    checkedState[response.number-1] = true
                })
                setCheckedState(checkedState)
            }
        }
    }, [weakTest, checkedState])

    useEffect(() => {
        if(prospectTest.tests){
            let array_virtues1 = []
            let array_virtues2 = []

            prospectTest.tests?.map((testrealizado, id)=>(
                testrealizado.me === true 
                ?
                    testrealizado?.feelings?.map((feeling)=>(
                        array_virtues1.push(feeling.virtue)
                    ))
                    &&
                    testrealizado?.conducts?.map((conduct)=>(
                        array_virtues1.push(conduct.virtue)
                    ))
                :
                    testrealizado?.feelings?.map((feeling)=>(
                        array_virtues2.push(feeling.virtue)
                    ))
                    &&
                    testrealizado?.conducts?.map((conduct)=>(
                        array_virtues2.push(conduct.virtue)
                    ))
            ))

            let array_count1 = []
            array_virtues1.forEach(function (virtue) {
                if (array_count1.find(ele => ele.virtue === virtue)) {
                    array_count1.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count1.push({virtue: virtue, count: 1})
                }
            })
            array_count1.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtues1(array_count1)

            let array_count2 = []
            array_virtues2.forEach(function (virtue) {
                if (array_count2.find(ele => ele.virtue === virtue)) {
                    array_count2.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count2.push({virtue: virtue, count: 1})
                }
            })
            array_count2.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtues2(array_count2)
        }
    }, [prospectTest])
    
    useEffect(() => {
        if(mediumhardTest.test){
            let testfound = []
            mediumhardTest?.test?.responses?.forEach(response => {
                if(response.type === 'medium'){
                    testfound.push('1')
                }else{
                    testfound.push('2')
                }
            })
            setSelectedState(testfound)

            let array_virtues_hard = []
            let array_virtues_medium = []

            let array_count_hard = []
            let array_count_medium = []
            
            let arrayVirtuesMediumRadar = virtuesMediumRadar
            let arrayVirtuesStrongRadar = virtuesStrongRadar

            mediumhardTest.test?.responses?.map((response)=>{
                if(response.type === 'hard'){
                    array_virtues_hard.push(response.virtue)
                    arrayVirtuesStrongRadar[response.virtue] = 3
                }
                return true
            })
            array_virtues_hard.forEach(function (virtue) {
                if (array_count_hard.find(ele => ele.virtue === virtue)) {
                    array_count_hard.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count_hard.push({virtue: virtue, count: 1})
                }
                return true
            })
            array_count_hard.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtuesStrong(array_count_hard)
            setVirtuesStrongRadar(arrayVirtuesStrongRadar)

            mediumhardTest.test?.responses?.map((response)=>{
                if(response.type === 'medium'){
                    array_virtues_medium.push(response.virtue)
                    arrayVirtuesMediumRadar[response.virtue] = 2
                }
                return true
            })

            array_virtues_medium.forEach(function (virtue) {
                if (array_count_medium.find(ele => ele.virtue === virtue)) {
                    array_count_medium.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count_medium.push({virtue: virtue, count: 1})
                }
            })
            array_count_medium.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtuesMediumRadar(arrayVirtuesMediumRadar)
        }
    }, [mediumhardTest, virtuesMediumRadar, virtuesStrongRadar])
    
    useEffect(() => {
        if(harmfulTest.tests){
            let array_virtues = []

            harmfulTest?.tests?.map((testrealizado, id)=>(
                testrealizado?.feelings?.map((feeling)=>(
                    array_virtues.push(feeling.virtue)
                ))
            ))

            let array_count = []
            array_virtues.forEach(function (virtue) {
                if (array_count.find(ele => ele.virtue === virtue)) {
                    array_count.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count.push({virtue: virtue, count: 1})
                }
            })
            array_count.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtues(array_count)
        }
    }, [harmfulTest])

    useEffect(() => {
        if(weakTest.test || prospectTest.tests){
            let array_virtues = []
            let arrayVirtuesWeakRadar = virtuesWeakRadar

            if(weakTest.test){
                weakTest.test?.responses?.map((response)=>{
                    array_virtues.push(response.weak)
                    arrayVirtuesWeakRadar[response.weak] = 1
                    return true
                })
            }

            if(prospectTest.tests){
                prospectTest.tests?.map((testrealizado, id)=>(
                    testrealizado?.feelings?.map((feeling)=>{
                        array_virtues.push(feeling.virtue)
                        arrayVirtuesWeakRadar[feeling.virtue] = 1
                        return true
                    })
                    &&
                    testrealizado?.conducts?.map((conduct)=>{
                        array_virtues.push(conduct.virtue)
                        arrayVirtuesWeakRadar[conduct.virtue] = 1
                        return true
                    })
                ))
            }

            let array_count = []
            array_virtues.forEach(function (virtue) {
                if (array_count.find(ele => ele.virtue === virtue)) {
                    array_count.find(ele => ele.virtue === virtue).count += 1
                } else {
                    array_count.push({virtue: virtue, count: 1})
                }
            })
            array_count.sort((itemA, itemB) => itemB.count - itemA.count)
            setVirtuesWeak(array_count)
            setVirtuesWeakRadar(arrayVirtuesWeakRadar)
        }
    }, [weakTest.test, prospectTest.tests, virtuesWeakRadar])

    const data = {
        labels: [t('virtues.aceptacion'), t('virtues.admiracion'), t('virtues.alegria'), t('virtues.compania'), t('virtues.compasion'), t('virtues.dignidad'), t('virtues.disciplina'), t('virtues.educacion'), t('virtues.generosidad'), t('virtues.guia'), t('virtues.humildad'), t('virtues.identidad'), t('virtues.inspiracion'), t('virtues.justicia'), t('virtues.lealtad'), t('virtues.libertad'), t('virtues.paciencia'), t('virtues.perdon'), t('virtues.perseverancia'), t('virtues.proteccion'), t('virtues.responsabilidad'), t('virtues.sabiduria'), t('virtues.valentia')],
        datasets: [
            {
                label: t('virtuestype.weak'),
                pointBorderColor: "rgba(0, 0, 0, 0)",
                pointBackgroundColor: "rgba(0, 0, 0, 0)",
                data: [virtuesWeakRadar.aceptacion, virtuesWeakRadar.admiracion, virtuesWeakRadar.alegria, virtuesWeakRadar.compania, virtuesWeakRadar.compasion, virtuesWeakRadar.dignidad, virtuesWeakRadar.disciplina, virtuesWeakRadar.educacion, virtuesWeakRadar.generosidad, virtuesWeakRadar.guia, virtuesWeakRadar.humildad, virtuesWeakRadar.identidad, virtuesWeakRadar.inspiracion, virtuesWeakRadar.justicia, virtuesWeakRadar.lealtad, virtuesWeakRadar.libertad, virtuesWeakRadar.paciencia, virtuesWeakRadar.perdon, virtuesWeakRadar.perseverancia, virtuesWeakRadar.proteccion, virtuesWeakRadar.responsabilidad, virtuesWeakRadar.sabiduria, virtuesWeakRadar.valentia].map(n => n === 0 ? null : n),
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgb(255, 185, 80)',
                borderWidth: 2,
            },
            {
                label: t('virtuestype.medium'),
                pointBorderColor: "rgba(0, 0, 0, 0)",
                pointBackgroundColor: "rgba(0, 0, 0, 0)",
                data: [virtuesMediumRadar.aceptacion, virtuesMediumRadar.admiracion, virtuesMediumRadar.alegria, virtuesMediumRadar.compania, virtuesMediumRadar.compasion, virtuesMediumRadar.dignidad, virtuesMediumRadar.disciplina, virtuesMediumRadar.educacion, virtuesMediumRadar.generosidad, virtuesMediumRadar.guia, virtuesMediumRadar.humildad, virtuesMediumRadar.identidad, virtuesMediumRadar.inspiracion, virtuesMediumRadar.justicia, virtuesMediumRadar.lealtad, virtuesMediumRadar.libertad, virtuesMediumRadar.paciencia, virtuesMediumRadar.perdon, virtuesMediumRadar.perseverancia, virtuesMediumRadar.proteccion, virtuesMediumRadar.responsabilidad, virtuesMediumRadar.sabiduria, virtuesMediumRadar.valentia].map(n => n === 0 ? null : n),
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgb(255, 228, 92)',
                borderWidth: 2,
            },
            {
                label: t('virtuestype.hard'),
                pointBorderColor: "rgba(0, 0, 0, 0)",
                pointBackgroundColor: "rgba(0, 0, 0, 0)",
                data: [virtuesStrongRadar.aceptacion, virtuesStrongRadar.admiracion, virtuesStrongRadar.alegria, virtuesStrongRadar.compania, virtuesStrongRadar.compasion, virtuesStrongRadar.dignidad, virtuesStrongRadar.disciplina, virtuesStrongRadar.educacion, virtuesStrongRadar.generosidad, virtuesStrongRadar.guia, virtuesStrongRadar.humildad, virtuesStrongRadar.identidad, virtuesStrongRadar.inspiracion, virtuesStrongRadar.justicia, virtuesStrongRadar.lealtad, virtuesStrongRadar.libertad, virtuesStrongRadar.paciencia, virtuesStrongRadar.perdon, virtuesStrongRadar.perseverancia, virtuesStrongRadar.proteccion, virtuesStrongRadar.responsabilidad, virtuesStrongRadar.sabiduria, virtuesStrongRadar.valentia].map(n => n === 0 ? null : n),
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgb(114, 233, 233)',
                borderWidth: 2,
            }
        ],
    }

    const options = {
        spanGaps: true,
        showLine: true,
        scales: {
            r: {
                max: 3,
                min: 0,
                ticks: {
                    stepSize: 20,
                    color: 'rgba(0, 0, 0,0)'
                },
                angleLines: {
                    color: 'rgba(0, 0, 0,0)',
                },
                grid: {
                    color: "rgba(0, 0, 0,0)",
                },
            },
        },
        plugins: {
            tooltip: {
                enabled: false
            }
        }
    }

    return (
        <Container className='cuerpo'>            
            <Button className="float-end" variant="outline-success" href={`/admin/users`}>
                <i className='fas fa-angle-left'></i> {t('global.gotousers')}
            </Button>
            <h1>{t('admin.user.user')}:&nbsp;<span className='text-uppercase'>{user?.firstname} {user?.lastname}</span></h1>
            <Navbar  className="bg-gray my-3">
                <Container>                    
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href={`/admin/bloqueuno/`+id}>{t('global.phase')} 1</Nav.Link>
                        <Nav.Link href={`/admin/bloquedos/`+id}>{t('global.phase')} 2</Nav.Link>
                        <Nav.Link href={`/admin/bloquetres/`+id}>{t('global.phase')} 3</Nav.Link>
                        <Nav.Link href={`/admin/user/`+id}>{t('global.data')}</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Tabs defaultActiveKey="home" id="datos-usuarios" className="mb-3 datos-usuarios">
                <Tab eventKey="home" title={t('admin.well-being')}>
                    {initialTest.test
                    ?
                        <>
                            {initialTest?.test?.map((testrealizado,id)=>(
                                <InitialTableData key={id} responses={testrealizado.responses} fecha={testrealizado.date} />
                            ))}
                        </>
                    :
                        <p>{t('global.initialtestnone')}</p>
                    }
                </Tab>
                <Tab eventKey="profile" title={t('admin.virtues-to-train')}>
                    <Row className='px-3 px-md-5'>
                        <Col xs="12" className='mx-auto'>
                            <div className="form p-4">
                                {checkedState.map((question, id) => (
                                    <div key={id} attr_key={id} className='question-conscience'>
                                    <Row className='pt-5 pb-4'>
                                        <Col xs="10">
                                            <p><strong>{t('weak.question'+(id+1))}</strong></p>
                                        </Col>
                                        <Col  xs="2" className='d-flex justify-content-center'>
                                            <Form.Check 
                                                type="checkbox"
                                                name={`q${question+1}`}
                                                checked={checkedState[id]}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="prospect" title={t('admin.prospect')}>
                    <label className="py-3">{t('admin.prospectaboutme')}</label>
                    <Row>
                        <Col xs='12' md='6'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>{t('prospect.who')}</th>
                                        <th>{t('prospect.prospect')}</th>
                                        <th>{t('prospect.feelings')}</th>
                                        <th>{t('prospect.conducts')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {prospectTest?.tests?.map((testrealizado,id)=>(
                                        testrealizado.me === true 
                                        ?
                                            <tr key={id}>
                                                <td>{t('prospect.persons.'+testrealizado.who)}</td>
                                                <td>
                                                    {testrealizado.prospect[0]
                                                        ?
                                                        <>
                                                            {testrealizado.prospect[0].toUpperCase() + testrealizado.prospect?.slice(1).toLowerCase()}
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                </td>
                                                <td>
                                                    {testrealizado?.feelings?.map((feeling,key)=>(
                                                        <div key={key}>
                                                            {t('emotions.'+feeling.emotion)}
                                                        </div>
                                                    ))}
                                                </td>
                                                <td>
                                                    {testrealizado?.conducts?.map((conduct, key)=>(
                                                        <div key={key}>
                                                            {t('behaviors.'+conduct.behavior)}                                                                
                                                        </div>
                                                    ))}
                                                </td>
                                            </tr>
                                        :
                                            <Fragment key={id}></Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs='12' md='6'>
                            <h4 className='verde'>{t('resume.virtues-to-train')}</h4>
                            <Accordion> 
                            {virtues1?.map((virtue, id)=>{
                                return (
                                    <Accordion.Item eventKey={id} key={id} className='virtudes-sincolor'> 
                                        <Accordion.Header>{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                        <Accordion.Body>
                                            {t('descriptions.'+virtue.virtue )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })}
                            </Accordion>
                        </Col>
                    </Row>
                    <label className="py-3">{t('admin.prospectaboutothers')}</label>
                    <Row>
                        <Col xs='12' md='6'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>{t('prospect.who')}</th>
                                        <th>{t('prospect.prospect')}</th>
                                        <th>{t('prospect.feelings')}</th>
                                        <th>{t('prospect.conducts')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {prospectTest?.tests?.map((testrealizado,id)=>(
                                        testrealizado.me === false 
                                        ?
                                            <tr key={id}>
                                                <td>{t('prospect.persons.'+testrealizado.who)}</td>
                                                <td>
                                                    {testrealizado.prospect[0]
                                                        ?
                                                        <>
                                                            {testrealizado.prospect[0].toUpperCase() + testrealizado.prospect?.slice(1).toLowerCase()}
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                </td>
                                                <td>
                                                    {testrealizado?.feelings?.map((feeling,key)=>(
                                                        <div key={key}>
                                                            {t('emotions.'+feeling.emotion)}
                                                        </div>
                                                    ))}
                                                </td>
                                                <td>
                                                    {testrealizado?.conducts?.map((conduct, key)=>(
                                                        <div key={key}>
                                                            {t('behaviors.'+conduct.behavior)}                                                                
                                                        </div>
                                                    ))}
                                                </td>
                                            </tr>
                                        :
                                            <Fragment key={id}></Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs='12' md='6'>
                        <h4 className='verde'>{t('resume.virtues-to-train')}</h4>
                            <Accordion> 
                            {virtues2?.map((virtue, id)=>{
                                return (
                                    <Accordion.Item eventKey={id} key={id} className='virtudes-sincolor'> 
                                        <Accordion.Header>{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                        <Accordion.Body>
                                            {t('descriptions.'+virtue.virtue )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })}
                            </Accordion>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="mediumhard" title={t('admin.mediumhard')}>
                    <Row className='pt-5 pb-4'>
                        <Col xs="8">
                        </Col>
                        <Col  xs="2" className='d-flex justify-content-center text-center'>
                            <p><strong>{t('mediumhard.table-header1')}</strong></p>
                        </Col>
                        <Col  xs="2" className='d-flex justify-content-center text-center'>
                            <p><strong>{t('mediumhard.table-header2')}</strong></p>
                        </Col>
                    </Row>
                    {/* RECORREMOS TODAS LAS PREGUNTAS  */}
                    <Row className='px-3 px-md-5'>
                        <Col xs="12" className='mx-auto'>
                            <div className="form p-4">
                                {selectedState?.map((radioSelected, key) => (
                                    <div key={key} attr_key={key} className='question-conscience'>
                                    <Row className='pt-5 pb-4'>
                                        <Col xs="8">
                                            <p><strong>{t('mediumhard.question'+(key+1))}</strong></p>
                                        </Col>
                                        <Col  xs="2" className='d-flex justify-content-center'>
                                            <Form.Check 
                                                className='customized'
                                                type="radio"
                                                name={key+1}
                                                seleccion={1}
                                                checked={radioSelected === '1' ? true : false}
                                                disabled
                                            />
                                        </Col>
                                        <Col  xs="2" className='d-flex justify-content-center'>
                                            <Form.Check 
                                                className='customized'
                                                type="radio"
                                                name={key+1}
                                                seleccion={2}
                                                checked={radioSelected === '2' ? true : false}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="harmful" title={t('admin.harmful')}>
                    <Row className='px-3 px-md-5'>
                        <Col xs='12' md='8'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>{t('harmful.habit')}</th>
                                        <th>{t('harmful.feeling')}</th>
                                        <th>{t('harmful.action')}</th>
                                        <th>{t('harmful.frequency')}</th>
                                        <th>{t('harmful.quantity')}</th>
                                        <th>{t('harmful.impact')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {harmfulTest?.tests?.map((testrealizado, id)=>(
                                        <tr key={id}>
                                            <td>{testrealizado.habit}</td>
                                            <td>
                                                {testrealizado?.feelings?.map((feeling, key)=>(
                                                    <div key={key}>
                                                        {t('emotions.'+feeling.emotion)}
                                                    </div>
                                                ))}
                                            </td>
                                            <td>
                                                {testrealizado.action}
                                            </td>
                                            <td>
                                                {t('frequencies.'+testrealizado.frequency)}
                                            </td>
                                            <td>
                                                {testrealizado.quantity}
                                            </td>
                                            <td>
                                                {testrealizado.impact}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs='12' md='4'>
                            <h4 className='verde'>{t('resume.virtues-to-train')}</h4>
                            <Accordion> 
                            {virtues?.map((virtue, id)=>{
                                return (
                                    <Accordion.Item eventKey={id} key={id} className='virtudes-sincolor'>
                                        <Accordion.Header>{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                        <Accordion.Body>
                                            {t('descriptions.'+virtue.virtue )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })}
                            </Accordion>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="resume" title={t('admin.resume')}>
                    <Row>
                        <Col>
                            <Row>
                                <Col className='px-5'>
                                    <div className="alert alert-success ps-5" role="alert">
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='d-flex align-items-center justify-content-start'>
                                                <h3 className='ms-3 m-0 text-white'>{t('resume.virtues-hard')}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <Accordion> 
                                    {virtuesStrong?.map((virtue, id)=>{
                                        return (
                                            <Accordion.Item eventKey={id} key={id} className='virtudes-sincolor'>
                                                <Accordion.Header>{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                                <Accordion.Body>
                                                    {t('descriptions.'+virtue.virtue )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })}
                                    </Accordion>
                                </Col>
                                <Col className='px-5'>
                                    <div className="alert alert-success ps-5" role="alert">
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='d-flex align-items-center justify-content-start'>
                                                <h3 className='ms-3 m-0 text-white'>{t('resume.virtues-to-train')}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <Accordion> 
                                    {virtuesWeak?.map((virtue, id)=>{
                                        return (
                                            <Accordion.Item eventKey={id} key={id} className='virtudes-sincolor'>
                                                <Accordion.Header>{t('virtues.'+virtue.virtue)}</Accordion.Header>
                                                <Accordion.Body>
                                                    {t('descriptions.'+virtue.virtue )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })}
                                    </Accordion>
                                </Col>
                            </Row>
                            <Row className='py-5'>                                
                                <Col xs="12" md="6" className='mx-auto'>
                                    <Radar options={options} data={data} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </Container>
    )
}
