export const trainingTestReducer = (state = {loading:true,result:null,exercises:null} , action) => {
    switch (action.type) {
        case 'TRAINING_TEST_SEND_SUCCESS':
            return {
                ...state,
                loading:false,
                exercises: [...state.exercises, action.payload]
            }
        case 'TRAINING_ALLTEST_GET_SUCCESS':
            return {
                ...state,
                loading:false,
                exercises: action.payload
            }
        case 'TRAINING_ALLTEST_GET_EMPTY':
            return {
                ...state,
                loading:false,
                exercises: action.payload
            }
        case 'TRAINING_DONE_SUCCESS':
            return {
                ...state,
                exercises: state.exercises?.map(exercise => {
                    if (exercise._id === action.payload._id) {
                        return action.payload
                    } else {
                        return exercise
                    }
                })
            }
        case 'TRAINING_TEST_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'TRAINING_TEST_ERROR':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}