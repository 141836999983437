import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Container, Row, Col, Button, Card, Table, Popover, OverlayTrigger} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../../../context/auth/useAuth'
import { getAllEvaluationTest } from '../../../../actions/evaluationTestActions'
import { Link } from 'react-router-dom'
import { getUservirtue } from '../../../../actions/uservirtueActions'

export default function Historical() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const {uservirtue} = useSelector(state=>state.uservirtue)
    const {tests} = useSelector(state=>state.evaluationTest)
    const [weakVirtues, setWeakVirtues] = useState([])
    const [mediumVirtues, setMediumVirtues] = useState([])
    const [hardVirtues, setHardVirtues] = useState([])
    const [displayElements, setDisplayElements] = useState(false)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client){
            dispatch(getUservirtue(client.id))
            dispatch(getAllEvaluationTest(client.id))
        }
    }, [client, dispatch])

    useEffect(() => {
        if(uservirtue){
            let weakVirtuesArray = []
            let mediumVirtuesArray = []
            let hardVirtuesArray = []

            for (const virtue in uservirtue) {
                if(virtue !== '_id' && virtue !== '__v' && virtue !== 'user'){
                    if(uservirtue[virtue] === 'debil'){
                        weakVirtuesArray.push(virtue)
                    }
                    if(uservirtue[virtue] === 'media'){
                        mediumVirtuesArray.push(virtue)
                    }
                    if(uservirtue[virtue] === 'fuerte'){
                        hardVirtuesArray.push(virtue)
                    }
                }
            }

            setWeakVirtues(weakVirtuesArray)
            setMediumVirtues(mediumVirtuesArray)
            setHardVirtues(hardVirtuesArray)
        }
    }, [uservirtue])
    
    return (
        <>
          
            <Container fluid className='contenido modulo2' id='historical'>            
            <div className='bg-white p-2 d-block d-md-none d-flex justify-content-around align-items-center rounded' id="barra-historical">
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        overlay={
                                        <Popover>                                                                    
                                        <Popover.Body>
                                        {t('historical.explain-crisis')}
                                        </Popover.Body>
                                        </Popover>
                                    }
                                    >
                                        <button  className="bola-frecuencia bola-diario popovers" data-toggle="popover"></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        overlay={
                                        <Popover>                                                                    
                                        <Popover.Body>
                                        {t('historical.explain-challenge')}
                                        </Popover.Body>
                                        </Popover>
                                    }
                                    >
                                        <button  className="bola-frecuencia bola-algunos popovers" data-toggle="popover"></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        overlay={
                                        <Popover>                                                                    
                                        <Popover.Body>
                                        {t('historical.explain-flow')}
                                        </Popover.Body>
                                        </Popover>
                                    }
                                    >
                                        <button  className="bola-frecuencia bola-pocos popovers" data-toggle="popover"></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        overlay={
                                        <Popover>                                                                    
                                        <Popover.Body>
                                        {t('historical.explain-custom')}
                                        </Popover.Body>
                                        </Popover>
                                    }
                                    >
                                        <button  className="bola-frecuencia bola-ninguno popovers" data-toggle="popover"></button>
                                    </OverlayTrigger>
                                    <Link to="/client/profile" className='icono-home'>
                                        <i className="fas fa-home fa-2x"></i>
                                    </Link>  
            </div>    
                <Row className='px-0 px-md-5'>
                    <Col xs="12" className='mx-auto'>
                        <div className="form p-2 p-md-4">
                            <div>
                                <Row>
                                    <Col className='px-4 d-flex'>
                                        <h2>{t('historical.title')}</h2>
                                        <Link to="/client/profile" className='position-fixed icono-home d-none d-md-block'>
                                            <i className="fas fa-home fa-2x"></i>
                                        </Link>                                        
                                    </Col>
                                </Row>
                            
                                <Row>
                                    <Col className='px-4 pb-3'>
                                        <div className="alert auto alert-success text-center" role="alert">
                                            <h3 className='m-0 text-white'>{t('historical.alert')}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='px-4'>
                                        <p><strong>{t('historical.paragraph1')}</strong></p>
                                    </Col>
                                </Row>
                            </div>
                            <Card className='my-5 d-none d-md-block sticky-top'>
                                <Card.Body className='px-5'>
                                    <Row className='pt-5 pb-4'>
                                        <Col xs="4" className='justify-content-center p-5'>
                                            <h3>{t('historical.explain')}</h3>
                                        </Col>
                                        <Col xs="2" className='d-flex justify-content-center text-center explain-block-first p-4'>
                                            <div className='bola-frecuencia bola-diario mb-3'></div>
                                            <div><strong>{t('historical.explain-crisis')}</strong></div>
                                        </Col>
                                        <Col xs="2" className='d-flex justify-content-center text-center explain-block p-4'>
                                            <div className='bola-frecuencia bola-algunos mb-3'></div>
                                            <div><strong>{t('historical.explain-challenge')}</strong></div>
                                        </Col>
                                        <Col xs="2" className='d-flex justify-content-center text-center explain-block p-4'>
                                            <div className='bola-frecuencia bola-pocos mb-3'></div>
                                            <div><strong>{t('historical.explain-flow')}</strong></div>
                                        </Col>
                                        <Col xs="2" className='d-flex justify-content-center text-center explain-block p-4'>
                                            <div className='bola-frecuencia bola-ninguno mb-3'></div>
                                            <div><strong>{t('historical.explain-custom')}</strong></div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className='my-5'>
                                <Card.Body className='px-2 px-md-5'>
                                    <Row className='pt-5'>
                                        <Col xs="auto" className='px-4'>
                                            <div className="alert auto alert-success text-center" role="alert">
                                                <h3 className='m-0 text-white'>{t('virtuestype.weak')}</h3>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='py-3'>
                                        <div className='text-end'>
                                            <span className='btn-vacio' hidden={displayElements} onClick={() => setDisplayElements(true)}>
                                                <i className='fas fa-chevron-right'></i>
                                            </span>
                                            <span className='btn-vacio' hidden={!displayElements} onClick={() => setDisplayElements(false)}>
                                                <i className='fas fa-chevron-left'></i>
                                            </span>
                                        </div>
                                        <Col>
                                            <Table responsive className="historical">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        {tests?.map((test, itest) => (
                                                            <th key={itest} hidden={itest > 0 && itest < 7 ? displayElements : itest > 0 ? !displayElements : false}>{test.date}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {weakVirtues?.map((virtue, ivirtue) => (
                                                        <tr key={ivirtue}>
                                                            <td key={ivirtue} className='columna-virtudes'>
                                                                <OverlayTrigger
                                                                    trigger="click"
                                                                    placement="right"
                                                                    overlay={
                                                                    <Popover>
                                                                    <Popover.Header as="h3">{t('virtues.'+virtue)}</Popover.Header>
                                                                    <Popover.Body>
                                                                    {t('descriptions.'+virtue)}
                                                                    </Popover.Body>
                                                                    </Popover>
                                                                }
                                                                >
                                                                    <button type="button" className="alert alert-info mt-3 popovers d-flex justify-content-between align-items-center" data-toggle="popover">
                                                                        {t('virtues.'+virtue)}
                                                                        <i className='fas fa-plus'></i>
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </td>
                                                            {tests?.map((test, itest) => (
                                                                <td key={itest} className={`columna${itest} ${itest === 0 ? 'td-bg-grisclaro' : ''}`} hidden={itest > 0 && itest < 7 ? displayElements : itest > 0 ? !displayElements : false}>
                                                                    <div className={`bola-frecuencia bola-${test[virtue]}`}></div>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row className='pt-4 pb-3'>
                                        <Col xs="auto" className='px-4 pb-3'>
                                            <div className="alert auto alert-success text-center" role="alert">
                                                <h3 className='m-0 text-white'>{t('virtuestype.medium')}</h3>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='py-3'>
                                        <Col>
                                            <Table responsive id='medium' className='historical'>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        {tests?.map((test, itest) => (
                                                            <th key={itest} hidden={itest > 0 && itest < 7 ? displayElements : itest > 0 ? !displayElements : false}>{test.date}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {mediumVirtues?.map((virtue, ivirtue) => (
                                                        <tr key={ivirtue}>
                                                            <td key={ivirtue} className='columnavirtudes'>
                                                                <OverlayTrigger
                                                                    trigger="click"
                                                                    placement="right"
                                                                    overlay={
                                                                    <Popover>
                                                                    <Popover.Header as="h3">{t('virtuetraining.comoentrenar')} {t('virtues.'+virtue)}</Popover.Header>
                                                                    <Popover.Body>
                                                                    {t('virtuetraining.'+virtue)}
                                                                    </Popover.Body>
                                                                    </Popover>
                                                                }
                                                                >
                                                                    <button type="button" className="alert alert-info mt-3 popovers d-flex justify-content-between align-items-center" data-toggle="popover">
                                                                        {t('virtues.'+virtue)}
                                                                        <i className='fas fa-plus'></i>
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </td>
                                                            {tests?.map((test, itest) => (
                                                                <td key={itest} className={`columna${itest} ${itest === 0 ? 'td-bg-grisclaro' : ''}`} hidden={itest > 0 && itest < 7 ? displayElements : itest > 0 ? !displayElements : false}>
                                                                    <div className={`bola-frecuencia bola-${test[virtue]}`}></div>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row className='pt-4 pb-3'>
                                        <Col xs="auto" className='px-4 pb-3'>
                                            <div className="alert auto alert-success text-center" role="alert">
                                                <h3 className='m-0 text-white'>{t('virtuestype.hard')}</h3>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='py-3'>
                                        <Col>
                                            <Table responsive id="hard" className='historical'>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        {tests?.map((test, itest) => (
                                                            <th key={itest} hidden={itest > 0 && itest < 7 ? displayElements : itest > 0 ? !displayElements : false}>{test.date}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {hardVirtues?.map((virtue, ivirtue) => (
                                                        <tr key={ivirtue}>
                                                            <td key={ivirtue} className='columnavirtudes'>
                                                                <OverlayTrigger
                                                                    trigger="click"
                                                                    placement="right"
                                                                    overlay={
                                                                    <Popover>
                                                                    <Popover.Header as="h3">{t('virtuetraining.comoentrenar')} {t('virtues.'+virtue)}</Popover.Header>
                                                                    <Popover.Body>
                                                                    {t('virtuetraining.'+virtue)}
                                                                    </Popover.Body>
                                                                    </Popover>
                                                                }
                                                                >
                                                                    <button type="button" className="alert alert-info mt-3 popovers d-flex justify-content-between align-items-center" data-toggle="popover">
                                                                        {t('virtues.'+virtue)}
                                                                        <i className='fas fa-plus'></i>
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </td>
                                                            {tests?.map((test, itest) => (
                                                                <td key={itest} className={`columna${itest} ${itest === 0 ? 'td-bg-grisclaro' : ''}`} hidden={itest > 0 && itest < 7 ? displayElements : itest > 0 ? !displayElements : false}>
                                                                    <div className={`bola-frecuencia bola-${test[virtue]}`}></div>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row xs="12" id="evaluation_test_footer_right">
                                        <Col className="text-end mt-2 mt-md-0">
                                            <Link to="/client/test/responsibility/training">
                                                <Button className='boton-gris' variant='primary' type="submit" disabled>
                                                    {t('global.next')}
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Row className='my-4 botones-pasos'>
                                <Col className="d-flex justify-content-md-between justify-content-center flex-wrap">
                                    
                                            <Link to="/client/test/responsibility/weeklyresult">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.weekly')}
                                                </Button>
                                            </Link>
                                    
                                            <Link to="/client/test/responsibility/evaluation">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.evaluation')}
                                                </Button>
                                            </Link>
                                    
                                            <Link to="/client/test/responsibility/training">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.training')}
                                                </Button>
                                            </Link>

                                            <Button className='boton-blanco' variant='primary' type="submit">
                                                {t('responsibility.historical')}
                                            </Button>
                                    
                                            <Link to="/client/test/responsibility/essential">
                                                <Button className='boton-gris' variant='primary' type="submit">
                                                    {t('responsibility.essential')}
                                                </Button>
                                            </Link>
                                </Col>
                            </Row>
                            <Row className='my-4 botones-pasos'>
                                <Col className="d-flex justify-content-center flex-wrap">
                                    <Link to="/client/test/responsibility/guide">
                                        <Button variant='primary guide' type="submit" disabled>
                                            {t('responsibility.guide')}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}