import { Document, Page, Text, View, Image, StyleSheet} from "@react-pdf/renderer"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"


export default function ResumeReport(info){
    const { t } = useTranslation()
	let styles = StyleSheet.create({
		pagina: {			
			paddingTop: 20,
			paddingRight: 30,
			paddingLeft: 30,
			paddingBottom: 20,
			fontSize: 18,
			fontFamily: 'Helvetica'
		},
		cabeceraPagina:{
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			marginBottom: 30 
		},	
		image:{
			flex:1,	
			maxWidth: 150
		},
		name: {
			flex: 1,
			fontSize: 20,
			fontFamily: 'Helvetica-Bold',
			color: "#4c4c4c",
			marginTop: 20,
            textAlign: 'right'
		},
		titulo: {
			color: "#ffffff",
			fontSize: 18,
			fontFamily: 'Helvetica-Bold'
		},
		titulo2: {
			borderWidth: 1,
			borderColor: "#4c4c4c",
			borderRadius: 40,
			color: "#4c4c4c",
			fontSize: 16,
			fontFamily: 'Helvetica-Bold',
			marginBottom: 20,
			paddingVertical: 10,
			paddingHorizontal: 20,
			width: 250
		},
		mitad: {
			marginBottom:20,
			width: '50%'
		},
        contenedorVirtudes: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 20
        },
        texto : {
            fontSize: 12,
			fontFamily: 'Helvetica',
			color: "#4c4c4c"
        },
		caja: {
			borderRadius: 20,
            flex: 1,
            marginHorizontal: 5,
			paddingVertical:20,
		},
        cabeceraVirtud: {
			backgroundColor: "#4c4c4c",
			borderRadius: 20,
			color: "#ffffff",
			fontSize: 14,
			fontFamily: 'Helvetica-Bold',
			marginBottom: 5,
			paddingHorizontal: 10,
			paddingVertical: 7,
            textAlign: 'center',
		},
		virtud: {
			border: 1,
			borderColor: "#6e6e6e",
			borderRadius: 20,
			color: "#6e6e6e",
			fontSize: 12,
			fontFamily: 'Helvetica-Bold',
			marginVertical: 3,
			paddingHorizontal: 10,
			paddingVertical: 7,
		},
		contenedorPolarity: {
            display: 'flex',
            flexDirection: 'row',
			marginBottom:10
        },
		cabecera:{
			display: 'flex',
			flexDirection: 'row'
		},
		cajaPolarity: {
            flex: 1,
			marginHorizontal: 5
		},
		head: {
			color: "#4c4c4c",
			flex: 1,
			fontSize: 12,
			fontFamily: 'Helvetica-Bold',
		},
		separador: {							
			borderWidth: 1,
			borderColor: "#4c4c4c"
		},
		bloqueVirtud: {
			justifyContent:'space-between',
			display: 'flex',
			flexDirection: 'row',
			paddingVertical: 10,
			width:'100%'
		},
		datoTitulo: {
			border: 1,
			borderColor: "#6e6e6e",
			borderRadius: 20,
			color: "#6e6e6e",
			fontSize: 12,
			fontFamily: 'Helvetica-Bold',
			marginVertical: 3,
			marginHorizontal: 3,
			paddingHorizontal: 10,
			paddingVertical: 7,
			flexBasis: '32%',  // Garantiza que ocupe el 32% del ancho
			flexGrow: 0,       // No se expande más allá del 32%
			flexShrink: 0
		},		
		dato: {
			border: 1,
			borderColor: "#6e6e6e",
			borderRadius: 20,
			color: "#6e6e6e",
			fontSize: 12,
			fontFamily: 'Helvetica-Bold',
			marginVertical: 3,
			marginHorizontal: 3,
			paddingHorizontal: 10,
			paddingVertical: 7,
			flexBasis: '32%',  // Garantiza que ocupe el 32% del ancho
			flexGrow: 0,       // No se expande más allá del 32%
			flexShrink: 0
		},
		nodato: {
			marginVertical: 3,
			marginHorizontal: 3,
			paddingHorizontal: 10,
			paddingVertical: 7,
			flexBasis: '32%',  // Garantiza que ocupe el 32% del ancho
			flexGrow: 0,       // No se expande más allá del 32%
			flexShrink: 0
		},
		result: {
			fontSize: 12,
			fontFamily: 'Helvetica',
			color: "#4c4c4c",
			flexBasis: '32%',  // Garantiza que ocupe el 32% del ancho
			flexGrow: 0,       // No se expande más allá del 32%
			flexShrink: 0,
			whiteSpace: 'normal',      // Permite saltos de línea
    		wordBreak: 'break-all',
		},
		noexercises: {
			color: "#4c4c4c",
			flex: 1,
			fontSize: 12,
			fontFamily: 'Helvetica-Bold',
			marginBottom: 10
		},
		pageNumber: {
			position: 'absolute',
			fontSize: 10,
			bottom: 15,
			left: 0,
			right: 0,
			textAlign: 'center',
			color: 'grey',
		}
	})
	
    let datos = []
    if(info.virtuesStrongPolarity?.length > 0){
        info.virtuesStrongPolarity?.map((virtue) => (		    
				datos.push(virtue.virtue)
		))
        
    }

	return (
		<Document>
            <Page size="A4" style={styles.pagina}>
				<View>
				<View style={styles.cabeceraPagina} fixed>
						<Image style={styles.image} src="/images/logotipo.png"/>
						<View style={styles.name}>
							<Text>{info.name}</Text>
						</View>
					</View>
					<View style={styles.mitad}>
						<View style={styles.texto}>
							<Text>{t('resume.phrase1a')} {t('resume.phrase1b')} {t('resume.phrase2')}</Text>
						</View>
					</View>
                    <View style={styles.contenedorVirtudes}>
                        <View style={styles.caja}>
                            <Text style={styles.cabeceraVirtud}>{t('resume.hard')}</Text>
                            {info.virtuesStrong?.map((virtues, index) => (
                               <Text key={index} style={styles.virtud}>{t('virtues.'+virtues.virtue)}</Text>
							))}
                        </View>
                        <View style={styles.caja}>
                            <Text style={styles.cabeceraVirtud}>{t('resume.train')}</Text>
                            {info.virtuesWeak?.map((virtues, index) => (
                               <Text key={index} style={styles.virtud}>{t('virtues.'+virtues.virtue)}</Text>
							))}
                        </View>
                        <View style={styles.caja}>
                            <Text style={styles.cabeceraVirtud}>{t('resume.medium')}</Text>
                            {info.virtuesMedium?.map((virtues, index) => (
                               <Text key={index} style={styles.virtud}>{t('virtues.'+virtues.virtue)}</Text>
							))}
                        </View>
                    </View>
					<View wrap={false}>
					<View style={styles.cabecera}>
							<View style={styles.cajaPolarity}></View>
							<View style={styles.cajaPolarity}>
								<View style={styles.cabeceraVirtud}>
									<Text style={styles.titulo}>{t('resume.mypolarity')}</Text>
								</View>
							</View>
							<View style={styles.cajaPolarity}></View>
						</View>
						<View style={styles.contenedorPolarity}>
							<View style={styles.cajaPolarity}>
								<View style={styles.cabeceraVirtud}>
									<Text>{t('polarity.apply-a-lot')}</Text>
								</View>
							</View>
							<View style={styles.cajaPolarity}>
								<View style={styles.cabeceraVirtud}>
									<Text>{t('polarity.not-apply-a-lot')}</Text>
								</View>
							</View>
							<View style={styles.cajaPolarity}>
								<View style={styles.cabeceraVirtud}>
									<Text>{t('polarity.result')}</Text>
								</View>
							</View>
						</View>
						<View style={styles.contenedorPolarity}>
							<View style={styles.cajaPolarity} >
								{datos?.map((virtue, id, virtuesStrong)=> {
									let virtueCount = 0
									return(
										info.virtuesWeaksOfStrong[virtue].length >0
										?
											<View key={id}>
											{info.virtuesWeaksOfStrong[virtue]?.map((weakVirtue, key)=>(
												info.virtuesWeakPolarity.includes(weakVirtue.name)
												?
													<View key={key} style={styles.bloqueVirtud}>
														{virtueCount++ < 1
														?
															<View style={styles.datoTitulo}>
																<Text>{t('virtues.'+virtue)}</Text>
															</View>
														:
															<View style={styles.nodato}></View>
														}
														<View style={styles.dato}>
															<Text>{t('virtues.'+weakVirtue.name)}</Text>
														</View>
														<View style={styles.result}>
															<Text>{t('virtues.tips.'+weakVirtue.value)}</Text>
														</View>
													</View>
												:
													<Fragment key={key}></Fragment>
											))}
												{virtueCount > 0 && id + 1 !== virtuesStrong.length 
													? 
													<Text style={styles.separador}></Text> 
													: 
													<></>
												}
											</View>
										:
											<></>
									)
								})}
							</View>
						</View>
					</View>
				</View>
				<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        			`${pageNumber} / ${totalPages}`
      			)} fixed />
            </Page>
		</Document>
	)
}