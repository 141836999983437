import Axios  from "axios"

export const sendTrainingTest = (user, description, level, frequency, virtue) => async (dispatch) => {
    dispatch({
        type: 'TRAINING_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.post(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/training/register', {
                user: user,
                description: description,
                level: level,
                frequency: frequency,
                virtue: virtue
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'TRAINING_TEST_SEND_SUCCESS',
                payload: data.exercise
            }) 
        }

    } catch (error) {
        dispatch({
            type: 'TRAINING_TEST_ERROR'
        })         
    }
}

export const getAllTrainingTest = (user) => async (dispatch) => {
    dispatch({
        type: 'TRAINING_TEST_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/training/getbyuser/' + user, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'TRAINING_ALLTEST_GET_SUCCESS',
                payload: data.trainings
            })
        }
        if(status===263){
            dispatch({
                type: 'TRAINING_ALLTEST_GET_EMPTY'
            })
        }
    } catch (error) {
        dispatch({
            type: 'TRAINING_TEST_ERROR'
        })
    }
}

export const editTrainingTest = (exercise, level, frequency) => async (dispatch) => {
    try {
        const {
            data,
            status
        } = await Axios.put(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/training/edit/' + exercise, {
                level: level,
                frequency: frequency
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200) {
            dispatch({
                type: 'TRAINING_DONE_SUCCESS',
                payload: data.exercise
            })
        }
    } catch (error) {
        dispatch({
            type: 'TRAINING_TEST_ERROR'
        })
    }
}

export const setdoneTrainingTest = (exercise, favourite) => async (dispatch) => {
    try {
        const {
            data,
            status
        } = await Axios.put(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/responsibility/training/setdone/' + exercise, {
                favourite: favourite
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if (status === 200) {
            dispatch({
                type: 'TRAINING_DONE_SUCCESS',
                payload: data.trainingexercise
            })
        }
    } catch (error) {
        dispatch({
            type: 'TRAINING_TEST_ERROR'
        })
    }
}