import Axios  from "axios"

export const getUservirtue = (user) => async (dispatch) => {
    dispatch({
        type: 'USERVIRTUE_LOADING'
    })
    try {
        const {data,status} = await Axios.get(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/uservirtue/getbyuser/'+ user, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        if(status===200){
            dispatch({
                type: 'USERVIRTUE_GET_SUCCESS',
                payload: data.uservirtue
            })
        }
    } catch (error) {
        dispatch({
            type: 'USERVIRTUE_ERROR'
        })
    }
}

export const editUservirtue = (user, virtue, type) => async (dispatch) => {
    dispatch({
        type: 'USERVIRTUE_LOADING'
    })
    try {
        await Axios.put(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/uservirtue/edit/' + user, {
                user: user,
                virtue: virtue,
                type: type,
            }, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        dispatch({
            type: 'USERVIRTUE_EDIT_SUCCESS',
            payload: {
                user: user,
                virtue: virtue,
                type: type,
            }
        })
    } catch (error) {
        dispatch({
            type: 'USERVIRTUE_ERROR'
        })
    }
}

export const editUservirtueManifest = (user, manifiests) => async (dispatch) => {
    dispatch({
        type: 'USERVIRTUE_LOADING'
    })
    try {
        const {data,status} = await Axios.put(
            process.env.REACT_APP_DB_ADDRESS + '/api/client/uservirtue/editmanifest/' + user, manifiests, {
                headers: {
                    auth: sessionStorage.getItem('jwt')
                }
            }
        )
        dispatch({
            type: 'USERVIRTUE_EDIT_SUCCESS',
            payload: {
                user: user,
                newuservirtue: data.newUservirtue
            }
        })
    } catch (error) {
        dispatch({
            type: 'USERVIRTUE_ERROR'
        })
    }
}