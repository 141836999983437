import { Route, Routes } from 'react-router-dom'
import NotFoundPage from '../pages/404/NotFoundPage'
import { Forgot} from '../pages/auth/Forgot'
import Login from '../pages/auth/Login'
import ResetPassword from '../pages/auth/ResetPassword'

export default function AuthRouter () {
  return (
      <Routes>                
        <Route path="/" element={<Login />}/>   
        <Route path="/login" element={<Login />}/>        
        <Route path="/forgotpassword" element={<Forgot />}/>
        <Route path="/resetpassword/:id/:tokenresetpassword" element={<ResetPassword />}/>        
        <Route path="/*" element={<NotFoundPage />}/>   
        
      </Routes>
  )
}