import React,{Fragment, useEffect, useState} from 'react'
import { useTranslation } from "react-i18next"
import { Row, Col, Tabs, Tab, Container,Navbar,Nav, Button, Form, Card, Modal, Table} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../context/auth/useAuth'
import { useParams } from 'react-router-dom'
import { getUserDetail } from '../../actions/usersActions'
import { getUservirtue } from '../../actions/uservirtueActions'
import { getPersonalTest, sendPersonalTest } from '../../actions/personalTestActions'
import { getProfessionalTest, sendProfessionalTest } from '../../actions/professionalTestActions'
import { getCoupleTest, sendCoupleTest } from '../../actions/coupleTestActions'
import Select from "react-select"
import Swal from 'sweetalert2'

export default function BlockOne() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
    const {id} = useParams()
	const {client} = useAuth()

    const {user} = useSelector(state=>state.users)
    const {uservirtue} = useSelector(state=>state.uservirtue)
    const {personalTest} = useSelector(state=>state.personalTest)
    const {professionalTest} = useSelector(state=>state.professionalTest)
    const {coupleTest} = useSelector(state=>state.coupleTest)

    const [modalShow, setModalShow] = useState(false)
    const [weakVirtues, setWeakVirtues] = useState([])
    const [hardVirtues, setHardVirtues] = useState([])
    const [motivateVirtues, setMotivateVirtues] = useState([])
    const [favourite, setFavourite] = useState('')

    const [virtues, setVirtues] = useState({})
    const [virtues2, setVirtues2] = useState({})

    const [dimensionOptions, setDimensionOptions] = useState({
        options: [
            {label: t('impact.family'), value: 'family'},
            {label: t('impact.community'), value: 'community'},
            {label: t('impact.country'), value: 'country'},
            {label: t('impact.world'), value: 'world'}
        ],
        selected: ''
    })

    const [durationOptions, setDurationOptions] = useState({
        options: [
            {label: t('impact.ilive'), value: 'ilive'},
            {label: t('impact.iimpact'), value: 'iimpact'},
            {label: t('impact.intime'), value: 'intime'}
        ],
        selected: ''
    })

    const [lifeproject, setLifeproject] = useState('')

    const virtuespolarities = {
        'aceptacion': ['perseverancia'],
        'admiracion': ['dignidad'],
        'alegria': ['responsabilidad', 'disciplina'],
        'compania': ['dignidad', 'identidad'],
        'compasion': ['dignidad', 'justicia'],
        'dignidad': ['admiracion', 'humildad', 'generosidad', 'responsabilidad'],
        'disciplina': ['alegria', 'libertad', 'inspiracion', 'paciencia'],
        'educacion': ['dignidad', 'identidad', 'libertad'],
        'generosidad': ['dignidad', 'justicia'],
        'guia': ['humildad'],
        'humildad': ['dignidad', 'identidad', 'justicia', 'sabiduria'],
        'identidad': ['educacion', 'humildad'],
        'inspiracion': ['disciplina'],
        'justicia': ['compasion', 'humildad'],
        'lealtad': ['dignidad', 'libertad'],
        'libertad': ['disciplina', 'educacion', 'lealtad', 'responsabilidad'],
        'paciencia': ['disciplina', 'perseverancia'],
        'perdon': ['dignidad', 'justicia'],
        'perseverancia': ['aceptacion', 'paciencia'],
        'proteccion': ['valentia'],
        'responsabilidad': ['alegria', 'dignidad', 'justicia', 'libertad'],
        'sabiduria': ['humildad'],
        'valentia': ['aceptacion', 'paciencia', 'proteccion']
    }

    const [allVirtuesPolarities, setAllVirtuesPolarities] = useState({
        aceptacion: {polarity: 'empty', virtues_couple: '', potential: ''}, admiracion: {polarity: 'empty', virtues_couple: '', potential: ''}, alegria: {polarity: 'empty', virtues_couple: '', potential: ''}, compania: {polarity: 'empty', virtues_couple: '', potential: ''}, compasion: {polarity: 'empty', virtues_couple: '', potential: ''}, dignidad: {polarity: 'empty', virtues_couple: '', potential: ''}, disciplina: {polarity: 'empty', virtues_couple: '', potential: ''},
        educacion: {polarity: 'empty', virtues_couple: '', potential: ''}, generosidad: {polarity: 'empty', virtues_couple: '', potential: ''}, guia: {polarity: 'empty', virtues_couple: '', potential: ''}, humildad: {polarity: 'empty', virtues_couple: '', potential: ''}, identidad: {polarity: 'empty', virtues_couple: '', potential: ''}, inspiracion: {polarity: 'empty', virtues_couple: '', potential: ''}, justicia: {polarity: 'empty', virtues_couple: '', potential: ''},
        lealtad: {polarity: 'empty', virtues_couple: '', potential: ''}, libertad: {polarity: 'empty', virtues_couple: '', potential: ''}, paciencia: {polarity: 'empty', virtues_couple: '', potential: ''}, perdon: {polarity: 'empty', virtues_couple: '', potential: ''}, perseverancia: {polarity: 'empty', virtues_couple: '', potential: ''}, proteccion: {polarity: 'empty', virtues_couple: '', potential: ''}, responsabilidad: {polarity: 'empty', virtues_couple: '', potential: ''},
        sabiduria: {polarity: 'empty', virtues_couple: '', potential: ''}, valentia: {polarity: 'empty', virtues_couple: '', potential: ''},
    })

	useEffect(() => {
		if(client?.id){
            dispatch(getUserDetail(id))
            dispatch(getUservirtue(id))
            dispatch(getPersonalTest(id))
            dispatch(getProfessionalTest(id))
            dispatch(getCoupleTest(id))
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, client, id])

    useEffect(() => {
        if(personalTest){
            if(personalTest.motivate){
                setMotivateVirtues(personalTest.motivate)
            }
            if(personalTest.dimension){
                setDimensionOptions({options: dimensionOptions.options, selected: {label: t('impact.'+personalTest.dimension), value: personalTest.dimension}})
            }
            if(personalTest.duration){
                setDurationOptions({options: durationOptions.options, selected: {label: t('impact.'+personalTest.duration), value: personalTest.duration}})
            }
            if(personalTest.lifeproject){
                setLifeproject(personalTest.lifeproject)
            }
            if(personalTest.favourite){
                setFavourite(personalTest.favourite)
            }
        }
    }, [personalTest, dimensionOptions.options, durationOptions.options, t])

    useEffect(() => {
        if(uservirtue){
            let weakVirtuesArray = []
            let hardVirtuesArray = []

            for (const virtue in uservirtue) {
                if(virtue !== '_id' && virtue !== '__v' && virtue !== 'user'){
                    if(uservirtue[virtue] === 'debil'){
                        weakVirtuesArray.push(virtue)
                    }
                    if(uservirtue[virtue] === 'fuerte'){
                        hardVirtuesArray.push(virtue)
                    }else{
                        let arrayMotivateVirtues = motivateVirtues
                        if(arrayMotivateVirtues?.includes(virtue)){
                            setMotivateVirtues(arrayMotivateVirtues.filter(virtueFilter => virtueFilter !== virtue))
                        }
                    }
                }
            }

            setWeakVirtues(weakVirtuesArray)
            setHardVirtues(hardVirtuesArray)

            if(coupleTest?.complementary){
                setAllVirtuesPolarities(coupleTest?.complementary)
            }

            let jsonVirtues = {}
            let jsonVirtues2 = {}

            weakVirtuesArray?.map((virtue1)=>{
                if(professionalTest?.virtues[virtue1]?.length > 0 && professionalTest.virtues[virtue1][6] === 'weak'){
                    jsonVirtues[virtue1] = [professionalTest.virtues[virtue1][0], professionalTest.virtues[virtue1][1], professionalTest.virtues[virtue1][2], professionalTest.virtues[virtue1][3], professionalTest.virtues[virtue1][4], professionalTest.virtues[virtue1][5], professionalTest.virtues[virtue1][6]]
                }else{
                    jsonVirtues[virtue1] = ['empty', 'empty', 'empty', '-', '-', '-', 'weak']
                }
                if(coupleTest?.virtues[virtue1]?.length > 0 && coupleTest.virtues[virtue1][6] === 'weak'){
                    jsonVirtues2[virtue1] = [coupleTest.virtues[virtue1][0], coupleTest.virtues[virtue1][1], coupleTest.virtues[virtue1][2], coupleTest.virtues[virtue1][3], coupleTest.virtues[virtue1][4], coupleTest.virtues[virtue1][5], coupleTest.virtues[virtue1][6]]
                }else{
                    jsonVirtues2[virtue1] = ['empty', 'empty', 'empty', '-', '-', '-', 'weak']
                }
                return true
            })

            hardVirtuesArray?.map((virtue1)=>{
                if(professionalTest?.virtues[virtue1]?.length > 0 && professionalTest?.virtues[virtue1][6] === 'hard'){
                    jsonVirtues[virtue1] = [professionalTest.virtues[virtue1][0], professionalTest.virtues[virtue1][1], professionalTest.virtues[virtue1][2], professionalTest.virtues[virtue1][3], professionalTest.virtues[virtue1][4], professionalTest.virtues[virtue1][5], professionalTest.virtues[virtue1][6]]
                }else{
                    jsonVirtues[virtue1] = ['empty', 'empty', 'empty', '-', '-', '-', 'hard']
                }
                if(coupleTest?.virtues[virtue1]?.length > 0 && coupleTest?.virtues[virtue1][6] === 'hard'){
                    jsonVirtues2[virtue1] = [coupleTest.virtues[virtue1][0], coupleTest.virtues[virtue1][1], coupleTest.virtues[virtue1][2], coupleTest.virtues[virtue1][3], coupleTest.virtues[virtue1][4], coupleTest.virtues[virtue1][5], coupleTest.virtues[virtue1][6]]
                }else{
                    jsonVirtues2[virtue1] = ['empty', 'empty', 'empty', '-', '-', '-', 'hard']
                }
                return true
            })

            setVirtues(jsonVirtues)
            setVirtues2(jsonVirtues2)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uservirtue, motivateVirtues, professionalTest, coupleTest])

    const addVirtueMotivate = (virtue) => {
        let arrayMotivateVirtues = motivateVirtues

        if(arrayMotivateVirtues.includes(virtue)){
            setMotivateVirtues(arrayMotivateVirtues.filter(virtueFilter => virtueFilter !== virtue))
        }else{
            if(arrayMotivateVirtues.length < 3){
                setMotivateVirtues([...motivateVirtues, virtue])
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'warning',
                    text: t('impact.cantaddmotivate')
                })
            }
        }
    }

    const selectDimensionHandle = (e) => {
        setDimensionOptions({options: dimensionOptions.options, selected: {label: t('impact.'+e.value), value: e.value}})
    }

    const selectDurationHandle = (e) => {
        setDurationOptions({options: durationOptions.options, selected: {label: t('impact.'+e.value), value: e.value}})
    }

    const savePersonal = () => {
        dispatch(sendPersonalTest({
            user: id,
            motivate: motivateVirtues,
            favourite: favourite,
            dimension: dimensionOptions.selected?.value,
            duration: durationOptions.selected?.value,
            lifeproject: lifeproject
        }))
        Swal.fire({
            icon: 'success',
            text: t('impact.personaledited'),
            showConfirmButton: true,
            confirmButtonText: t('impact.great')
        })
    }

    const selectWeakVirtueOptionHandle = (e, virtue, position) => {
        let jsonVirtues = {}

        weakVirtues?.map((virtue1)=>{
            if(virtues[virtue1]){
                jsonVirtues[virtue1] = [virtues[virtue1][0], virtues[virtue1][1], virtues[virtue1][2], virtues[virtue1][3], virtues[virtue1][4], virtues[virtue1][5], 'weak']
            }
            return true
        })

        hardVirtues?.map((virtue1)=>{
            if(virtues[virtue1]){
                jsonVirtues[virtue1] = [virtues[virtue1][0], virtues[virtue1][1], virtues[virtue1][2], virtues[virtue1][3], virtues[virtue1][4], virtues[virtue1][5], 'hard']
            }
            return true
        })

        jsonVirtues[virtue][position] = e.value

        if(jsonVirtues[virtue][0] === true){
            if(jsonVirtues[virtue][0] !== 'empty' && jsonVirtues[virtue][1] !== 'empty' && jsonVirtues[virtue][2] !== 'empty'){
                if(jsonVirtues[virtue][1] === true){
                    if(jsonVirtues[virtue][2] === true){
                        jsonVirtues[virtue][3] = 'wow'
                        jsonVirtues[virtue][4] = 'resultweak6'
                        jsonVirtues[virtue][5] = 'actionweak6'
                    }else{
                        jsonVirtues[virtue][3] = 'challenge'
                        jsonVirtues[virtue][4] = 'resultweak4'
                        jsonVirtues[virtue][5] = 'actionweak4'
                    }
                }else{
                    if(jsonVirtues[virtue][2] === true){
                        jsonVirtues[virtue][3] = 'flow'
                        jsonVirtues[virtue][4] = 'resultweak5'
                        jsonVirtues[virtue][5] = 'actionweak5'
                    }else{
                        jsonVirtues[virtue][3] = 'crisis'
                        jsonVirtues[virtue][4] = 'resultweak3'
                        jsonVirtues[virtue][5] = 'actionweak3'
                    }
                }
            }else{
                jsonVirtues[virtue][3] = '-'
                jsonVirtues[virtue][4] = '-'
                jsonVirtues[virtue][5] = '-'
            }
        }else{
            if(jsonVirtues[virtue][0] !== 'empty' && jsonVirtues[virtue][2] !== 'empty'){
                if(jsonVirtues[virtue][2] === true){
                    jsonVirtues[virtue][3] = 'challenge'
                    jsonVirtues[virtue][4] = 'resultweak2'
                    jsonVirtues[virtue][5] = 'actionweak2'
                }else{
                    jsonVirtues[virtue][3] = 'crisis'
                    jsonVirtues[virtue][4] = 'resultweak1'
                    jsonVirtues[virtue][5] = 'actionweak1'
                }
            }else{
                jsonVirtues[virtue][3] = '-'
                jsonVirtues[virtue][4] = '-'
                jsonVirtues[virtue][5] = '-'
            }
        }

        setVirtues(jsonVirtues)
    }

    const selectHardVirtueOptionHandle = (e, virtue, position) => {
        let jsonVirtues = {}

        weakVirtues?.map((virtue1)=>{
            if(virtues[virtue1]){
                jsonVirtues[virtue1] = [virtues[virtue1][0], virtues[virtue1][1], virtues[virtue1][2], virtues[virtue1][3], virtues[virtue1][4], virtues[virtue1][5], 'weak']
            }
            return true
        })

        hardVirtues?.map((virtue1)=>{
            if(virtues[virtue1]){
                jsonVirtues[virtue1] = [virtues[virtue1][0], virtues[virtue1][1], virtues[virtue1][2], virtues[virtue1][3], virtues[virtue1][4], virtues[virtue1][5], 'hard']
            }
            return true
        })

        jsonVirtues[virtue][position] = e.value

        if(jsonVirtues[virtue][0] === true){
            jsonVirtues[virtue][3] = 'wow'
            jsonVirtues[virtue][4] = 'resulthard4'
            jsonVirtues[virtue][5] = 'actionhard4'
        }else{
            if(jsonVirtues[virtue][1] === true){
                if(jsonVirtues[virtue][0] !== 'empty' && jsonVirtues[virtue][1] !== 'empty'){
                    if(jsonVirtues[virtue][2] === true){
                        jsonVirtues[virtue][3] = 'flow'
                        jsonVirtues[virtue][4] = 'resulthard3'
                        jsonVirtues[virtue][5] = 'actionhard3'
                    }else{
                        if(jsonVirtues[virtue][2] !== 'empty'){
                            jsonVirtues[virtue][3] = 'challenge'
                            jsonVirtues[virtue][4] = 'resulthard2'
                            jsonVirtues[virtue][5] = 'actionhard2'
                        }else{
                            jsonVirtues[virtue][3] = '-'
                            jsonVirtues[virtue][4] = '-'
                            jsonVirtues[virtue][5] = '-'
                        }
                    }
                }else{
                    jsonVirtues[virtue][3] = '-'
                    jsonVirtues[virtue][4] = '-'
                    jsonVirtues[virtue][5] = '-'
                }
            }else{
                jsonVirtues[virtue][3] = 'flow'
                jsonVirtues[virtue][4] = 'resulthard1'
                jsonVirtues[virtue][5] = 'actionhard1'
            }
        }

        setVirtues(jsonVirtues)
    }

    const selectWeakVirtueOptionHandle2 = (e, virtue, position) => {
        let jsonVirtues = {}

        weakVirtues?.map((virtue1)=>{
            if(virtues2[virtue1]){
                jsonVirtues[virtue1] = [virtues2[virtue1][0], virtues2[virtue1][1], virtues2[virtue1][2], virtues2[virtue1][3], virtues2[virtue1][4], virtues2[virtue1][5], 'weak']
            }
            return true
        })

        hardVirtues?.map((virtue1)=>{
            if(virtues2[virtue1]){
                jsonVirtues[virtue1] = [virtues2[virtue1][0], virtues2[virtue1][1], virtues2[virtue1][2], virtues2[virtue1][3], virtues2[virtue1][4], virtues2[virtue1][5], 'hard']
            }
            return true
        })

        jsonVirtues[virtue][position] = e.value

        if(jsonVirtues[virtue][0] === true){
            if(jsonVirtues[virtue][0] !== 'empty' && jsonVirtues[virtue][1] !== 'empty' && jsonVirtues[virtue][2] !== 'empty'){
                if(jsonVirtues[virtue][1] === true){
                    if(jsonVirtues[virtue][2] === true){
                        jsonVirtues[virtue][3] = 'wow'
                        jsonVirtues[virtue][4] = 'resultweakcouple6'
                        jsonVirtues[virtue][5] = 'actionweakcouple6'
                    }else{
                        jsonVirtues[virtue][3] = 'challenge'
                        jsonVirtues[virtue][4] = 'resultweakcouple4'
                        jsonVirtues[virtue][5] = 'actionweakcouple4'
                    }
                }else{
                    if(jsonVirtues[virtue][2] === true){
                        jsonVirtues[virtue][3] = 'flow'
                        jsonVirtues[virtue][4] = 'resultweakcouple5'
                        jsonVirtues[virtue][5] = 'actionweakcouple5'
                    }else{
                        jsonVirtues[virtue][3] = 'crisis'
                        jsonVirtues[virtue][4] = 'resultweakcouple3'
                        jsonVirtues[virtue][5] = 'actionweakcouple3'
                    }
                }
            }else{
                jsonVirtues[virtue][3] = '-'
                jsonVirtues[virtue][4] = '-'
                jsonVirtues[virtue][5] = '-'
            }
        }else{
            if(jsonVirtues[virtue][0] !== 'empty' && jsonVirtues[virtue][2] !== 'empty'){
                if(jsonVirtues[virtue][2] === true){
                    jsonVirtues[virtue][3] = 'challenge'
                    jsonVirtues[virtue][4] = 'resultweakcouple2'
                    jsonVirtues[virtue][5] = 'actionweakcouple2'
                }else{
                    jsonVirtues[virtue][3] = 'crisischallenge'
                    jsonVirtues[virtue][4] = 'resultweakcouple1'
                    jsonVirtues[virtue][5] = 'actionweakcouple1'
                }
            }else{
                jsonVirtues[virtue][3] = '-'
                jsonVirtues[virtue][4] = '-'
                jsonVirtues[virtue][5] = '-'
            }
        }

        setVirtues2(jsonVirtues)
    }

    const selectHardVirtueOptionHandle2 = (e, virtue, position) => {
        let jsonVirtues = {}

        weakVirtues?.map((virtue1)=>{
            if(virtues2[virtue1]){
                jsonVirtues[virtue1] = [virtues2[virtue1][0], virtues2[virtue1][1], virtues2[virtue1][2], virtues2[virtue1][3], virtues2[virtue1][4], virtues2[virtue1][5], 'weak']
            }
            return true
        })

        hardVirtues?.map((virtue1)=>{
            if(virtues2[virtue1]){
                jsonVirtues[virtue1] = [virtues2[virtue1][0], virtues2[virtue1][1], virtues2[virtue1][2], virtues2[virtue1][3], virtues2[virtue1][4], virtues2[virtue1][5], 'hard']
            }
            return true
        })

        jsonVirtues[virtue][position] = e.value

        if(jsonVirtues[virtue][0] === true){
            jsonVirtues[virtue][3] = 'wow'
            jsonVirtues[virtue][4] = 'resulthard4'
            jsonVirtues[virtue][5] = 'actionhard4'
        }else{
            if(jsonVirtues[virtue][1] === true){
                if(jsonVirtues[virtue][0] !== 'empty' && jsonVirtues[virtue][1] !== 'empty'){
                    if(jsonVirtues[virtue][2] === true){
                        jsonVirtues[virtue][3] = 'challenge'
                        jsonVirtues[virtue][4] = 'resulthard3'
                        jsonVirtues[virtue][5] = 'actionhard3'
                    }else{
                        if(jsonVirtues[virtue][2] !== 'empty'){
                            jsonVirtues[virtue][3] = 'crisis'
                            jsonVirtues[virtue][4] = 'resulthard2'
                            jsonVirtues[virtue][5] = 'actionhard2'
                        }else{
                            jsonVirtues[virtue][3] = '-'
                            jsonVirtues[virtue][4] = '-'
                            jsonVirtues[virtue][5] = '-'
                        }
                    }
                }else{
                    jsonVirtues[virtue][3] = '-'
                    jsonVirtues[virtue][4] = '-'
                    jsonVirtues[virtue][5] = '-'
                }
            }else{
                jsonVirtues[virtue][3] = 'flow'
                jsonVirtues[virtue][4] = 'resulthard1'
                jsonVirtues[virtue][5] = 'actionhard1'
            }
        }

        setVirtues2(jsonVirtues)
    }

    const saveProfessional = () => {
        dispatch(sendProfessionalTest({user: id, virtues: virtues}))
        Swal.fire({
            icon: 'success',
            text: t('impact.professionaledited'),
            showConfirmButton: true,
            confirmButtonText: t('impact.great')
        })
    }

    const saveCouple = () => {
        dispatch(sendCoupleTest({user: id, virtues: virtues2}))
        Swal.fire({
            icon: 'success',
            text: t('impact.coupleedited'),
            showConfirmButton: true,
            confirmButtonText: t('impact.great')
        })
    }

    const saveComplementary = () => {
        let newDataVirtuesArray = allVirtuesPolarities

        hardVirtues?.map((virtue)=>{
            let newDataVirtue = {
                polarity: document.querySelector(`[virtue=${virtue}]`).querySelector('[name=selectcomplementary]').value,
                virtues_couple: document.querySelector(`[virtue=${virtue}]`).querySelector('[name=othervirtues]').value,
                potential: document.querySelector(`[virtue=${virtue}]`).querySelector('[name=creations]').value
            }

            newDataVirtuesArray[virtue] = newDataVirtue
            return true
        })

        dispatch(sendCoupleTest({user: id, complementary: newDataVirtuesArray}))
        Swal.fire({
            icon: 'success',
            text: t('impact.coupleedited'),
            showConfirmButton: true,
            confirmButtonText: t('impact.great')
        })
    }

    return (
        <Container className='cuerpo'>
            <Button className="float-end" variant="outline-success" href={`/admin/users`}>
                <i className='fas fa-angle-left'></i> {t('global.gotousers')}
            </Button>
            <h1>{t('admin.user.user')}:&nbsp;<span className='text-uppercase'>{user?.firstname} {user?.lastname}</span></h1>
            <Navbar  className="bg-gray my-3">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href={`/admin/bloqueuno/`+id}>{t('global.phase')} 1</Nav.Link>
                        <Nav.Link href={`/admin/bloquedos/`+id}>{t('global.phase')} 2</Nav.Link>
                        <Nav.Link href={`/admin/bloquetres/`+id}>{t('global.phase')} 3</Nav.Link>
                        <Nav.Link href={`/admin/user/`+id}>{t('global.data')}</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Tabs defaultActiveKey="personalpurposes" id="datos-usuarios" className="mb-3 datos-usuarios">
                <Tab eventKey="personalpurposes" title={t('impact.personal')}>
                    <Row>
                        <Col xs="auto" className='px-4 pb-3'>
                            <div className="alert auto alert-success text-center" role="alert">
                                <h3 className='m-0 text-white'>{t('impact.personal')}</h3>
                            </div>
                        </Col>
                    </Row>
                    <Card className='alert alert-info border'>
                        <Card.Body className='px-2 px-md-5'>
                            <Row>
                                <Col xs="7">
                                    <p><strong className="verde">{t('impact.motivate1-header')}</strong></p>
                                    {t('impact.motivate1-body')}
                                    <Row>
                                    {hardVirtues?.length > 0
                                        ?
                                            hardVirtues?.map((virtue, ivirtue) => (
                                                <Col xs='4' className='mt-2 mb-2' key={ivirtue}>
                                                    {!motivateVirtues?.includes(virtue)
                                                        ?
                                                            <Button className='btn btn-primary boton-blanco w-100 p-1' onClick={() => addVirtueMotivate(virtue)}>{t('virtues.'+virtue)}</Button>
                                                        :
                                                            <Button className='btn btn-primary w-100 p-1' onClick={() => addVirtueMotivate(virtue)}>{t('virtues.'+virtue)}</Button>
                                                    }
                                                </Col>
                                            ))
                                        :
                                            <Fragment></Fragment>
                                    }
                                    </Row>
                                </Col>
                                <Col xs="5">
                                    <p><strong className="verde">{t('impact.motivate2-header')}</strong></p>
                                    <ul>
                                        <li className="li-verde my-3"><span>{t('impact.motivate2-body1')}</span></li>
                                        <li className="li-verde my-3"><span>{t('impact.motivate2-body2')}</span></li>
                                        <li className="li-verde my-3"><span>{t('impact.motivate2-body3')}</span></li>
                                        <li className="li-verde my-3"><span>{t('impact.motivate2-body4')}</span></li>
                                    </ul>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className='alert alert-info border'>
                        <Card.Body className='px-2 px-md-5'>
                            <Row>
                                <Col xs="7">
                                    <p><strong className="verde">{t('impact.motivate3-header')}</strong></p>
                                    {t('impact.motivate3-body')}
                                    <br/><br/>
                                    <Button variant='primary' type='buttom' onClick={() => setModalShow(true)}>
                                        {t('impact.examplesfavourites')}
                                    </Button>
                                </Col>
                                <Col xs="5">
                                    <p><strong className="verde">{t('impact.motivate4-header')}</strong></p>
                                    <Form.Group>
                                        <Form.Control
                                                as="textarea"
                                                className="form-control form-control-verde"
                                                name='favourite'
                                                rows='6'
                                                value={favourite}
                                                onChange={(e) => setFavourite(e.target.value)}
                                        />  
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className='alert alert-info border'>
                        <Card.Body className='px-2 px-md-5'>
                            <Row>
                                <Col>
                                    <p><strong className="verde">{t('impact.motivate5-header')}</strong></p>
                                    <Select
                                        className="form-control form-control-verde" 
                                        name="dimension" 
                                        options={dimensionOptions.options}
                                        value={dimensionOptions.selected}
                                        onChange={selectDimensionHandle}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className='alert alert-info border'>
                        <Card.Body className='px-2 px-md-5'>
                            <Row>
                                <Col>
                                    <p><strong className="verde">{t('impact.motivate6-header')}</strong></p>
                                    <Select
                                        className="form-control form-control-verde" 
                                        name="duration" 
                                        options={durationOptions.options}
                                        value={durationOptions.selected}
                                        onChange={selectDurationHandle}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className='alert alert-info border'>
                        <Card.Body className='px-2 px-md-5'>
                            <Row>
                                <Col>
                                    <p><strong className="verde">{t('impact.motivate7-header')}</strong></p>
                                    {t('impact.motivate7-body')}
                                    <Form.Group>
                                        <Form.Control className="form-control mt-2"
                                            type='text'
                                            name='lifeproject'
                                            value={lifeproject}
                                            onChange={(e) => setLifeproject(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row>
                        <Col className='text-end'>
                            <Button variant='primary' type='buttom' onClick={savePersonal}>
                                {t('global.save')}
                            </Button>
                        </Col>
                    </Row>
                    <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Table>
                                        <thead>
                                            <tr className='verde text-center'>
                                                <th><strong>{t('impact.virtue')}</strong></th>
                                                <th><strong>{t('impact.satisfaction')}</strong></th>
                                                <th><strong>{t('impact.peoplefavourite')}</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {hardVirtues?.map((virtue, id)=>(
                                                <tr key={id} className='p-3'>
                                                    <td className='text-center'>{t('virtues.'+virtue)}</td>
                                                    <td>{t('impact.virtuesfavourites.satisfaction.'+virtue)}</td>
                                                    <td>{t('impact.virtuesfavourites.peoplefavourite.'+virtue)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Button onClick={() => setModalShow(false)}>{t('prospect.close')}</Button>
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>
                </Tab>
                <Tab eventKey="professionalpurposes" title={t('impact.alertprofessional')}>
                    <Row>
                        <Col className='px-4 pb-3'>
                            <div className="alert auto alert-success text-center" role="alert">
                                <h3 className='m-0 text-white'>{t('impact.alertprofessional')}</h3>
                            </div>
                        </Col>
                    </Row>
                    <Card className='alert alert-info border'>
                        <Card.Body className='px-2 px-md-5'>
                            <Row>
                                <Col>
                                    {virtues
                                        ?
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>{t('impact.myvirtuesweak')}</th>
                                                        <th>{t('impact.mybusiness')}</th>
                                                        <th>{t('impact.trainingweak')}</th>
                                                        <th>{t('impact.midlevelweak')}</th>
                                                        <th>{t('impact.level')}</th>
                                                        <th>{t('impact.result')}</th>
                                                        <th>{t('impact.action')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {weakVirtues?.map((virtue, id)=>(
                                                        <Fragment key={id}>
                                                            {virtues[virtue]
                                                                ?
                                                                    <tr>
                                                                        <td>{t('virtues.'+virtue)}</td>
                                                                        <td>
                                                                            <Select
                                                                                className="form-control form-control-verde" 
                                                                                name={'select-'+virtue}
                                                                                options={[
                                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                                    {label: t('impact.true'), value: true},
                                                                                    {label: t('impact.false'), value: false}
                                                                                ]}
                                                                                value={{label: t('impact.'+virtues[virtue][0]), value: virtues[virtue][0]}}
                                                                                onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 0)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Select
                                                                                className={`form-control form-control-verde ${virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? 'disabled' : '' }`} 
                                                                                name={'select-'+virtue}
                                                                                options={[
                                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                                    {label: t('impact.true'), value: true},
                                                                                    {label: t('impact.false'), value: false}
                                                                                ]}
                                                                                value={virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? {label: t('impact.false'), value: false} : {label: t('impact.'+virtues[virtue][1]), value: virtues[virtue][1]}}
                                                                                onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 1)}
                                                                                isDisabled={virtues[virtue][0] === false || virtues[virtue][0] === 'false' ? true : false}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Select
                                                                                className="form-control form-control-verde" 
                                                                                name={'select-'+virtue}
                                                                                options={[
                                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                                    {label: t('impact.true'), value: true},
                                                                                    {label: t('impact.false'), value: false}
                                                                                ]}
                                                                                value={{label: t('impact.'+virtues[virtue][2]), value: virtues[virtue][2]}}
                                                                                onChange={(e) => selectWeakVirtueOptionHandle(e, virtue, 2)}
                                                                            />
                                                                        </td>
                                                                        <td>{t('impact.'+virtues[virtue][3])}</td>
                                                                        <td>{t('impact.'+virtues[virtue][4])}</td>
                                                                        <td>{t('impact.'+virtues[virtue][5])}</td>
                                                                    </tr>
                                                                :
                                                                    <></>
                                                            }
                                                        </Fragment>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        :
                                            <>{t('impact.thereisnovirtues')}</>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    <Card className='alert alert-info border'>
                        <Card.Body className='px-2 px-md-5'>
                            <Row>
                                <Col>
                                    {virtues
                                        ?
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>{t('impact.myvirtueshard')}</th>
                                                        <th>{t('impact.mybusiness')}</th>
                                                        <th>{t('impact.traininghard')}</th>
                                                        <th>{t('impact.midlevelhard')}</th>
                                                        <th>{t('impact.level')}</th>
                                                        <th>{t('impact.result')}</th>
                                                        <th>{t('impact.action')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {hardVirtues?.map((virtue, id)=>(
                                                        <Fragment key={id}>
                                                            {virtues[virtue]
                                                                ?
                                                                    <tr>
                                                                        <td>{t('virtues.'+virtue)}</td>
                                                                        <td>
                                                                            <Select
                                                                                className="form-control form-control-verde" 
                                                                                name={'select-'+virtue}
                                                                                options={[
                                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                                    {label: t('impact.true'), value: true},
                                                                                    {label: t('impact.false'), value: false}
                                                                                ]}
                                                                                value={{label: t('impact.'+virtues[virtue][0]), value: virtues[virtue][0]}}
                                                                                onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 0)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Select
                                                                                className={`form-control form-control-verde ${virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? 'disabled' : '' }`}
                                                                                name={'select-'+virtue}
                                                                                options={[
                                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                                    {label: t('impact.true'), value: true},
                                                                                    {label: t('impact.false'), value: false}
                                                                                ]}
                                                                                value={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? {label: t('impact.true'), value: true} : {label: t('impact.'+virtues[virtue][1]), value: virtues[virtue][1]}}
                                                                                onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 1)}
                                                                                isDisabled={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? true : false}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Select
                                                                                className={`form-control form-control-verde ${(virtues[virtue][0] === true || virtues[virtue][0] === 'true') || ((virtues[virtue][0] === false || virtues[virtue][0] === 'false') && (virtues[virtue][1] === false || virtues[virtue][1] === 'false')) ? 'disabled' : '' }`}
                                                                                name={'select-'+virtue}
                                                                                options={[
                                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                                    {label: t('impact.true'), value: true},
                                                                                    {label: t('impact.false'), value: false}
                                                                                ]}
                                                                                value={virtues[virtue][0] === true || virtues[virtue][0] === 'true' ? {label: t('impact.true'), value: true} : {label: t('impact.'+virtues[virtue][2]), value: virtues[virtue][2]}}
                                                                                onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 2)}
                                                                                isDisabled={(virtues[virtue][0] === true || virtues[virtue][0] === 'true') || ((virtues[virtue][0] === false || virtues[virtue][0] === 'false') && (virtues[virtue][1] === false || virtues[virtue][1] === 'false')) ? true : false}
                                                                            />
                                                                        </td>
                                                                        <td>{t('impact.'+virtues[virtue][3])}</td>
                                                                        <td>{t('impact.'+virtues[virtue][4])}</td>
                                                                        <td>{t('impact.'+virtues[virtue][5])}</td>
                                                                    </tr>
                                                                :
                                                                    <></>
                                                            }
                                                        </Fragment>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        :
                                            <>{t('impact.thereisnovirtues')}</>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                        </Card>
                    <Row className='mb-3'>
                        <Col className='text-end'>
                            <Button variant='primary' type='buttom' onClick={saveProfessional}>
                                {t('global.save')}
                            </Button>
                        </Col>
                    </Row>
                    <Card className='alert alert-info border'>
                        <Card.Body className='px-2 px-md-5'>
                            <Row>
                                <Col>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>{t('impact.level')}</th>
                                                <th>{t('impact.scene')}</th>
                                                <th>{t('impact.recommendation')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td rowSpan={4}>{t('impact.crisis')}</td>
                                                <td>{t('impact.crisisscene1')}</td>
                                                <td rowSpan={4}>{t('impact.crisisrecommendation')}</td>
                                            </tr>
                                            <tr><td>{t('impact.crisisscene2')}</td></tr>
                                            <tr><td>{t('impact.crisisscene3')}</td></tr>
                                            <tr><td>{t('impact.crisisscene4')}</td></tr>
                                            <tr>
                                                <td rowSpan={2}>{t('impact.challenge')}</td>
                                                <td>{t('impact.challengescene1')}</td>
                                                <td rowSpan={2}>{t('impact.challengerecommendation')}</td>
                                            </tr>
                                            <tr><td>{t('impact.challengescene2')}</td></tr>
                                            <tr>
                                                <td rowSpan={2}>{t('impact.flow')}</td>
                                                <td>{t('impact.flowscene1')}</td>
                                                <td rowSpan={2}>{t('impact.flowrecommendation')}</td>
                                            </tr>
                                            <tr><td>{t('impact.flowscene2')}</td></tr>
                                            <tr>
                                                <td rowSpan={2}>{t('impact.wow')}</td>
                                                <td>{t('impact.wowscene1')}</td>
                                                <td rowSpan={2}>{t('impact.wowrecommendation')}</td>
                                            </tr>
                                            <tr><td>{t('impact.wowscene2')}</td></tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Tab>
                <Tab eventKey="couple" title={t('impact.alertcouple')}>
                    <Row>
                        <Col className='px-4 pb-3'>
                            <div className="alert auto alert-success text-center" role="alert">
                                <h3 className='m-0 text-white'>{t('impact.alertcouple')}</h3>
                            </div>
                        </Col>
                    </Row>
                    <Card className='alert alert-info border'>
                        <Card.Body className='px-2 px-md-5'>
                            <Row>
                                <Col>
                                    {virtues2
                                        ?
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>{t('impact.myvirtuesweak')}</th>
                                                        <th>{t('impact.mycouplehave')}</th>
                                                        <th>{t('impact.igetbycouple')}</th>
                                                        <th>{t('impact.thankstocouple')}</th>
                                                        <th>{t('impact.level')}</th>
                                                        <th>{t('impact.result')}</th>
                                                        <th>{t('impact.action')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {weakVirtues?.map((virtue, id)=>(
                                                        <Fragment key={id}>
                                                            {virtues2[virtue]
                                                                ?
                                                                    <tr>
                                                                        <td>{t('virtues.'+virtue)}</td>
                                                                        <td>
                                                                            <Select
                                                                                className="form-control form-control-verde" 
                                                                                name={'select-'+virtue}
                                                                                options={[
                                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                                    {label: t('impact.true'), value: true},
                                                                                    {label: t('impact.false'), value: false}
                                                                                ]}
                                                                                value={{label: t('impact.'+virtues2[virtue][0]), value: virtues2[virtue][0]}}
                                                                                onChange={(e) => selectWeakVirtueOptionHandle2(e, virtue, 0)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Select
                                                                                className={`form-control form-control-verde ${virtues2[virtue][0] === false || virtues2[virtue][0] === 'false' ? 'disabled' : '' }`} 
                                                                                name={'select-'+virtue}
                                                                                options={[
                                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                                    {label: t('impact.true'), value: true},
                                                                                    {label: t('impact.false'), value: false}
                                                                                ]}
                                                                                value={virtues2[virtue][0] === false || virtues2[virtue][0] === 'false' ? {label: t('impact.false'), value: false} : {label: t('impact.'+virtues2[virtue][1]), value: virtues2[virtue][1]}}
                                                                                onChange={(e) => selectWeakVirtueOptionHandle2(e, virtue, 1)}
                                                                                isDisabled={virtues2[virtue][0] === false || virtues2[virtue][0] === 'false' ? true : false}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Select
                                                                                className="form-control form-control-verde" 
                                                                                name={'select-'+virtue}
                                                                                options={[
                                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                                    {label: t('impact.true'), value: true},
                                                                                    {label: t('impact.false'), value: false}
                                                                                ]}
                                                                                value={{label: t('impact.'+virtues2[virtue][2]), value: virtues2[virtue][2]}}
                                                                                onChange={(e) => selectWeakVirtueOptionHandle2(e, virtue, 2)}
                                                                            />
                                                                        </td>
                                                                        <td>{t('impact.'+virtues2[virtue][3])}</td>
                                                                        <td>{t('impact.'+virtues2[virtue][4])}</td>
                                                                        <td>{t('impact.'+virtues2[virtue][5])}</td>
                                                                    </tr>
                                                                :
                                                                    <></>
                                                            }
                                                        </Fragment>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        :
                                            <>{t('impact.thereisnovirtues')}</>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className='alert alert-info border'>
                        <Card.Body className='px-2 px-md-5'>
                            <Row>
                                <Col>
                                    {virtues2
                                        ?
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>{t('impact.myvirtueshard')}</th>
                                                        <th>{t('impact.mycouplehave')}</th>
                                                        <th>{t('impact.mycouplehas')}</th>
                                                        <th>{t('impact.mycoupleget')}</th>
                                                        <th>{t('impact.level')}</th>
                                                        <th>{t('impact.result')}</th>
                                                        <th>{t('impact.action')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {hardVirtues?.map((virtue, id)=>(
                                                        <Fragment key={id}>
                                                            {virtues2[virtue]
                                                                ?
                                                                    <tr>
                                                                        <td>{t('virtues.'+virtue)}</td>
                                                                        <td>
                                                                            <Select
                                                                                className="form-control form-control-verde" 
                                                                                name={'select-'+virtue}
                                                                                options={[
                                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                                    {label: t('impact.true'), value: true},
                                                                                    {label: t('impact.false'), value: false}
                                                                                ]}
                                                                                value={{label: t('impact.'+virtues2[virtue][0]), value: virtues2[virtue][0]}}
                                                                                onChange={(e) => selectHardVirtueOptionHandle2(e, virtue, 0)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Select
                                                                                className={`form-control form-control-verde ${virtues2[virtue][0] === true || virtues2[virtue][0] === 'true' ? 'disabled' : '' }`}
                                                                                name={'select-'+virtue}
                                                                                options={[
                                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                                    {label: t('impact.true'), value: true},
                                                                                    {label: t('impact.false'), value: false}
                                                                                ]}
                                                                                value={virtues2[virtue][0] === true || virtues2[virtue][0] === 'true' ? {label: t('impact.true'), value: true} : {label: t('impact.'+virtues2[virtue][1]), value: virtues2[virtue][1]}}
                                                                                onChange={(e) => selectHardVirtueOptionHandle2(e, virtue, 1)}
                                                                                isDisabled={virtues2[virtue][0] === true || virtues2[virtue][0] === 'true' ? true : false}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Select
                                                                                className={`form-control form-control-verde ${(virtues2[virtue][0] === true || virtues2[virtue][0] === 'true') || ((virtues2[virtue][0] === false || virtues2[virtue][0] === 'false') && (virtues2[virtue][1] === false || virtues2[virtue][1] === 'false')) ? 'disabled' : '' }`}
                                                                                name={'select-'+virtue}
                                                                                options={[
                                                                                    {label: t('impact.empty'), value: 'empty'},
                                                                                    {label: t('impact.true'), value: true},
                                                                                    {label: t('impact.false'), value: false}
                                                                                ]}
                                                                                value={virtues2[virtue][0] === true || virtues2[virtue][0] === 'true' ? {label: t('impact.true'), value: true} : {label: t('impact.'+virtues2[virtue][2]), value: virtues2[virtue][2]}}
                                                                                onChange={(e) => selectHardVirtueOptionHandle2(e, virtue, 2)}
                                                                                isDisabled={(virtues2[virtue][0] === true || virtues2[virtue][0] === 'true') || ((virtues2[virtue][0] === false || virtues2[virtue][0] === 'false') && (virtues2[virtue][1] === false || virtues2[virtue][1] === 'false')) ? true : false}
                                                                            />
                                                                        </td>
                                                                        <td>{t('impact.'+virtues2[virtue][3])}</td>
                                                                        <td>{t('impact.'+virtues2[virtue][4])}</td>
                                                                        <td>{t('impact.'+virtues2[virtue][5])}</td>
                                                                    </tr>
                                                                :
                                                                    <></>
                                                            }
                                                        </Fragment>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        :
                                            <>{t('impact.thereisnovirtues')}</>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row className='mb-3'>
                        <Col className='text-end'>
                            <Button variant='primary' type='buttom' onClick={saveCouple}>
                                {t('global.save')}
                            </Button>
                        </Col>
                    </Row>
                    <Card className='alert alert-info border'>
                        <Card.Body className='px-2 px-md-5'>
                            <Row>
                                <Col>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>{t('impact.level')}</th>
                                                <th>{t('impact.scene')}</th>
                                                <th>{t('impact.recommendation')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td rowSpan={4}>{t('impact.crisis')}</td>
                                                <td>{t('impact.crisisscene1')}</td>
                                                <td rowSpan={4}>{t('impact.crisisrecommendation')}</td>
                                            </tr>
                                            <tr><td>{t('impact.crisisscene2')}</td></tr>
                                            <tr><td>{t('impact.crisisscene3')}</td></tr>
                                            <tr><td>{t('impact.crisisscene4')}</td></tr>
                                            <tr>
                                                <td rowSpan={2}>{t('impact.challenge')}</td>
                                                <td>{t('impact.challengescene1')}</td>
                                                <td rowSpan={2}>{t('impact.challengerecommendation')}</td>
                                            </tr>
                                            <tr><td>{t('impact.challengescene2')}</td></tr>
                                            <tr>
                                                <td rowSpan={2}>{t('impact.flow')}</td>
                                                <td>{t('impact.flowscene1')}</td>
                                                <td rowSpan={2}>{t('impact.flowrecommendation')}</td>
                                            </tr>
                                            <tr><td>{t('impact.flowscene2')}</td></tr>
                                            <tr>
                                                <td rowSpan={2}>{t('impact.wow')}</td>
                                                <td>{t('impact.wowscene1')}</td>
                                                <td rowSpan={2}>{t('impact.wowrecommendation')}</td>
                                            </tr>
                                            <tr><td>{t('impact.wowscene2')}</td></tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row>
                        <Col className='px-4 pb-3 mt-3'>
                            <h2 className='verde'>{t('impact.alertcomplementary')}</h2>
                        </Col>
                    </Row>
                    <Card className='alert alert-info border'>
                        <Card.Body className='px-2 px-md-5'>
                            <Row>
                                <Col>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th className='bg-verde-table text-center'>{t('impact.strongvirtues')}</th>
                                                <th className='bg-blanco-table text-center'>{t('impact.virtuespolarity')}</th>
                                                <th className='bg-blanco-table text-center'>{t('impact.virtuesofcouple')}</th>
                                                <th className='bg-verde-table text-center'>{t('impact.creations')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {hardVirtues?.map((virtue, id)=>(
                                                <tr virtue={virtue} key={id}>
                                                    <td>{t('virtues.'+virtue)}</td>
                                                    <td>
                                                        <select
                                                            className="form-control form-control-verde" 
                                                            name='selectcomplementary'
                                                            defaultValue={allVirtuesPolarities[virtue]?.polarity}
                                                            //onChange={(e) => selectHardVirtueOptionHandle(e, virtue, 0)}
                                                        >
                                                            <option value='empty'>{t('impact.select')}</option>
                                                            {virtuespolarities[virtue]?.map((virtueNow, id)=>(
                                                                <option key={id} value={virtueNow}>{t('virtues.'+virtueNow)}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <Form.Group>
                                                            <Form.Control
                                                                as="textarea"
                                                                className="form-control form-control-verde"
                                                                name='othervirtues'
                                                                rows='2'
                                                                defaultValue={allVirtuesPolarities[virtue]?.virtues_couple || ''}
                                                            /> 
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <Form.Group>
                                                            <Form.Control
                                                                as="textarea"
                                                                className="form-control form-control-verde"
                                                                name='creations'
                                                                rows='2'
                                                                defaultValue={allVirtuesPolarities[virtue]?.potential || ''}
                                                            /> 
                                                        </Form.Group>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row className='mb-3'>
                        <Col className='text-end'>
                            <Button variant='primary' type='buttom' onClick={saveComplementary}>
                                {t('global.save')}
                            </Button>
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </Container>
    )
}
